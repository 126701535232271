import { capitalizeFirstLetter } from 'common/utils/helpers';
import Typography from '../typography';
import { TypographyVariants } from '../typography/types';
import { BadgeProps } from './types';
import './styles.scss';

export const Badge = (props: BadgeProps) => {
  const { label, size = 'base', variant = 'default' } = props;
  const typographyVariant: TypographyVariants = size === 'base' ? 'label-02' : 'label-01';
  return (
    <div className={['badge-container', size, variant].join(' ')}>
      <Typography variant={typographyVariant}>
        {capitalizeFirstLetter(label)}
      </Typography>
    </div>
  );
};

export default Badge;
