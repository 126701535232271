/* eslint-disable jsx-a11y/no-static-element-interactions */
import './styles.scss';
import {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { theme } from 'common/theme/theme';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import { SelectOption } from './option';
import { SelectProps, LabelValuePair } from './types';
import Typography from '../typography';
import { ChevronDown } from '../iconComponents';

export const Select = ({
  labelText,
  id,
  name,
  error = false,
  errorMessage = '',
  helperText = '',
  options = [],
  onUpdate = () => null,
  ...rest
}: SelectProps) => {
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const [selectedLabel, setSelectedLabel] = useState<string>('Select');
  const [showOptions, toggleShowOptions] = useState(false);
  const [renderedValue, setRenderedValue] = useState('Select');
  const handleToggle = (e?:
    React.MouseEvent<HTMLDivElement, MouseEvent>
    | React.KeyboardEvent<HTMLDivElement>
    | React.FocusEvent<HTMLDivElement, Element>
    | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    toggleShowOptions(!showOptions);
    e?.stopPropagation();
    e?.preventDefault();
  };

  useEffect(() => {
    if (selectedValue) {
      onUpdate(selectedValue);
      return;
    }
    onUpdate(null);
  }, [selectedValue]);

  useEffect(() => {
    setRenderedValue(selectedLabel);
  }, [selectedLabel]);

  const updateValues = useCallback((value: number | null, label: string) => {
    setSelectedValue(value);
    setSelectedLabel(label);
    toggleShowOptions(false);
  }, [selectedValue, selectedLabel]);

  const handleClickOutside = () => {
    toggleShowOptions(false);
  };

  useOnClickOutside(optionsRef, handleClickOutside);
  return (
    <div
      className="custom-label-select"
      onClick={(e) => handleToggle(e)}
      onKeyDown={(e) => handleToggle(e)}
      data-cy={`multi-select-${name}`}
      onBlur={(e) => handleToggle(e)}
    >
      <Typography variant="heading-compact-01">
        {labelText}
      </Typography>
      <div className="custom-select-container">
        <div
          id={id}
          ref={optionsRef}
          className="custom-select select-type"
          {...rest}
        >
          <Typography variant="body-01" className="rendered-value" noMargin>
            {renderedValue}
          </Typography>
          <div
            className={`select-options ${showOptions ? 'show' : 'hide'}`}
            style={{ height: showOptions ? `${options.length * 38}px` : '0px' }}
          >
            {options.map((entry: LabelValuePair) => (
              <SelectOption
                selectedValue={selectedValue}
                key={entry.value}
                labelText={entry.label}
                value={entry.value}
                onSelect={updateValues}
                data-cy={`select-${entry.value}`}
              />
            ))}
          </div>
        </div>
        <ChevronDown
          className={`chevron-icon ${showOptions ? 'show-options' : ''}`}
        />
        {!error && helperText && (
          <Typography className="helper-text" variant="helper-text-01" color={theme.palette.text['text-02']} data-cy={`${name}-error`}>
            {helperText}
          </Typography>
        )}
        {error && errorMessage && (
          <Typography className="helper-text" variant="helper-text-01" color={theme.palette.support['support-01']}>
            {errorMessage}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Select;
