/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from 'react';
import lang from 'common/lang';
import Typography from 'common/designSystem/typography';
import Link from 'common/designSystem/link';
import { OrderHeaderContainer, OrderNumberUrl } from './styles';
import { OrderHeaderProps } from './types';

export const CgOrderHeader = (props: OrderHeaderProps) => {
  const {
    name, statusUrl, createdAt,
  } = props;
  const { order: orderCopy } = lang;
  const authHeaderRef = useRef<HTMLHeadElement | null>(null);
  const orderHeaderRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    authHeaderRef.current = document.querySelector('.auth-header');
    window.addEventListener('scroll', handleOrderHeaderPosition);

    return (() => window.removeEventListener('scroll', handleOrderHeaderPosition));
  }, []);

  const handleOrderHeaderPosition = () => {
    if (authHeaderRef?.current && orderHeaderRef?.current) {
      if (authHeaderRef.current.classList.contains('header-visible')) {
        orderHeaderRef.current.classList.add('header-exists');
        return;
      }
      orderHeaderRef.current.classList.remove('header-exists');
    }
  };

  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <OrderHeaderContainer className="order-header" ref={orderHeaderRef}>
      <OrderNumberUrl>
        <Typography variant="heading-compact-02">
          {orderCopy.orderNumber}
        </Typography>
        {statusUrl && (
          <Link
            elementType="button"
            onClick={() => handleExternalLink(statusUrl)}
          >
            {`#${name}`}
          </Link>
        )}
        {!statusUrl && (
          <Typography variant="heading-compact-02" className="order-number">
            {`#${name}`}
          </Typography>
        )}
      </OrderNumberUrl>
      <Typography variant="heading-compact-02">
        {createdAt}
      </Typography>
    </OrderHeaderContainer>
  );
};

export default CgOrderHeader;
