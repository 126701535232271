import { Controller } from 'react-hook-form';
import { ReactElement } from 'react';
import Checkbox from './checkbox';
import type { ControlledCheckboxProps } from './types';

const ControlledCheckbox = ({
  name, control, error: baseError = false, ...propsToPass
}: ControlledCheckboxProps<any>):ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const isError = baseError || error?.message;
        return (
          <Checkbox
            {...propsToPass}
            field={field}
            error={!!isError}
            errorMessage={error?.message}
          />
        );
      }}
    />
  );
};

export default ControlledCheckbox;
