import styled from 'styled-components';

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 8px;
    background-color: ${({ theme }) => theme.palette.brand['brand-03']};
    align-items: center;
    svg {
       min-height: 40px;
       min-width: 76px;
    }
`;

export const SocialLinksContainer = styled.div`
    display: flex;
    margin: 32px 0px;
    gap: 16px;
    align-items: center;
    justify-content: center;
    a {
        max-height: 32px;
        svg {
            min-height: 32px;
            min-width: 32px;
            color: ${({ theme }) => theme.palette.icon['icon-02']};
            opacity: 1;
            transition: all 0.2s ease-in-out;
            &:hover {
                opacity: 0.8;
            }
        }
    }
`;

export const OtherLinksContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 32px;
    justify-content: center;
    align-items: center;
    a {
        text-align: center;
    }
`;
