import Typography from 'common/designSystem/typography';
import { LineItems } from 'features/home/types';
import { ProductSpecificsContainer, PriceContainer } from './styles';
import ProductData from './productData';
export const ProductSpecifics = (props: LineItems) => {
  const {
    unitPrice, quantity, productDiscount,
  } = props;
  const discountedPrice = unitPrice - (productDiscount / quantity);

  return (
    <ProductSpecificsContainer>
      <ProductData {...props} />
      <PriceContainer>
        {!!productDiscount && unitPrice > 0 && (
          <Typography variant="body-compact-01-linethrough" className="unit-price" noMargin>
            {`£${(unitPrice / 100).toFixed(2)}`}
          </Typography>
        )}
        { !!unitPrice && (
          <Typography variant="body-03" className="unit-price" noMargin>
            {`£${(discountedPrice / 100).toFixed(2)}`}
          </Typography>
        )}
        <Typography variant="body-03" className="quantity" noMargin>
          {`x ${quantity}`}
        </Typography>
        { !!unitPrice && (
          <Typography variant="body-03" className="total-price" noMargin>
            {`£${((discountedPrice * quantity) / 100).toFixed(2)}`}
          </Typography>
        )}
      </PriceContainer>
    </ProductSpecificsContainer>
  );
};

export default ProductSpecifics;
