import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography, Button, AuthLayout, Loader, useModal, useAlert,
} from 'common/designSystem';
import lang from 'common/lang';
import { ChevronRight, CircularCloseIcon, CloseIcon } from 'common/designSystem/iconComponents';
import { OrdersList } from 'features/home/styles';
import { OrderType } from 'features/home/types';

import CgOrder from 'common/designSystem/cgOrder';
import CgSubscriptionDetail from 'common/designSystem/cgSubscriptionDetails';
import { internalLinks } from 'common/constants';
import { Widget } from '@typeform/embed-react';
import { useCgPlanDetails } from './useCgPlanDetails';
import {
  BreadCrumpContainer, ConfirmInfoBlock, NoOrdersBlock, Prompt, PromptForTypeform, SingleInfoBlock, SubscriptionAndOrdersContainer,
} from './styles';

import { PromptHeader } from '../styles';

export const CgPlanDetails = () => {
  const authHeaderRef = useRef<HTMLHeadElement | null>(null);
  const plansHeaderRef = useRef<HTMLDivElement | null>(null);
  const { plans: plansCopy } = lang;
  const navigate = useNavigate();
  const { id } = useParams();
  const { Alert, forceClose } = useAlert();
  const { Modal: CancelModal, setModalOpen: setCancelModalOpen } = useModal();
  const { Modal: TypeformModal, setModalOpen: setTypeformModalOpen } = useModal();

  const {
    cgPlanDetails, ordersList, isCgPlansLoading,
    statusLoading, refetchCgPlanDetails,
  } = useCgPlanDetails({ id: id || 0 });

  useEffect(() => {
    authHeaderRef.current = document.querySelector('.auth-header');
    window.addEventListener('scroll', handleOrderHeaderPosition);

    return (() => window.removeEventListener('scroll', handleOrderHeaderPosition));
  }, []);

  const handleOrderHeaderPosition = () => {
    if (authHeaderRef?.current && plansHeaderRef?.current) {
      if (authHeaderRef.current.classList.contains('header-visible')) {
        plansHeaderRef.current.classList.add('header-exists');
        return;
      }
      plansHeaderRef.current.classList.remove('header-exists');
    }
  };

  return (
    <AuthLayout>
      { (isCgPlansLoading || statusLoading) && <Loader />}
      <SubscriptionAndOrdersContainer>
        <div className="subscription">
          <div className="subscription-button-container" ref={plansHeaderRef}>
            {cgPlanDetails && (
              <>
                <BreadCrumpContainer>
                  <Typography variant="heading-compact-02" className="my-plans-text" data-cy="link-my-plans" onClick={() => navigate(internalLinks.plans)}>{plansCopy.myPlans}</Typography>
                  <ChevronRight />
                  <Typography variant="heading-compact-02" className="plan-with-id">{`${plansCopy.plan} #${id}`}</Typography>
                </BreadCrumpContainer>
                <CgSubscriptionDetail
                  {...cgPlanDetails}
                  onCancelSelect={() => setCancelModalOpen(true)}
                  closeAlert={forceClose}
                  refetchCgPlanDetails={refetchCgPlanDetails}
                />
              </>
            )}
          </div>
        </div>
        {(ordersList && ordersList?.length > 0) ? (
          <div className="orders">
            <OrdersList>
              {ordersList.map((order: OrderType) => <CgOrder key={order.id} {...order} isSubscription={true} perBoxPrice={cgPlanDetails?.perBoxPrice} />)}
            </OrdersList>
          </div>
        ) : (
          <NoOrdersBlock>
            <Typography variant="body-compact-02">{plansCopy.noOrders}</Typography>
          </NoOrdersBlock>
        )}
      </SubscriptionAndOrdersContainer>
      <CancelModal>
        <Prompt>
          <PromptHeader>
            <Typography variant="heading-03" data-cy="confirm-cancel">
              {plansCopy.confirmCancel}
            </Typography>
            <CloseIcon data-cy="close-icon" onClick={() => setCancelModalOpen(false)} />
          </PromptHeader>
          <Typography variant="body-compact-02" data-cy="confirm-cancel-plan-modal-heading">
            {plansCopy.confirmCancelPlan}
          </Typography>
          <ConfirmInfoBlock>
            <SingleInfoBlock>
              <CircularCloseIcon />
              <Typography variant="body-compact-02" data-cy="confirm-cancel-plan-subtext-1">
                {plansCopy.confirmCancelPlanSubtext1}
              </Typography>
            </SingleInfoBlock>
            <SingleInfoBlock>
              <CircularCloseIcon />
              <Typography variant="body-compact-02" data-cy="confirm-cancel-plan-subtext-2">
                {plansCopy.confirmCancelPlanSubtext2}
              </Typography>
            </SingleInfoBlock>
          </ConfirmInfoBlock>

          <Typography variant="body-compact-02" data-cy="confirm-cancel-plan-subtext-3">
            {plansCopy.confirmCancelPlanSubtext3}
          </Typography>
          <div className="button-container">
            <Button
              label={plansCopy.notNow}
              variant="outlined"
              data-cy="button-not-now"
              onClick={() => setCancelModalOpen(false)}
            />
            <Button
              label={plansCopy.cancelPlan}
              variant="outlined"
              className="contact-support-button"
              data-cy="button-cancel-plan"
              onClick={() => { setCancelModalOpen(false); setTypeformModalOpen(true); }}
            />
          </div>
        </Prompt>
      </CancelModal>
      <TypeformModal>
        <PromptForTypeform>

          <Widget id="ItfUfzM2" className="cancel-plan-form" />
          <Button
            label={plansCopy.returnToMyPlans}
            variant="outlined"
            data-cy="button-return-to-my-plans"
            onClick={() => navigate(internalLinks.plans)}
          />
        </PromptForTypeform>
      </TypeformModal>
      <Alert />
    </AuthLayout>
  );
};
export default CgPlanDetails;
