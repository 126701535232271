import * as React from 'react';
import { SVGProps } from 'react';
const SvgInstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.29 7.054C13.25 7.01 13.556 7 16 7c2.445 0 2.75.01 3.71.054.958.044 1.613.197 2.186.418.6.226 1.144.58 1.593 1.038.458.45.812.994 1.038 1.594.223.573.375 1.227.419 2.186.044.96.054 1.266.054 3.71s-.01 2.75-.054 3.71c-.044.959-.196 1.613-.418 2.186-.226.6-.58 1.144-1.038 1.593-.45.459-.994.813-1.594 1.038-.573.223-1.227.375-2.186.419-.96.044-1.266.054-3.71.054s-2.75-.01-3.71-.054c-.959-.044-1.613-.196-2.186-.418a4.414 4.414 0 0 1-1.593-1.038 4.408 4.408 0 0 1-1.038-1.594c-.223-.573-.375-1.227-.419-2.186C7.01 18.75 7 18.445 7 16c0-2.445.01-2.75.054-3.71.044-.958.197-1.613.418-2.186.226-.6.58-1.144 1.038-1.593.45-.459.994-.813 1.594-1.038.573-.223 1.227-.375 2.186-.419Zm7.347 1.62c-.949-.043-1.234-.052-3.637-.052s-2.688.009-3.637.052c-.877.04-1.354.187-1.671.31a2.785 2.785 0 0 0-1.035.673c-.315.315-.51.615-.673 1.035-.124.317-.27.794-.31 1.67-.043.95-.052 1.235-.052 3.638 0 2.403.009 2.688.052 3.637.04.877.187 1.354.31 1.671.144.391.374.745.673 1.035.29.299.644.529 1.035.673.317.124.794.27 1.67.31.95.043 1.234.052 3.638.052s2.688-.009 3.637-.052c.877-.04 1.354-.187 1.671-.31a2.786 2.786 0 0 0 1.035-.673c.299-.29.529-.644.673-1.035.124-.317.27-.794.31-1.67.043-.95.052-1.235.052-3.638 0-2.403-.009-2.688-.052-3.637-.04-.877-.187-1.354-.31-1.671a2.78 2.78 0 0 0-.673-1.035 2.787 2.787 0 0 0-1.035-.673c-.317-.124-.794-.27-1.67-.31Zm-4.786 10.1a3.004 3.004 0 1 0 2.299-5.55 3.004 3.004 0 0 0-2.3 5.55Zm-2.122-6.045a4.626 4.626 0 1 1 6.542 6.543 4.626 4.626 0 0 1-6.542-6.543Zm8.922-.666a1.093 1.093 0 1 0-1.545-1.546 1.093 1.093 0 0 0 1.545 1.546Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInstagramIcon;
