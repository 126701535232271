import Typography from 'common/designSystem/typography';
import Button from 'common/designSystem/button/button';
import lang from 'common/lang';
import { LineItems } from 'features/home/types';
import { productUrl } from 'common/constants';
import { formatDeliveryDate } from 'common/utils/helpers';
import { useUserDetails } from 'common/hooks';
import { mixpanelCustomEvent, Dict, MixpanelEventName } from 'common/utils/mixpanel/eventTriggers';
import { ProductDataContainer, LineItemContainer } from './styles';

export const ProductData = (props: LineItems) => {
  const {
    productTitle, variant, shopifyProduct, variantId, orderId,
    logo, website, brandGuidelines, ribbonDetail, postcardMessage,
    postcardDesign, recipients, deliveryDate, unitPrice, productDiscount, quantity, isSubscription = false,
  } = props;
  const { order: orderCopy } = lang;
  const { user } = useUserDetails();
  const discountedPrice = unitPrice - (productDiscount / quantity);
  const productMainUrl = `${productUrl}${shopifyProduct?.handle || ''}`;
  const productVariantUrl = `${productUrl}${shopifyProduct?.handle || ''}${variantId ? `?variant=${variantId}` : ''}`;
  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };
  const handleExternalLinkWithMixpanel = (url: string) => {
    if (user) {
      const mixpanelProps: Dict = {
        $name: `${user.firstName} ${user?.lastName}`,
        $distinct_id: user.id,
        $email: user.email,
        total_price: ((discountedPrice * quantity) / 100).toFixed(2),
        order_id: orderId,
        product: productTitle,
        quantity,
        variant,
      };
      mixpanelCustomEvent({ mixpanelProps, id: user.id.toString(), eventName: MixpanelEventName.orderAgain });
    }
    window.open(url, '_blank');
  };
  return (
    <ProductDataContainer>
      <Typography variant="heading-03" className="product-title" onClick={() => handleExternalLink(productMainUrl)}>
        {productTitle}
      </Typography>
      <Typography variant="body-01" noMargin>
        {variant}
      </Typography>
      {logo && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.logo}
          </Typography>
          <Typography variant="body-compact-01-underline" onClick={() => handleExternalLink(logo)}>
            {orderCopy.link}
          </Typography>
        </LineItemContainer>
      )}
      {website && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.website}
          </Typography>
          <Typography variant="body-01">
            {website}
          </Typography>
        </LineItemContainer>
      )}
      {brandGuidelines && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.brandGuidelines}
          </Typography>
          <Typography variant="body-compact-01-underline" onClick={() => handleExternalLink(brandGuidelines)}>
            {orderCopy.link}
          </Typography>
        </LineItemContainer>
      )}
      {ribbonDetail && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.ribbon}
          </Typography>
          {!ribbonDetail.includes('https') && (
            <Typography variant="body-01">
              {ribbonDetail}
            </Typography>
          )}
          {ribbonDetail.includes('https') && (
            <Typography variant="body-compact-01-underline" onClick={() => handleExternalLink(ribbonDetail)}>
              {orderCopy.link}
            </Typography>
          )}
        </LineItemContainer>
      )}
      {postcardMessage && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.postcardMessage}
          </Typography>
          <Typography variant="body-01">
            {postcardMessage}
          </Typography>
        </LineItemContainer>
      )}
      {postcardDesign && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.postcardDesign}
          </Typography>
          <Typography variant="body-compact-01-underline" onClick={() => handleExternalLink(postcardDesign)}>
            {orderCopy.link}
          </Typography>
        </LineItemContainer>
      )}
      {recipients && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.multipleRecipients}
          </Typography>
          <Typography variant="body-compact-01-underline" onClick={() => handleExternalLink(recipients)}>
            {orderCopy.link}
          </Typography>
        </LineItemContainer>
      )}
      {deliveryDate && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.deliveryDate}
          </Typography>
          <Typography variant="body-01">
            {formatDeliveryDate(deliveryDate)}
          </Typography>
        </LineItemContainer>
      )}
      { isSubscription && shopifyProduct?.batchQuantity && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.quantity}
          </Typography>
          <Typography variant="body-01">
            {`${quantity} (${quantity * shopifyProduct.batchQuantity} treats)`}
          </Typography>
        </LineItemContainer>
      )}
      { !isSubscription && (
        <Button
          label={orderCopy.orderAgain}
          variant="outlined"
          size="small"
          onClick={() => handleExternalLinkWithMixpanel(productVariantUrl)}
          style={{ marginTop: '16px' }}
        />
      )}
    </ProductDataContainer>
  );
};

export default ProductData;
