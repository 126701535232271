import './checkbox.scss';
import { useState } from 'react';
import { CheckboxTick } from '../iconComponents';
import Typography from '../typography';

type CheckboxProps = {
  id: string;
  onClickOverride?: () => void;
  withTooltip?: boolean;
  checkedTooltipText?: string;
  unCheckedTooltipText?: string;
}
const useUnlabelledCheckbox = (initialState = false) => {
  const [isChecked, setIsChecked] = useState<boolean>(initialState);
  const [isTooltipVisible, setShowTooltip] = useState(false);
  const showTooltip = () => {
    setShowTooltip(true);
  };

  const hideTooltip = () => {
    setShowTooltip(false);
  };

  const UnlabelledCheckbox = (
    {
      id, onClickOverride, withTooltip = false, checkedTooltipText = '', unCheckedTooltipText = '',
    }: CheckboxProps,
  ) => {
    const handleClick = () => {
      if (onClickOverride) {
        onClickOverride();
      }
      setTimeout(() => {
        setIsChecked(!isChecked);
      }, 0);
    };
    return (
      <button
        className={`custom-checkbox ${isChecked ? 'checked' : ''}`}
        id={id}
        type="button"
        onClick={handleClick}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {isChecked && <CheckboxTick width={10} height={10} />}
        {withTooltip && (
          <div className={`checkbox-tooltip ${isTooltipVisible ? 'show' : 'hide'}`}>
            <Typography variant="helper-text-01">
              {isChecked ? checkedTooltipText : unCheckedTooltipText}
            </Typography>
          </div>
        )}
      </button>
    );
  };

  return { isChecked, UnlabelledCheckbox, setIsChecked };
};

export default useUnlabelledCheckbox;
