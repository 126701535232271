import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';

export const Footer = styled.footer`
    position: fixed;
    width: calc(100vw - 56px);
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.background['ui-background']};
    gap: 8px;
    padding: 16px 28px;
    @media ${breakpoints.tablet} {
        padding: 16px 80px;
        width: calc(100vw - 160px);
        justify-content: flex-end;
    }
    box-shadow: 0px -2px 4px 0px rgba(30, 30, 30, 0.1);
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transform: translate3d(0px,0px,0px);
    .button-right-margin {
        margin-right: 15px;
        height: 20px;
    }
`;

export const FooterPlanSummaryContainer = styled.div`
    position: fixed;
    bottom: 74px;
    width: calc(100vw - 48px);
    background: ${({ theme }) => theme.palette['ui-base'].grey10};
    display: block;
    @media ${breakpoints.tablet} {
        display: none;
    }
    border-radius: 6px 6px 0px 0px;
    padding: 24px;
    box-shadow: none;
    transform: translateY(100%);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    &.show {
        transform: translateY(0%);
        box-shadow: 0px -2px 4px 0px rgba(30, 30, 30, 0.1);
    }
    max-height: calc(100vh - 254px);
`;

export const PlanSummaryInnerContainer = styled.div`
    padding: 24px 32px;
    border: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}}`}; 
    border-radius: 6px;
    z-index: 2;
    overflow: scroll;
    max-height: calc(100vh - 280px);
`;

export const PlanSummaryIndicatorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 16px;
    z-index: 3;
    top: -54px;
    left: 50%;
    transform: translateX(-50%);
    gap: 6px;
    background: ${({ theme }) => theme.palette['ui-base'].grey10};
    border-radius: 6px 6px 0px 0px;
    box-shadow: 0px -3px 3px 0px rgba(30, 30, 30, 0.1);
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    &.flip {
            svg {
                transform: rotate(0deg);
            }
         }
    svg {
        color: ${({ theme }) => theme.palette.text['text-01']};
        min-width: 24px;
        min-height: 24px;
        max-width: 24px;
        max-height: 24px;
        transform: rotate(180deg);
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    }
`;
