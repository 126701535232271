import { isEmpty } from 'lodash';
import { ReactElement, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'common/hooks/state';
import { RootState } from 'common/store/types';
import { mixpanelCustomEvent, Dict, MixpanelEventName } from 'common/utils/mixpanel/eventTriggers';
import { useCookie } from 'common/hooks';
import { authCookieName } from 'common/constants';
type RequireAuthProps = {
  children: ReactElement;
  redirectTo: string;
}

const ProtectedRoute = ({ children, redirectTo }: RequireAuthProps): ReactElement => {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const { getCookie } = useCookie();
  const isAuthenticated = (): boolean => {
    const token = useAppSelector((state: RootState) => state.auth.token);
    return !isEmpty(token);
  };
  const isLoggedIn = isAuthenticated() && getCookie(authCookieName);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location]);

  if (isLoggedIn && user) {
    const mixpanelProps: Dict = {
      $name: `${user.firstName} ${user?.lastName}`,
      $distinct_id: user.id,
      $email: user.email,
      pathName: location.pathname,
    };
    mixpanelCustomEvent({ mixpanelProps, id: user.id.toString(), eventName: MixpanelEventName.pageView });
  }
  return isLoggedIn ? children : <Navigate to={redirectTo} replace state={{ redirectTo: location }} />;
};

export default ProtectedRoute;
