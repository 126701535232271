import * as React from 'react';
import { SVGProps } from 'react';
const SvgSearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m17.5 17.5-4.33-4.33m0 0A6.25 6.25 0 1 0 4.33 4.33a6.25 6.25 0 0 0 8.838 8.838Z"
    />
  </svg>
);
export default SvgSearchIcon;
