import { useRef } from 'react';
import './search.scss';
import { SearchProps } from './types';
import { SearchIcon, CloseIcon } from '../iconComponents';
export const Search = ({
  id,
  type,
  value,
  onClear = () => null,
  ...rest
}: SearchProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleClear = () => {
    onClear();
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };
  return (
    <div className="custom-search-container">
      <input
        id={id}
        className={`custom-search with-icon ${value ? 'active' : ''}`}
        type="text"
        {...rest}
        ref={inputRef}
      />
      {!value && <SearchIcon className="search-close-icon" />}
      {value && <CloseIcon className="search-close-icon pointer" onClick={handleClear} />}
    </div>
  );
};

export default Search;
