import { CgPlanType } from 'features/plans/types';
import { ProductSpecificsContainer } from './styles';
import ProductData from './productData';

export const CgProductSpecifics = (props: CgPlanType) => {
  return (
    <ProductSpecificsContainer>
      <ProductData {...props} />
    </ProductSpecificsContainer>
  );
};

export default CgProductSpecifics;
