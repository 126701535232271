/* eslint-disable @typescript-eslint/naming-convention */
import runtimeEnv from '@mars/heroku-js-runtime-env';

const {
  REACT_APP_API_URL, REACT_APP_MIXPANEL_TOKEN,
  REACT_APP_STRIPE_PUBLISHABLE_KEY, REACT_APP_ALLOW_STAMPED,
  REACT_APP_ALLOWED_STAMPED_EMAILS, REACT_APP_STAMPED_PUBLIC_KEY,
  REACT_APP_STORE_ID, REACT_APP_BUGSNAG_API_KEY,
} = runtimeEnv();

export const apiRootUrl = `${REACT_APP_API_URL}/api`;
export const mixpanelToken = REACT_APP_MIXPANEL_TOKEN;
export const stripePublishableKey = REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const allowStamped = REACT_APP_ALLOW_STAMPED === 'true';
export const allowedStampedEmails = REACT_APP_ALLOWED_STAMPED_EMAILS?.split(',') || [];
export const stampedPublicKey = REACT_APP_STAMPED_PUBLIC_KEY;
export const storeId = REACT_APP_STORE_ID;
export const mergeBaseUrl = 'https://api-eu.merge.dev';
export const bugsNagApiKey = REACT_APP_BUGSNAG_API_KEY;
// RTK Query

export const signInUrl = `${apiRootUrl}/login`;
export const signUpUrl = `${apiRootUrl}/register`;
export const forgotPasswordUrl = `${apiRootUrl}/forgot-password`;
export const resetPasswordUrl = `${apiRootUrl}/reset-password`;
export const logoutUrl = `${apiRootUrl}/logout`;
export const verifyEmailUrl = `${apiRootUrl}/profile/verify-email`;
export const resendVerifyEmailUrl = `${apiRootUrl}/resend-otp-code`;
export const occasionsListingUrl = `${apiRootUrl}/occasions`;
export const teamSizeListingUrl = `${apiRootUrl}/team-size-options`;
export const ordersListUrl = `${apiRootUrl}/orders`;
export const plansListUrl = `${apiRootUrl}/subscriptions`;
export const pausePlanUrl = `${apiRootUrl}/subscriptions/%id%/pause`;
export const resumePlanUrl = `${apiRootUrl}/subscriptions/%id%/resume`;
export const cancelPlanUrl = `${apiRootUrl}/subscriptions/%id%/cancel`;
export const corporateGiftsProductsUrl = `${apiRootUrl}/corporate-gift-products`;
export const presignedUrl = `${apiRootUrl}/presigned-url`;
export const linkTokenUrl = `${apiRootUrl}/hr-partner-link-token/%id%`;
export const hrConnectionIdUrl = `${apiRootUrl}/save-hr-partner-token/%id%`;
export const hrActiveConnectonUrl = `${apiRootUrl}/hr-partner-connections/active`;
export const hrConnectionEmployessUrl = `${apiRootUrl}/hr-partner-connections/%connection_id%/employees`;
export const hrConnectionNewlyJoinedEmployeesUrl = `${apiRootUrl}/hr-partner-connections/%connection_id%/employees/newly-joined`;
export const createCGplanUrl = `${apiRootUrl}/corporate-subscriptions`;
export const cgPlanDetails = `${apiRootUrl}/corporate-subscriptions/%id%`;
export const createCgPlanDraftsUrl = `${apiRootUrl}/drafts`;
