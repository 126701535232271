import * as React from 'react';
import { SVGProps } from 'react';
const SvgIntervalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.625 9c0-.924-.035-1.84-.103-2.746a3.004 3.004 0 0 0-2.776-2.776 36.507 36.507 0 0 0-5.492 0 3.004 3.004 0 0 0-2.776 2.776c-.012.165-.024.33-.034.496M14.625 9l2.25-2.25M14.625 9l-2.25-2.25m-9 2.25c0 .924.035 1.84.103 2.746a3.004 3.004 0 0 0 2.776 2.776 36.492 36.492 0 0 0 5.492 0 3.004 3.004 0 0 0 2.776-2.776c.012-.165.024-.33.034-.496M3.375 9l2.25 2.25M3.375 9l-2.25 2.25"
    />
  </svg>
);
export default SvgIntervalIcon;
