import React, { memo, Suspense } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { Loader } from 'common/designSystem';
import ProtectedRoute from './protectedRoute';
import { CgPlanDetails } from './plans/cgPlanDetails';
import CgSelectRecipients from './plans/cgSelectRecipients';
import StampedRewards from './rewards';

const Home = React.lazy(() => import('./home'));
const LandingPage = React.lazy(() => import('./landingPage'));
const AccountRoutes = React.lazy(() => import('features/account/routes'));
const ResetPassword = React.lazy(() => import('features/account/reset-password'));
const Plans = React.lazy(() => import('./plans'));
const PlanDetails = React.lazy(() => import('./plans/planDetails'));
const Gifting = React.lazy(() => import('./gifting'));
const Rewards = React.lazy(() => import('./rewards'));
const WebRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          <Route path="/home" element={(<ProtectedRoute redirectTo="/account/signin"><Home /></ProtectedRoute>)} />
          <Route path="/plans" element={(<ProtectedRoute redirectTo="/account/signin"><Plans /></ProtectedRoute>)} />
          <Route path="/plans/:id" element={(<ProtectedRoute redirectTo="/account/signin"><PlanDetails /></ProtectedRoute>)} />
          <Route path="/cgplans/:id" element={(<ProtectedRoute redirectTo="/account/signin"><CgPlanDetails /></ProtectedRoute>)} />
          <Route path="/cgplans/:id/recipients" element={(<ProtectedRoute redirectTo="/account/signin"><CgSelectRecipients /></ProtectedRoute>)} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/account/*" element={<AccountRoutes />} />
          <Route path="/password-reset/:token" element={<ResetPassword />} />
          <Route path="/gifting/isFromDraft" element={(<ProtectedRoute redirectTo="/account/signin"><Gifting /></ProtectedRoute>)} />
          <Route path="/gifting" element={<Gifting />} />
          <Route path="/rewards" element={(<ProtectedRoute redirectTo="/account/signin"><StampedRewards /></ProtectedRoute>)} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default memo(WebRoutes);
