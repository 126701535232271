/* eslint-disable no-useless-escape */
export const mobileWidthLimit = 667;
export const tabletWidthLimit = 768;
export const shopifyUrl = 'https://cakedrop.london/';
export const shopifyFilesPageUrl = 'https://cdn.shopify.com/s/files/1/0740/3736/2990/files/';
export const socialLinks = {
  facebook: 'https://www.facebook.com/CakeDropLDN/',
  instagram: 'https://www.instagram.com/cakedropldn/?hl=en',
  linkedIn: 'https://www.linkedin.com/company/cakedrop-london/?originalSubdomain=uk',
  twitter: 'https://twitter.com/cakedropldn?lang=en',
};
export const externalLinks = {
  shop: shopifyUrl,
  contact: `${shopifyUrl}pages/contact`,
  tandCs: `${shopifyUrl}policies/terms-of-service`,
  privacyPolicy: `${shopifyUrl}policies/privacy-policy`,
  dataPolicy: `${shopifyUrl}pages/data-policy`,
  treats: `${shopifyUrl}collections/all`,
  ourStory: `${shopifyUrl}pages/about-us`,
  recurringTreats: `${shopifyUrl}pages/treat-subscriptions`,
  workAnniversaryEarlyAccess: `${shopifyUrl}pages/corporate-gifting-register-interest`,
  postcardDesignTemplate: `${shopifyFilesPageUrl}CakeDrop_Postcard_Artwork_Template.pdf?v=1698919120`,
  brandedTreatsLink: `${shopifyUrl}collections/branded-treats`,
  letterBoxTreatsLink: `${shopifyUrl}collections/postbox-treats`,
  classicTreatsLink: `${shopifyUrl}collections/classic-treats`,
  christmasTreatsLink: `${shopifyUrl}pages/christmas-treats`,
  corporateGiftingRegisterLink: `${shopifyUrl}pages/corporate-gifting-register-interest`,
  officeTreatsLink: `${shopifyUrl}products/office-treat-days`,
  corporateGiftingLink: `${shopifyUrl}pages/corporate-gifts`,
  corporateEventsLink: `${shopifyUrl}pages/branded-treats`,
  seasonalEventsLink: `${shopifyUrl}collections/seasonal-events-and-awareness-days-treats`,
  landingPage1aUrl: `${shopifyFilesPageUrl}landing1a.jpg`,
  landingPage1bUrl: `${shopifyFilesPageUrl}landing1b.jpg`,
  landingPage1cUrl: `${shopifyFilesPageUrl}landing1c.jpg`,
  landingPage2aUrl: `${shopifyFilesPageUrl}landing2a.jpg`,
  landingPage2bUrl: `${shopifyFilesPageUrl}landing2b.jpg`,
  landingPage2cUrl: `${shopifyFilesPageUrl}landing2c.jpg`,
  landingPage2dUrl: `${shopifyFilesPageUrl}landing2d.jpg`,
  cgHomePageLink: `${shopifyUrl}pages/corporate-gifting-automation`,
};
export const internalLinks = {
  orders: '/home',
  plans: '/plans',
  corporateGiftingPlan: '/cgplans/',
  recipients: '/recipients',
  recipientTableNewEmployee: '/recipients/?add_new_employee=true',
  rewards: '/rewards',
  gifting: '/gifting',
};
export const navBoxShadow = '0px 2px 4px rgba(30, 30, 30, 0.1)';
export const navBoxShadowHidden = '0px 2px 4px rgba(30, 30, 30, 0)';
export const promptShadow = '0px 10px 10px -5px rgba(41, 59, 97, 0.1)';
export const skeletonImageBase = '#f5f1f8';
export const skeletonPrimaryText = '#ecf1fd';
export const emailRegex = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
export const authCookieName = 'cakedrop_auth';
export const mainDomainName = '.cakedrop.london';
export const productUrl = `${shopifyUrl}products/`;
export const authUserCookieName = 'cakedrop_auth_user';
export const authUserInfoCookieName = 'cakedrop_auth_user_info';
export const mixpanelTokenCookieName = 'mixpanel_token';
export const cookieYesCookieName = 'cookieyes-consent';
export const allowedFileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
export const platformFeeAmount = 20;
export const vatMultiplier = 1.2;
export const vatDivider = 0.2;
export const expirationDateRegex = /^(0[1-9]|1[0-2])\/(20[2-9][0-9]|2[1-9][0-9]{2})$/;
export const subscribeNowButtonId = '#subscribe-now-button';
export const numberOfMonths = 12;
export const ralewayFontSource = 'https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600&display=swap';
export const storeUrl = 'cakedrop.london';
export const riveUrlForAnimation = 'https://rive.app/s/sle1FqBWskGztVmUeooE7g/embed';
export const stagingAppHostName = 'staging.cakedrop.london';
export const stagingAppUrl = 'https://staging.cakedrop.london/';
export const signUpSourceCg = 'CG';
export const signUpSourceOther = 'Other';
