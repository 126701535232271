/* eslint-disable no-shadow */
import { Dict } from 'mixpanel-browser';

export enum MixpanelEventName {
    register = 'Register',
    login = 'Log In',
    pageView = 'Pageview',
    viewOrder = 'View Order',
    orderAgain = 'Order again',
    viewInvoice = 'View Invoice',
    selectTreat = 'Select Treat',
    selectVariant = 'Select Variant',
    editMessage = 'Edit Message',
    selectRecipient = 'Select Recipient',
    deselectRecipient = 'Deselect Recipient',
    selectAll = 'Select All',
    deselectAll = 'Deselect All',
    searchRecipient = 'Search Recipient',
}
export type UserEventProps = {
    mixpanelProps: Dict,
    id: string | number,
    eventName: MixpanelEventName,
    companyName: string,
}
export type CustomEventProps = {
    mixpanelProps: Dict,
    id: string | number,
    eventName: MixpanelEventName,
    companyName?: string,
}
export enum CookieChoice {
    yes = 'yes',
    no = 'no',
}
export type CookieYesObjectType = {
    analytics: CookieChoice;
}

export type ConsentType = CookieYesObjectType | null;
