import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';

export const CgSubscriptionHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px;
    gap: 8px;
    width: calc(100% - 32px);
    align-items: flex-start;
    position: -webkit-sticky;
    background: ${({ theme }) => theme.palette.background['ui-background-03']};
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    @media ${breakpoints.desktopSmall} {
        position: unset;
        top: unset;
    }
`;

export const CgSubscriptionNumberUrl = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
`;
