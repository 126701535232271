/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './styles.scss';
import {
  useState, useCallback, useRef,
} from 'react';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import { SelectOption } from './option';
import { SelectProps, LabelActionPair } from './types';
import Typography from '../typography';
import { ChevronDown } from '../iconComponents';

export const ButtonSelect = ({
  labelText,
  id,
  name,
  options = [],
  maxWidth = '100%',
  style,
  ...rest
}: SelectProps) => {
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const [showOptions, toggleShowOptions] = useState(false);
  const handleToggle = (e?:
    React.MouseEvent<HTMLDivElement, MouseEvent>
    | React.KeyboardEvent<HTMLDivElement>
    | React.FocusEvent<HTMLDivElement, Element>
    | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    toggleShowOptions(!showOptions);
    e?.stopPropagation();
    e?.preventDefault();
  };
  const handleSelectedAction = useCallback((label: string) => {
    const selectedOption = options.filter((option: LabelActionPair) => option.label === label);
    if (selectedOption && selectedOption.length === 1) {
      selectedOption[0].action();
    }
    toggleShowOptions(false);
  }, []);

  const handleClickOutside = () => {
    toggleShowOptions(false);
  };

  useOnClickOutside(optionsRef, handleClickOutside);
  return (
    <div
      className="custom-label-button-select"
      onClick={(e) => handleToggle(e)}
      onKeyDown={(e) => handleToggle(e)}
      data-cy={`multi-select-${name}`}
      onBlur={(e) => handleToggle(e)}
      style={{ maxWidth }}
    >
      <div className="custom-button-select-container">
        <div
          id={id}
          ref={optionsRef}
          className="custom-select select-type"
          {...rest}
        >
          <div className="label-chevron-container">
            <Typography variant="body-01" className="rendered-value" noMargin>
              {labelText}
            </Typography>
            <ChevronDown
              className={`chevron-icon ${showOptions ? 'show-options' : ''}`}
            />
          </div>
          <div
            className={`select-options ${showOptions ? 'show' : 'hide'}`}
            style={{ height: showOptions ? `${options.length * 38}px` : '0px' }}
          >
            {options.map((entry: LabelActionPair, index: number) => (
              <SelectOption
                key={`entry.label${index}`}
                labelText={entry.label}
                onSelect={handleSelectedAction}
                data-cy={`select-${index}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonSelect;
