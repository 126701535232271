import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';

export const ProductCardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    max-width: 282px;
    @media ${breakpoints.tablet} {
            padding: 24px;
        }
    flex-direction: column;
    gap: 16px;
    border-radius: 6px;
    border: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}`};
    &:hover {
        border: ${({ theme }) => `1px solid ${theme.palette.brand['brand-01']}`};
        box-shadow: 0px 10px 10px -5px rgba(41, 59, 97, 0.1);
    }
    &.selected {
        box-shadow: 0px 10px 10px -5px rgba(41, 59, 97, 0.1);
        position: relative;
        &::before{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            border: ${({ theme }) => `2px solid ${theme.palette.brand['brand-01']}`};
            top: -2px;
            left: -2px;
            border-radius: 8px;
        }
    }
    background: ${({ theme }) => theme.palette.background['ui-background']};
    transition: all 0.3s ease-in-out;
    .select-treat-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width : 85%;
        cursor: pointer;
    }
    .button-container{
        display: flex;
        justify-content: center;
    }
    .selected-treat-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width : 85%;
        cursor: pointer;
        border: none;
        background: ${({ theme }) => theme.palette.button['button-02']};
    }
`;

export const ProductCardItems = styled.div`
    display: flex;
    gap:16px;
    flex-direction: column;
    .raw-html-content{
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: ${({ theme }) => theme.palette['ui-base'].grey70};
        h6, em{
            display: none;
        }
        span {
            color: ${({ theme }) => theme.palette['ui-base'].grey70};
        }
        p {
            margin: unset;
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
`;

export const TitleAndPrice = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const PricingPerBox = styled.div`
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
`;

export const ImageContainer = styled.div`
    position: relative; 
    img { 
        border-radius: 6px; 
        width: 100%; 
        min-width: 100%; 
        aspect-ratio: 1 / 1; 
        transition: filter 0.3s ease; 
    } 
    svg { 
        display: none;
        position: absolute; 
        min-width: 24px;
        max-width: 24px; 
        max-height: 24px; 
        min-height: 24px; 
        bottom: 8px; 
        right: 8px; 
    } 
    &:not(.selected):hover { 
        filter: brightness(80%);
        cursor: pointer; 
        svg {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 30px;
            max-width: 30px; 
            max-height: 30px; 
            min-height: 39px; 
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    } 
     &.selected {
        img {
            filter: none;  
        }
        svg:not(:last-child) { 
            display: none;
        }
        svg:last-child { 
            display: block; 
        }
     }

`;

export const DietaryRequirementsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
`;
