import * as React from 'react';
import { SVGProps } from 'react';
const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="77" height="40" viewBox="0 0 77 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6148_1002)">
      <path d="M0.320831 9.72755C0.320831 3.86345 4.14122 0 9.95512 0C14.5336 0 17.2016 2.39079 17.2016 4.66974C17.2016 5.86514 16.4992 6.64334 15.0951 6.64334C13.438 6.64334 12.9044 5.50361 12.3708 4.28012C11.8088 2.97392 11.1627 1.58502 8.97203 1.58502C6.02276 1.58502 4.30945 4.08557 4.30945 7.64315C4.30945 11.5623 6.41599 16.6206 11.2757 16.6206C13.6909 16.6206 14.9268 15.2863 15.7133 14.3682C16.1628 13.8688 16.4998 13.5348 16.8368 13.5348C17.2021 13.5348 17.3704 13.8688 17.3704 14.3973C17.3704 16.5374 14.5341 19.4556 9.84367 19.4556C4.1696 19.4556 0.321357 15.5916 0.321357 9.72807H0.320831V9.72755Z" fill="#A679F8" />
      <path d="M18.6909 18.4542C18.6909 17.9549 18.9438 17.7593 19.3092 17.5652C19.6462 17.3431 20.0394 17.0924 20.3207 16.4255L26.7807 1.00135C26.9768 0.5285 27.3421 0.278809 27.8479 0.278809H29.3646C29.8419 0.278809 30.1789 0.556071 30.3761 1.00135L36.7241 16.4255C36.9764 17.0924 37.3975 17.3712 37.7629 17.5652C38.072 17.7874 38.3249 17.9549 38.3249 18.4542C38.3249 18.8714 38.0158 19.1768 37.5106 19.1768H32.3422C31.837 19.1768 31.5279 18.8714 31.5279 18.4542C31.5279 18.037 31.725 17.8425 31.9774 17.676C32.3144 17.4263 32.5956 17.2032 32.2865 16.425L31.2472 13.7023H23.1301L22.0345 16.3974C21.7254 17.1475 22.0908 17.3988 22.5119 17.6204C22.8488 17.7868 23.158 17.9824 23.158 18.4537C23.158 18.8709 22.821 19.1763 22.3436 19.1763H19.5347C19.0568 19.1763 18.6915 18.8709 18.6915 18.4537L18.6909 18.4542ZM30.6568 12.1459L27.3421 3.50242L23.7757 12.1459H30.6568Z" fill="#A679F8" />
      <path d="M40.1507 18.4542C40.1507 17.9824 40.4309 17.7874 40.7122 17.5652C40.9934 17.3712 41.2742 17.148 41.2742 16.6762V2.77936C41.2742 2.30755 40.9929 2.08439 40.7122 1.89036C40.4309 1.66823 40.1507 1.44508 40.1507 1.00135C40.1507 0.557631 40.4877 0.278809 40.965 0.278809H45.768C46.2737 0.278809 46.6112 0.556071 46.6112 1.00135C46.6112 1.44664 46.33 1.66823 46.0493 1.89036C45.768 2.08439 45.4878 2.30755 45.4878 2.77936V9.81077L52.4535 2.80693C53.015 2.22328 52.7905 2.00116 52.4535 1.72389C52.2564 1.5283 52.0324 1.33427 52.0324 1.00135C52.0324 0.584161 52.3694 0.278809 52.8468 0.278809H55.8802C56.3859 0.278809 56.695 0.584161 56.695 1.00135C56.695 1.50074 56.358 1.7239 55.796 2.05682C55.3187 2.33564 54.7288 2.7237 54.0543 3.41867L49.9538 7.47668L56.358 16.4806C56.9474 17.3155 57.3974 17.5928 57.7622 17.7593C58.0713 17.9257 58.2396 18.0381 58.2396 18.3986C58.2396 19.0379 57.5656 19.4551 56.245 19.4551C54.251 19.4551 52.7343 18.5094 51.6955 16.9811L47.0329 10.4225L45.4883 11.9233V16.6762C45.4883 17.148 45.7685 17.3712 46.0498 17.5652C46.331 17.7874 46.6118 17.9824 46.6118 18.4542C46.6118 18.8995 46.2748 19.1768 45.7685 19.1768H40.9656C40.4882 19.1768 40.1512 18.8995 40.1512 18.4542H40.1507Z" fill="#A679F8" />
      <path d="M59.7847 18.4542C59.7847 17.9824 60.0649 17.7874 60.3461 17.5652C60.6274 17.3712 60.9081 17.148 60.9081 16.6762V2.77936C60.9081 2.30755 60.6269 2.08439 60.3461 1.89036C60.0649 1.66823 59.7847 1.44508 59.7847 1.00135C59.7847 0.557631 60.1217 0.278809 60.599 0.278809H73.8003C74.4742 0.278809 74.8675 0.667389 74.8675 1.27861V4.75296C74.8675 5.19824 74.5862 5.53116 74.1373 5.53116C73.5753 5.53116 73.294 4.94751 72.9854 4.16931C72.5922 3.11384 72.0591 1.83522 70.4578 1.83522H65.1217V8.56023H68.8012C69.7559 8.56023 70.1776 7.97762 70.4862 7.42154C70.7674 6.92111 71.0203 6.58819 71.4976 6.58819C71.975 6.58819 72.2557 6.92111 72.2557 7.39396V11.2845C72.2557 11.7573 71.9755 12.0902 71.4976 12.0902C71.0198 12.0902 70.7674 11.7573 70.4862 11.2569C70.1776 10.7008 69.7559 10.1182 68.8012 10.1182H65.1217V16.566C65.1217 17.2042 65.5428 17.6214 66.2173 17.6214H70.6549C72.2279 17.6214 72.874 16.2596 73.3229 15.176C73.6883 14.3135 73.9969 13.6753 74.5589 13.6753C75.0084 13.6753 75.2891 14.0357 75.2891 14.5086V18.177C75.2891 18.7887 74.8959 19.1768 74.2219 19.1768H60.5995C60.1222 19.1768 59.7852 18.8995 59.7852 18.4542H59.7847Z" fill="#A679F8" />
      <path d="M1.27711 38.9911C1.27711 38.5395 1.54576 38.3533 1.81387 38.1405C2.08251 37.9283 2.35116 37.7156 2.35116 37.264V24.1391C2.35116 23.6616 2.08251 23.4748 1.81387 23.2626C1.54576 23.0504 1.27711 22.8376 1.27711 22.4121C1.27711 21.9611 1.62619 21.6682 2.10933 21.6682H10.3252C15.7491 21.6682 19.3471 25.2544 19.3471 30.7013C19.3471 36.1482 15.749 39.7344 10.218 39.7344H2.10933C1.62619 39.7344 1.27711 39.4421 1.27711 38.9905V38.9911ZM7.13045 23.3167V36.7334C7.96266 37.6365 9.06351 38.1676 10.1375 38.1676C13.1178 38.1676 14.4873 35.3248 14.4873 32.0569C14.4873 28.4973 12.876 23.3167 8.98307 23.3167H7.13045Z" fill="#231E4F" />
      <path d="M21.1739 38.9911C21.1739 38.5395 21.4426 38.3533 21.7107 38.1406C21.9793 37.9283 22.248 37.7156 22.248 37.264V24.1391C22.248 23.6616 21.9793 23.4748 21.7107 23.2626C21.4426 23.0504 21.1739 22.8376 21.1739 22.4121C21.1739 21.9611 21.523 21.6682 22.0061 21.6682H31.028C35.0823 21.6682 37.7939 23.7141 37.7939 26.9018C37.7939 29.2131 36.344 31.0998 33.82 31.9498L37.2298 36.865C37.713 37.5824 38.2497 37.8747 38.7334 38.1135C39.1093 38.2727 39.2707 38.4589 39.2707 38.8043C39.2707 39.5487 38.3307 39.9997 36.8808 39.9997C34.9209 39.9997 33.1755 39.1762 31.9674 37.237L28.8531 32.4278H27.0273V37.2635C27.0273 37.7145 27.2155 37.9278 27.5377 38.14C27.8332 38.3263 28.1018 38.5385 28.1018 38.9905C28.1018 39.4426 27.7796 39.7344 27.2696 39.7344H22.0067C21.5235 39.7344 21.1744 39.4421 21.1744 38.9905L21.1739 38.9911ZM27.0273 23.3167V30.8615H29.9807C32.021 30.8615 32.9878 29.4804 32.9878 27.7269C32.9878 25.8401 31.8602 23.3167 29.0407 23.3167H27.0273Z" fill="#231E4F" />
      <path d="M39.6739 30.7017C39.6739 25.0961 43.4328 21.4033 49.0711 21.4033C54.7094 21.4033 58.4415 25.0956 58.4415 30.7017C58.4415 36.3078 54.7089 40.0001 49.0711 40.0001C43.4333 40.0001 39.6739 36.3078 39.6739 30.7017ZM53.9313 33.4119C53.9313 29.1344 51.4342 23.1304 47.7021 23.1304C45.4468 23.1304 44.1851 25.2288 44.1851 27.992C44.1851 32.3492 46.7895 38.2736 50.4411 38.2736C52.6964 38.2736 53.9313 36.1752 53.9313 33.4119Z" fill="#231E4F" />
      <path d="M60.2962 38.9911C60.2962 38.5395 60.5648 38.3533 60.833 38.1405C61.1016 37.9283 61.3702 37.7156 61.3702 37.264V24.1391C61.3702 23.6616 61.1016 23.4748 60.833 23.2626C60.5648 23.0504 60.2962 22.8376 60.2962 22.4121C60.2962 21.9611 60.6453 21.6682 61.1284 21.6682H69.5057C73.8823 21.6682 76.7816 23.9794 76.7816 27.6462C76.7816 31.3131 73.8818 33.81 69.3979 33.81H66.149V37.2635C66.149 37.7145 66.4713 37.9278 66.8204 38.14C67.1158 38.3263 67.4113 38.5385 67.4113 38.9905C67.4113 39.4426 67.089 39.7344 66.5522 39.7344H61.1284C60.6453 39.7344 60.2962 39.4421 60.2962 38.9905V38.9911ZM66.1495 23.3167V31.287C66.9549 31.8717 67.9217 32.2437 68.7808 32.2437C70.9556 32.2437 71.9487 30.7024 71.9487 28.7636C71.9487 26.6116 70.7138 23.3172 67.7335 23.3172H66.1495V23.3167Z" fill="#231E4F" />
    </g>
    <defs>
      <clipPath id="clip0_6148_1002">
        <rect width="77" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLogo;
