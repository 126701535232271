import {
  useMemo, Fragment,
} from 'react';
import { useGiftingDetails } from '../../hooks';
import { ChevronRight } from '../iconComponents';
import { BreadcrumbDisplayItem } from './partials/breadcrumbDisplayItem';
import { BreadcrumbList } from './styles';
import { BreadCrumbsItem } from './types';
import Typography from '../typography';

export const useBreadcrumbs = ({ items }: { items: Array<BreadCrumbsItem>}) => {
  const { selectedStep, setStep } = useGiftingDetails();
  const BreadcrumbDisplayList = useMemo(() => (
    <BreadcrumbList>
      {items.map((item: BreadCrumbsItem, index: number) => {
        return (
          <>
            <BreadcrumbDisplayItem
              label={item.label}
              step={item.step}
              currentStep={selectedStep}
              setSelectedStep={() => setStep(item.step)}
              key={item.step}
            />
            {index !== items.length - 1 && <ChevronRight key={item.label} />}
          </>
        );
      })}
    </BreadcrumbList>
  ), [selectedStep]);

  const BreadcrumbsHeading = useMemo(() => (
    <Typography variant="heading-05" style={{ marginBottom: selectedStep === 3 ? '8px' : '24px' }} data-cy="breadcrumbs-heading">
      {items[selectedStep - 1].heading}
    </Typography>
  ), [selectedStep]);

  return {
    BreadcrumbDisplayList, BreadcrumbsHeading,
  };
};

export default useBreadcrumbs;
