import { AuthLayout } from 'common/designSystem';
import { useUserDetails } from 'common/hooks';
import {
  allowStamped, allowedStampedEmails,
} from 'common/utils/network/endpoints';
import { useEffect } from 'react';
import {
  ActivitesBlock, ActivitiesAndReferralWidgets, SummaryWidget, WidgetsContainer,
} from './styles';

declare global {
  interface Window {
      StampedFn: Window['StampedFn'];
  }
}

let StampedFn: Window['StampedFn'];

export const StampedRewards = () => {
  const { user } = useUserDetails();

  const loadStampedRewardsWidgets = () => {
    if (user) {
      StampedFn.loadDisplayWidgetsRewards();
      console.warn(StampedFn);
    }
  };

  useEffect(() => {
    let intervalId: number | undefined;

    if (user && (allowStamped || allowedStampedEmails.includes(user?.email))) {
      intervalId = window.setInterval(() => {
        if ('StampedFn' in window) {
          clearInterval(intervalId);
          try {
            StampedFn = window.StampedFn;
            loadStampedRewardsWidgets();
          } catch (error) {
            console.error(error);
          }
        }
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [user]);

  return (
    <AuthLayout>
      <WidgetsContainer>
        <SummaryWidget>
          <div className="heading-02" id="stamped-rewards-widget" data-widget-type="rewards-summary" data-label-greeting-description="Make your budget go further with CakeDrop Rewards. Earn points with every order and turn those points into discounts."></div>
        </SummaryWidget>
        <ActivitiesAndReferralWidgets>
          <ActivitesBlock>
            {' '}
            <div className="heading-02" style={{ width: '100%' }} id="stamped-rewards-widget" data-widget-type="rewards-activities"></div>
          </ActivitesBlock>
        </ActivitiesAndReferralWidgets>
      </WidgetsContainer>

    </AuthLayout>
  );
};
export default StampedRewards;
