import * as React from 'react';
import { SVGProps } from 'react';
const SvgLinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="M25 8.324v15.353A1.323 1.323 0 0 1 23.677 25H8.324A1.323 1.323 0 0 1 7 23.677V8.324A1.324 1.324 0 0 1 8.324 7h15.353A1.323 1.323 0 0 1 25 8.324Zm-12.706 5.558H9.647v8.47h2.647v-8.47Zm.238-2.911a1.527 1.527 0 0 0-1.514-1.536h-.047a1.535 1.535 0 0 0 0 3.07 1.526 1.526 0 0 0 1.561-1.487v-.047Zm9.82 6.236c0-2.546-1.62-3.536-3.229-3.536a3.017 3.017 0 0 0-2.678 1.366h-.074v-1.155h-2.489v8.47h2.647v-4.504a1.757 1.757 0 0 1 1.589-1.896h.1c.842 0 1.467.53 1.467 1.864v4.537h2.647l.02-5.146Z"
    />
  </svg>
);
export default SvgLinkedInIcon;
