import { ExtendedBaseQuery } from 'features/account/types';
import {
  plansListUrl, pausePlanUrl, resumePlanUrl, cancelPlanUrl, createCGplanUrl, cgPlanDetails,
} from 'common/utils/network/endpoints';
import { baseApi } from 'common/store/baseApi';
import { convertObjectFieldNamesToCamelCase, sortPlansAndOrders } from 'common/utils/helpers';
import { CgPlansResponse, PlansResponse, PlanType } from './types';

export const plansApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['Orders'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPlansList: builder.query<PlansResponse, void>({
        query: () => plansListUrl,
        transformResponse: (response: ExtendedBaseQuery) => {
          const parseData = convertObjectFieldNamesToCamelCase(response.data).sort(sortPlansAndOrders);
          return parseData;
        },
      }),
      getPlanDetails: builder.query<PlanType, { id: number | string}>({
        query: (formData) => `${plansListUrl}/${formData.id}`,
        transformResponse: (response: ExtendedBaseQuery) => {
          const parseData = convertObjectFieldNamesToCamelCase(response.data);
          return parseData;
        },
      }),
      pausePlan: builder.mutation<void, { id: number | string}>({
        query: (formData) => ({
          url: pausePlanUrl.replace('%id%', formData.id.toString()),
          method: 'PATCH',
        }),
      }),
      resumePlan: builder.mutation<void, { id: number | string}>({
        query: (formData) => ({
          url: resumePlanUrl.replace('%id%', formData.id.toString()),
          method: 'PATCH',
        }),
      }),
      cancelPlan: builder.mutation<void, { id: number | string}>({
        query: (formData) => ({
          url: cancelPlanUrl.replace('%id%', formData.id.toString()),
          method: 'PATCH',
        }),
      }),
      getCgPlansList: builder.query<CgPlansResponse, void>({
        query: () => createCGplanUrl,
        transformResponse: (response: ExtendedBaseQuery) => {
          const parseData = convertObjectFieldNamesToCamelCase(response.data).sort(sortPlansAndOrders);
          return parseData;
        },
      }),
      getCgPlanDetails: builder.query<CgPlansResponse, { id: number | string}>({
        query: (formData) => cgPlanDetails.replace('%id%', formData.id.toString()),
        transformResponse: (response: ExtendedBaseQuery) => {
          const responseData = response.data as CgPlansResponse[];
          const parseData = convertObjectFieldNamesToCamelCase(responseData);
          return parseData;
        },
      }),
      updateCgPlan: builder.mutation<void, { id: number | string, recipients: number[], action?: string | null }>({
        query: ({ id, recipients, action }) => ({
          url: cgPlanDetails.replace('%id%', id.toString()),
          method: 'PATCH',
          body: JSON.stringify({ recipients, action }),
        }),
      }),
    }),
  });

export const {
  useGetPlansListQuery,
  useGetPlanDetailsQuery,
  usePausePlanMutation,
  useResumePlanMutation,
  useCancelPlanMutation,
  useGetCgPlansListQuery,
  useLazyGetCgPlansListQuery,
  useGetCgPlanDetailsQuery,
  useUpdateCgPlanMutation,
} = plansApi;
