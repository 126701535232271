import { Dict } from 'mixpanel-browser';
import { useCookie } from 'common/hooks';
import { cookieYesCookieName } from 'common/constants';
import { parseCookieYesStringToObject } from '../helpers';
import { Mixpanel } from '.';
import {
  MixpanelEventName, UserEventProps, CustomEventProps, ConsentType, CookieYesObjectType, CookieChoice,
} from './types';
export type { Dict };
export { MixpanelEventName };

const { getCookie } = useCookie();
const cookieyesConsent = getCookie(cookieYesCookieName);

let consentData: ConsentType = null;

if (cookieyesConsent) {
  consentData = (parseCookieYesStringToObject(cookieyesConsent)) as CookieYesObjectType;
}

export const mixpanelUserEvent = ({
  mixpanelProps = {}, id, eventName, companyName,
}: UserEventProps) => {
  if (consentData?.analytics === CookieChoice.yes) {
    try {
      Mixpanel.reset();
      Mixpanel.identify(id.toString());
      Mixpanel.people.set(mixpanelProps);
      if (companyName) {
        Mixpanel.register({ Company: companyName });
      }
      Mixpanel.track(eventName, mixpanelProps);
    } catch (err) {
      console.error('error', `Mixpanel tracking error: ${err}`);
    }
  }
};

export const mixpanelCustomEvent = ({
  mixpanelProps = {}, id, eventName, companyName,
}: CustomEventProps) => {
  if (consentData?.analytics === CookieChoice.yes) {
    try {
      if (eventName === MixpanelEventName.login && companyName) {
        Mixpanel.reset();
        Mixpanel.identify(id.toString());
        Mixpanel.register({ Company: companyName });
      }
      Mixpanel.identify(id.toString());
      Mixpanel.track(eventName, mixpanelProps);
    } catch (err) {
      console.error('error', `Mixpanel tracking error: ${err}`);
    }
  }
};
