import styled from 'styled-components';

export const NameAndPrice = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: flex-start;
`;

export const NamePriceAndPhoto = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    img {
        width: 60px;
        height: 60px;
        border-radius: 6px;
    }
`;

export const PricingPerBox = styled.div`
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
`;

export const PlanInfo = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    padding: 16px 0px;
    border-bottom: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}`};
`;

export const PlanInfoItem = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
`;

export const PlanProperties = styled.div`
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
`;
export const PlanProperty = styled.div`
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    svg {
        min-height: 18px;
        min-width: 18px;
        max-width: 18px;
        max-height: 18px;
        color: ${({ theme }) => theme.palette.text['text-01']};
    }
`;

export const NamePricePhotoAndDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 16px;
    border-bottom: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}`};
    .selection-info{
        p {
            color: ${({ theme }) => theme.palette['ui-base'].grey70};
        }
        .downloadable {
            margin-left:5px;
            display: inline-block;
            cursor: pointer;
            color: ${({ theme }) => theme.palette.button['button-01']};
            &:hover{ 
                color: ${({ theme }) => theme.palette.button['button-01-hover']};
            }
            text-decoration: underline;
        }
    }
`;
export const PlanPropertiesWithGdpr = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
export const GdprCompliant = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
`;
