import { CgPlanType } from 'features/plans/types';
import { Variant } from '../../badge/types';
import Badge from '../../badge';
import Typography from '../../typography';
import { CgProductDetailsContainer, BadgeAndCorporateGiftContainer } from './styles';
import { CgProductSpecifics } from './cgProductSpecifics';

export const CgProductDetails = (props : CgPlanType) => {
  const {
    planType, status,
  } = props;
  const badgeStatusMapping: { [key: string]: Variant } = {
    active: 'success',
    cancelled: 'error',
    paused: 'warning',
    expired: 'info',
  };
  return (
    <CgProductDetailsContainer>
      <BadgeAndCorporateGiftContainer>
        <Badge label={status} variant={badgeStatusMapping[status.toLowerCase()] || 'default'} />
        <Typography variant="heading-compact-01">{planType}</Typography>
      </BadgeAndCorporateGiftContainer>
      <CgProductSpecifics {...props} />
    </CgProductDetailsContainer>
  );
};

export default CgProductDetails;
