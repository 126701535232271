import Badge from 'common/designSystem/badge';
import lang from 'common/lang';
import { LineItems } from 'features/home/types';
import {
  ProductDetailsContainer,
} from './styles';
import ProductSpecifics from './productSpecifics';

export const ProductDetails = (props: LineItems) => {
  const { order: orderCopy } = lang;
  const {
    status, perBoxPrice,
  } = props;

  return (
    <ProductDetailsContainer>
      <Badge label={!status ? orderCopy.placed : status} />
      <ProductSpecifics {...props} perBoxPrice={perBoxPrice} />
    </ProductDetailsContainer>
  );
};

export default ProductDetails;
