import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';

export const OrderContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}`};
    align-items: flex-start;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    @media ${breakpoints.desktopSmall} {
        width: 100%;
    }
`;

export const Summary = styled.div`
    padding: 24px;
    width: calc(100% - 48px);
`;

export const InvoiceContainer = styled.div`
    padding: 24px;
    width: calc(100% - 48px);
    text-align: left;
    margin-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
`;
