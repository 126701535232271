import * as React from 'react';
import { SVGProps } from 'react';
const SvgHandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 19 18" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.038 3.431a1.181 1.181 0 0 0-2.363 0v2.25m2.363-2.25V2.306a1.181 1.181 0 0 1 2.362 0v1.125m-2.362 0 .056 4.444m2.306.563V3.43m0 0a1.181 1.181 0 0 1 2.362 0v7.819M5.675 5.681a1.181 1.181 0 1 0-2.362 0v6.131a5.063 5.063 0 0 0 5.062 5.063h1.513a3.937 3.937 0 0 0 2.785-1.153l1.299-1.3a3.937 3.937 0 0 0 1.153-2.784l.002-1.518a.501.501 0 0 1 .148-.353 1.181 1.181 0 1 0-1.67-1.67 2.863 2.863 0 0 0-.84 2.014m-7.09-4.43V9m4.702 3.239a3.368 3.368 0 0 1 2.385-.989m.002 0h-.002"
    />
  </svg>
);
export default SvgHandIcon;
