import { useEffect } from 'react';
import { EmploymentStatusType } from 'features/plans/types';
import { RecipientRowProps } from './types';
import useUnlabelledCheckbox from '../unlabelledCheckbox/useUnlabelledCheckbox';
import {
  TableRowElement, RowElement, NameRowElement, RowNameElement,
  NameAndDesignation,
} from './styles';
import Typography from '../typography';
export const TableRow = (props: RecipientRowProps &
  { onCheckboxClick: (employmentStatus: string, selectedStatus: boolean) => void,
    selectedEmployees?: Set<number>,
    updateSingleEmployeeSelection: (id: number, isChecked: boolean) => void;
    isNewEmployee? : boolean,
    isCancelClicked? : boolean,
  }) => {
  const { selectedEmployees, updateSingleEmployeeSelection } = props;
  const {
    id, name, designation = '', department, birthday = '',
    startDate = '', dietaryRequirements = '-', homeAddress, employmentStatus = '', onCheckboxClick,
    showCheckbox = true, isFromEditRecipients, isNewEmployee, isCancelClicked,
  } = props;
  const { isChecked, setIsChecked, UnlabelledCheckbox } = useUnlabelledCheckbox(showCheckbox ? selectedEmployees?.has(id) : false);

  useEffect(() => {
    if (showCheckbox) {
      if (selectedEmployees && selectedEmployees?.has(id) && !isChecked) {
        setIsChecked(true);
        return;
      }
      if (!selectedEmployees?.has(id) && isChecked) {
        setIsChecked(false);
      }
    }
  }, [selectedEmployees]);

  useEffect(() => {
    if (isCancelClicked) {
      if (selectedEmployees && selectedEmployees?.has(id) && isNewEmployee) {
        setIsChecked(false);
        updateSingleEmployeeSelection(id, false);
      } else if (selectedEmployees && !selectedEmployees?.has(id) && !isNewEmployee) {
        setIsChecked(true);
        updateSingleEmployeeSelection(id, true);
      }
    }
  }, [isCancelClicked, showCheckbox]);

  useEffect(() => {
    if (showCheckbox) {
      if ((isChecked && !selectedEmployees?.has(id)) || (!isChecked && selectedEmployees && selectedEmployees?.has(id))) {
        updateSingleEmployeeSelection(id, isChecked);
      }
    }
  }, [isChecked]);

  useEffect(() => {
    if (employmentStatus === EmploymentStatusType.active && isFromEditRecipients && !isNewEmployee) {
      setIsChecked(true);
      updateSingleEmployeeSelection(id, true);
    }
  }, [employmentStatus, isFromEditRecipients, isNewEmployee]);

  const handleOnClickOverride = () => {
    onCheckboxClick(employmentStatus, isChecked);
  };
  return (
    <TableRowElement isChecked={isChecked} isFromEditRecipients={isFromEditRecipients}>
      <RowNameElement width="260px" className="sticky recipient-table-row-name" isChecked={isChecked} isFromEditRecipients={isFromEditRecipients}>
        <NameRowElement isChecked={isChecked} isFromEditRecipients={isFromEditRecipients}>
          { showCheckbox && <UnlabelledCheckbox id={id.toString()} onClickOverride={handleOnClickOverride} />}
          <NameAndDesignation>
            <Typography variant="heading-compact-01">
              {name}
            </Typography>
            <Typography variant="helper-text-01" className="designation">
              {designation}
            </Typography>
          </NameAndDesignation>
        </NameRowElement>
      </RowNameElement>
      <RowElement width="224px">
        <Typography variant="body-compact-01">
          {department}
        </Typography>
      </RowElement>
      <RowElement width="180px">
        <Typography variant="body-compact-01">
          {birthday}
        </Typography>
      </RowElement>
      <RowElement width="180px">
        <Typography variant="body-compact-01">
          {startDate}
        </Typography>
      </RowElement>
      <RowElement width="200px">
        <Typography variant="body-compact-01">
          {dietaryRequirements}
        </Typography>
      </RowElement>
      <RowElement width="384px">
        <Typography variant="body-compact-01">
          {homeAddress}
        </Typography>
      </RowElement>
    </TableRowElement>
  );
};

export default TableRow;
