import './style.scss';
import Spinner from './spinner';

type LoaderProps = {
  isFromCgSignup?: boolean;
}
export const Loader = (props : LoaderProps) => {
  const { isFromCgSignup } = props;
  return (
    <div className={isFromCgSignup ? 'cg-loader-container' : 'loader-container'} data-cy="loader">
      <Spinner />
    </div>
  );
};

export default Loader;
