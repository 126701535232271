import Typography from 'common/designSystem/typography';
import { LineItems } from 'features/home/types';
import { formatDeliveryDate } from 'common/utils/helpers';
import lang from 'common/lang';
import {
  ProductSpecificsContainer, PriceContainer, ProductDataContainer, LineItemContainer,
} from './styles';

export const ProductSpecifics = (props: LineItems) => {
  const {
    productTitle, deliveryDate, perBoxPrice, quantity,
  } = props;
  const { order: orderCopy } = lang;

  return (
    <ProductSpecificsContainer>
      <ProductDataContainer>
        <Typography variant="heading-03" className="product-title">
          {productTitle}
        </Typography>
        {deliveryDate && (
          <LineItemContainer>
            <Typography variant="body-01">
              {orderCopy.deliveryDate}
            </Typography>
            <Typography variant="body-01">
              {formatDeliveryDate(deliveryDate)}
            </Typography>
          </LineItemContainer>
        )}
      </ProductDataContainer>
      <PriceContainer>
        {perBoxPrice && perBoxPrice > 0 && (
          <Typography variant="body-03" className="unit-price" noMargin>
            {`£${(perBoxPrice / 100).toFixed(2)}`}
          </Typography>
        )}
        <Typography variant="body-03" className="quantity" noMargin>
          {`x ${quantity}`}
        </Typography>
        {perBoxPrice && (
          <Typography variant="body-03" className="total-price" noMargin>
            {`£${((perBoxPrice * quantity) / 100).toFixed(2)}`}
          </Typography>
        )}
      </PriceContainer>
    </ProductSpecificsContainer>
  );
};

export default ProductSpecifics;
