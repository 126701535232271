import { BillingDetails } from '@stripe/stripe-js';
import { AlertProps } from 'common/designSystem/alert/types';
import { RecipientRowProps } from 'common/designSystem/recipientsTable/types';

/* eslint-disable no-shadow */
export type ShopifyProduct = {
    id: number,
    planType: string,
    anniverSaryCelebrationFrequency: string,
    title: string,
    status: string,
    bodyHtml: string,
    variants: Array<ShopifyVariant>,
    options: Array<VariantOptions>,
    image: ProductImage,
    images : Array<ProductImage>,
    tags: string,
    postCardOptionType?: string,
    openEligibleEmployeeModal?: boolean;
    openGdprModal?: boolean;
    showCgSignUpModal?: false;
};

export type ShopifyVariant = {
    id: number,
    productId: number,
    title: string,
    price: string,
    sku: string,
    position: number,
    taxable: boolean,
};

export type VariantOptions = {
    id: number,
    productId: number,
    name: string,
    position: number,
    values: Array<string>,
};

export type ProductImage = {
    id: number,
    productId: number,
    src: string,
    adminGraphqlApiId: string;
    alt: string;
    createdAt: string;
    height: number;
    position: number;
    updatedAt: string;
    variantIds: number[];
    width: number;
};

export type ShopifyProducts = Array<ShopifyProduct>;

export type CommonStepProps = {
    selectedProduct: ShopifyProduct | null,
    setSelectedProduct: React.Dispatch<React.SetStateAction<ShopifyProduct | null>>;
    setSelectedImageProduct: React.Dispatch<React.SetStateAction<ShopifyProduct | null>>;
};

export type VariantOption = {
    value: string;
    isCustom: boolean;
    fileName?: string;
    selectedFile?: string;
    fileObject?: File | null;
}

export type PostCardDesign = {
    uploadedFileName?: string;
    selectedFile?: string;
    fileObject?: File | null;
}

export type OptionSelection = {
    [key: string]: VariantOption;
};

export enum GiftingPlanType {
    birthday = 'Birthday',
    workAnniversary = 'Work Anniversary',
    anniversary = 'Anniversary',
}

export enum GiftingPostCardOption {
    Text = 'Text',
    Design = 'Design',
}

export enum AnniverSaryCelebrationOption {
    firstYear ='First year',
    everyYear = 'Every year',
}

export type PlanSummaryType = {
    title: string,
    price: string,
    imageSrc: string,
    planType: string,
    isUserLoggedIn?: string,
};

export type EmployeeMap = {
    [key: number]: RecipientRowProps;
}
export type GiftingState = {
    isLoading: boolean,
    selectedStep: number,
    selectedProduct: ShopifyProduct | null,
    selectedImageProduct: ShopifyProduct | null,
    direction: number,
    variantSelection: OptionSelection,
    planType: string,
    customMessage: string,
    connectionDetails: ConnectionDetails | null,
    employeesLoaded: boolean,
    employeeLoadingError: boolean,
    employeeMap: EmployeeMap,
    selectedEmployees: Set<number>;
    pricePerBox: number;
    taxable: boolean;
    alertState: AlertProps;
    planCreated: boolean;
    createdPlanId: number | null;
    postCardDesign: PostCardDesign;
    anniverSaryCelebrationFrequency: string;
    openEligibleEmployeeModal: boolean;
    openGdprModal: boolean;
    postCardOptionType?: string;
    showCgSignUpModal?: boolean;
};

export type LinkTokenType = {
    linkToken: string,
};

export type GetLinkTokenResponse = {
    data: LinkTokenType;
};

export type ConnectionDetails = {
    id: number,
    userId: number,
    name: string,
    logo: string,
    status: string,
    archivedAt: null,
    token: string,
    createdAt: string,
    updatedAt: string,
    lastSyncedAt: string
};

export type ConnectionDetailsResponse = {
    data: ConnectionDetails,
};

export type ActiveConnectionDetailsResponse = Array<ConnectionDetails>;

export type EmployeeDetails = {
    createdAt: string,
    dateOfBirth: string,
    employmentStatus: string,
    firstName: string,
    gender: string,
    id: number,
    jobTitle: string,
    lastName: string,
    mobilePhoneNumber: string | null,
    personalEmail: string | null,
    workEmail: string,
    homeLocation: EmployeeHomeLocation,
    startDate: string,
    hrSystemConnectionId: number,
    homeLocationId: number,
    workLocationId: number,
    modifiedAtHrSystem: string,
    updatedAt: string,
    idHrSystem: string,
    department?: string | null,
    dietaryRequirements: string | null,
};

export type EmployeeHomeLocation = {
    id: number,
    city: string,
    country: string,
    locationType: string,
    name: string,
    state: string,
    street1: string,
    street2: string | null,
    zipCode: string,
    locationIdHrSystem: string,
    phoneNumber: string | null,
    createdAt: string | null,
    updatedAt: string | null,
};

export type EmployeesListingResponse = Array<EmployeeDetails>;

export type UploadedFileWithType = {
    [key: string]: string,
}
export type CardInfo = {
    cardNumber: number,
    nameOnCard: string,
    expirationDate: string,
    securityCode: number,
    reference: string,
}
export type BillingAddress = {
    firstName: string,
    lastName: string,
    company: string,
    billingAddress_1: string,
    billingAddress_2: string,
    city: string,
    postcode: string,
    phone: string,
}
export type PaymentInfo = CardInfo & BillingAddress;

export type CreateSubscriptionDTO = {
    shopifyProductId: number,
    productTitle: string,
    planType: string,
    planStart: string,
    pricePerBox: string,
    variant: string,
    postcardMessage: string | null,
    quantity: number,
    sku: string,
    files: UploadedFileWithType,
    taxable: boolean,
    repeatEveryYear : boolean,
    recipients: Array<number>,
    billingAddress: BillingDetails,
};

export type PostCardDesignDrafts = {
    uploadedFileName: string;
    selectedFile: string;
    fileObject?: File | null;
}

export type CreateSubscriptionDTODraft = {
    shopifyProductId: number,
    productTitle: string,
    planType: string,
    planStart: string,
    pricePerBox: string,
    variant: string,
    postcardMessage: string | null,
    quantity: number,
    sku: string,
    files: UploadedFileWithType,
    taxable: boolean,
    repeatEveryYear : boolean,
};

export type DraftsResponse = {
    selectedProduct: ShopifyProduct,
    planType: string,
    planStart: string,
    variantSelection: OptionSelection,
    postCardOptionType: string,
    postCardDesign: PostCardDesignDrafts,
    postcardMessage: string,
}

export type CardCompletedFields = {
    number: boolean,
    exp: boolean,
    cvc: boolean,
    address: boolean,
};
export type CreateCGPlanData = {
    id: number,
}
export type CreateCGPlanResponse = {
    data: CreateCGPlanData,
};
export enum SortingFieldName {
    Name = 'name',
    Department = 'department',
    Birthday = 'birthday',
    StartDate = 'startDate',
    DietaryRequirements = 'dietary',
    HomeAddress = 'address',
}
