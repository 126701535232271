import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';

export const Header = styled.header`
    position: fixed;
    width: calc(100vw - 56px);
    z-index: 9999;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.background['ui-background']};
    svg {
        min-height: 40px;
        min-width: 76px;
    }
    padding: 16px 28px;
    button {
        margin-left: 16px;
    }
    padding: 0;
    width: 100vw;
    flex-direction: column;
    box-shadow: 0px 2px 4px rgba(30, 30, 30, 0);
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transform: translate3d(0px,0px,0px);
`;

export const Menu = styled.div<{$isOpen: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    svg {
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
        margin-left: 8px;
        min-width: 16px;
        min-height: 16px;
        max-width: 16px;
        max-height: 16px;
        transform: ${({ $isOpen }) => $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
`;

export const MenuOptions = styled.div<{$isOpen: boolean}>`
    z-index: 3;
    position: absolute;
    width: 200px;
    top: 52px;
    right: 0px;
    background: ${({ theme }) => theme.palette['field-02']};
    border-radius: ${({ theme }) => theme.borderRadius['border-radius-medium']};
    background: ${({ theme }) => theme.palette.background['ui-background']};
    box-shadow: ${({ theme }) => `0px 0px 4px ${theme.palette['ui-base'].grey60}`};
    transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    overflow: hidden;
    z-index: 9999;
    opacity: ${({ $isOpen }) => $isOpen ? '1' : '0'};
    pointer-events: ${({ $isOpen }) => $isOpen ? 'all' : 'none'};
    height: ${({ $isOpen }) => $isOpen ? '167px' : '0px'};
    padding: ${({ $isOpen }) => $isOpen ? '8px 0px' : '0px'};
`;

export const MenuOption = styled.div`
    padding: 10px 16px;
    min-width: 168px;
    text-align: left;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s;
    &:hover {
        background: ${({ theme }) => theme.palette['ui-base'].grey30};
    }
`;

export const LogoAndLinks = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 28px;
`;

export const MegaMenuLinks = styled.div<{$isOpen?: boolean}>`
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    h6{
        display: flex;
        align-items: center;
        cursor: pointer;
        color: ${({ theme }) => theme.palette.text['text-01']};
        &:hover {
            color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
        &.active{
            color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
    }
    svg {
        transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
        margin-left: 8px;
        min-width: 16px;
        min-height: 16px;
        max-width: 16px;
        max-height: 16px;
        transform: ${({ $isOpen }) => $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
    &.main-nav-links {
        display: none;
        @media ${breakpoints.tablet} {
            display: flex;
        }
    }
   
`;

export const Links = styled.div`
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    h6{
        cursor: pointer;
        color: ${({ theme }) => theme.palette.text['text-01']};
        &:hover {
            color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
        &.active{
            color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
    }
    &.main-nav-links {
        display: none;
        @media ${breakpoints.tablet} {
            display: flex;
        }
    }
    &.sub-nav-links {
        display: flex;
    }
`;

export const MobileMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 28px;
    height: 28px;
    svg {
        position: absolute;
        min-width: 28px;
        min-height: 28px;
        max-width: 28px;
        max-height: 28px;
        transform: scale(1);
        opacity: 1;
        transition: all 0.2s ease-in-out;
        color: ${({ theme }) => theme.palette.icon['icon-01']};
        &:hover{
            transform: scale(1.07);
        }
        &.show{
            pointer-events: all;
        }
        &.hide{
            pointer-events: none;
            opacity: 0;
            transform: scale(0.8);
        }
    }
    @media ${breakpoints.tablet} {
        display: none;
    }
`;

export const Overlay = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 9997;
    background-color: ${({ theme }) => theme.palette.brand['brand-03']};
    opacity: 0.3;
    display: block;
    transition: all 0.3s ease-in-out;
    top: 72px;
    left: 0px;
    &.hide {
        opacity: 0;
        pointer-events: none;
    }
`;

export const MobileMenuContent = styled.div`
    position: absolute;
    height: 100vh;
    width: 348px;
    z-index: 9998;
    background-color: ${({ theme }) => theme.palette.background['ui-background']};
    display: block;
    transition: all 0.3s ease-in-out;
    top: 72px;
    left: -160%;
    padding: 16px 0;
    box-shadow: inset rgba(30, 30, 30, 0.1) 0px 2px 4px;
    &.hide {
        left: 0%;
    }
`;

export const MobileMenuItem = styled.div`
    padding: 10px 24px;
    width: calc(100% - 48px);
    text-align: left;
    background-color: ${({ theme }) => theme.palette.background['ui-background']};
    transition: all 0.2s ease-in-out;
    &:hover {
        background-color: ${({ theme }) => theme.palette['ui-base'].grey30};
    }
    p{
        color: ${({ theme }) => theme.palette.text['text-01']};
    }
`;

export const MainNav = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 28px;
        width: calc(100vw - 56px);
        @media ${breakpoints.tablet} {
        width: calc(100% - 160px);
        padding: 16px 80px;
        }
`;

export const SubNav = styled.div`
    display: flex;
    width: calc(100% - 32px);
    justify-content: center;
    align-items: center;
    padding: 20px 16px;
    border-top: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey30}`};
`;

export const LogoIcon = styled.div`
    display: flex;
    cursor: pointer;
`;
