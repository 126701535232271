import { LinkProps } from './types';
import './link.scss';
const Link = (props: LinkProps) => {
  const {
    type = 'primary',
    elementType = 'anchor',
    children,
    onClick = () => null,
    ...rest
  } = props;
  return (
    <>
      { elementType === 'anchor' && (
        <a className={`custom-link ${type}`} {...rest}>
          {children}
        </a>
      )}
      { elementType === 'button' && (
        <button type="button" className={`custom-link ${type}`} onClick={onClick}>
          {children}
        </button>
      )}
    </>
  );
};

export default Link;
