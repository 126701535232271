import { useEffect, useRef } from 'react';
import lang from 'common/lang';
import Typography from 'common/designSystem/typography';
import { SubscriptionHeaderContainer, SubscriptionNumberUrl } from './styles';
import { PlansHeaderProps } from './types';

export const SubscriptionHeader = (props: PlansHeaderProps) => {
  const {
    name, createdAt,
  } = props;
  const { order: orderCopy } = lang;
  const authHeaderRef = useRef<HTMLHeadElement | null>(null);
  const orderHeaderRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    authHeaderRef.current = document.querySelector('.auth-header');
    window.addEventListener('scroll', handlePlansHeaderPosition);

    return (() => window.removeEventListener('scroll', handlePlansHeaderPosition));
  }, []);

  const handlePlansHeaderPosition = () => {
    if (authHeaderRef?.current && orderHeaderRef?.current) {
      if (authHeaderRef.current.classList.contains('header-visible')) {
        orderHeaderRef.current.classList.add('header-exists');
        return;
      }
      orderHeaderRef.current.classList.remove('header-exists');
    }
  };

  return (
    <SubscriptionHeaderContainer className="order-header" ref={orderHeaderRef}>
      <SubscriptionNumberUrl>
        <Typography variant="heading-compact-02">
          {`${orderCopy.subscriptionNumber} #${name}`}
        </Typography>
      </SubscriptionNumberUrl>
      <Typography variant="heading-compact-02">
        {createdAt}
      </Typography>
    </SubscriptionHeaderContainer>
  );
};

export default SubscriptionHeader;
