/* eslint-disable no-restricted-syntax */
import lang from 'common/lang';
import { OrderType, LineItems } from 'features/home/types';
import { BillingAttempt, PlanType } from 'features/plans/types';
import { allowedFileTypes } from 'common/constants';
import {
  ConnectionDetails, EmployeeHomeLocation, EmployeeMap, GiftingPlanType, SortingFieldName, VariantOptions,
} from 'features/gifting/types';
import { RecipientRowProps } from 'common/designSystem/recipientsTable/types';
const { components: componentsCopy, gifting } = lang;
let fieldToSort = '';
const camelToSnakeCase = (propertyName: string) => propertyName
  .replace(
    /[A-Z]/g,
    (letter) => `_${letter.toLowerCase()}`,
  );

const snakeToCamelCase = (propertyName: string) => propertyName
  .toLowerCase()
  .replace(/([-_][a-z])/g, (group) => group
    .toUpperCase()
    .replace('-', '')
    .replace('_', ''));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseObjectPropertiesToSnakeCase = (object: any): any => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        return [camelToSnakeCase(key), value];
      }
      const parsedNestedObject = parseObjectPropertiesToSnakeCase(value);
      return [camelToSnakeCase(key), parsedNestedObject];
    }),
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseObjectPropertiesToCamelCase = (object: any): any => {
  if (!object) {
    return;
  }
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        return [snakeToCamelCase(key), value];
      }
      const parsedNestedObject = parseObjectPropertiesToCamelCase(value);
      return [snakeToCamelCase(key), parsedNestedObject];
    }),
  );
};

export const convertObjectFieldNamesToCamelCase = (obj: any): any => {
  if (obj === null) {
    return null;
  }
  if (typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => convertObjectFieldNamesToCamelCase(item));
  }
  return Object.keys(obj).reduce((acc: any, key) => {
    let camelCaseKey: string = key.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
    camelCaseKey = camelCaseKey.replaceAll('_', '');
    // eslint-disable-next-line no-param-reassign
    acc[camelCaseKey] = convertObjectFieldNamesToCamelCase(obj[key]);
    return acc;
  }, {});
};

export const convertObjectFieldNamesFromCamelCaseToSnakeCase = (obj: any): any => {
  if (obj === null) {
    return null;
  }
  if (typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => convertObjectFieldNamesFromCamelCaseToSnakeCase(item));
  }
  return Object.keys(obj).reduce((acc: any, key) => {
    const snakeCaseKey: string = key.replace(/([A-Z])/g, (group) => `_${group.toLowerCase()}`);
    // eslint-disable-next-line no-param-reassign
    acc[snakeCaseKey] = convertObjectFieldNamesFromCamelCaseToSnakeCase(obj[key]);
    return acc;
  }, {});
};

export const formatOrderCreationDateAndTime = (dateString: string): string => {
  const months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December',
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedDate = `${day} ${month} ${year}`;
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  return `${formattedDate} at ${formattedTime}`;
};

export const formatDeliveryDate = (dateString: string): string => {
  const months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December',
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  let suffix = 'th';
  if (day === 1 || day === 21 || day === 31) {
    suffix = 'st';
  } else if (day === 2 || day === 22) {
    suffix = 'nd';
  } else if (day === 3 || day === 23) {
    suffix = 'rd';
  }

  const formattedDate = `${month} ${day}${suffix}, ${year}`;
  return formattedDate;
};

export const parseCookieYesStringToObject = (str: string) => {
  const obj: {[key: string]: string} = {};
  const keyValuePairs = str.split(',');

  for (let i = 0; i < keyValuePairs.length; i += 1) {
    const [key, value] = keyValuePairs[i].split(':');
    obj[key.trim()] = value.trim();
  }
  return obj;
};

export const formatCreatedAtDate = (dateString: string): string => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();

  const formattedDate = `${day} ${month} ${year}`;
  const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

  return `${formattedDate} at ${formattedTime}`;
};

export const capitalizeFirstLetter = (input: string): string => {
  if (!input || input.length === 0) {
    return '';
  }

  const firstLetter = input.charAt(0).toUpperCase();
  const restOfTheString = input.slice(1).toLowerCase();

  return firstLetter + restOfTheString;
};

export const formatPlanDates = (dateString: string): string => {
  const months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December',
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};

export const getSampleLineItem = (order: OrderType, title: string): LineItems | null => {
  const sampleLineItem = order.orderLineItems.find((lineItem: LineItems) => title.includes(lineItem.productTitle) && title.includes(lineItem.variant));
  if (sampleLineItem) {
    return sampleLineItem;
  }
  return null;
};

export const sortPlansAndOrders = (plan1: PlanType | OrderType, plan2: PlanType | OrderType): number => {
  const parsedDate1 = new Date(plan1.createdAt);
  const parsedDate2 = new Date(plan2.createdAt);

  if (parsedDate1 > parsedDate2) {
    return -1;
  }
  if (parsedDate1 < parsedDate2) {
    return 1;
  }
  return 0;
};

export const sortBillingAttempts = (attempt1: BillingAttempt, attempt2: BillingAttempt): number => {
  const parsedDate1 = new Date(attempt1.date);
  const parsedDate2 = new Date(attempt2.date);

  if (parsedDate1 > parsedDate2) {
    return 1;
  }
  if (parsedDate1 < parsedDate2) {
    return -1;
  }
  return 0;
};

const getMonthName = (monthIndex: number): string => {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];
  return monthNames[monthIndex];
};

export const getPlanStartDate = (): string => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  if (today.getDate() < 25) {
    const nextMonth = (currentMonth + 1) % 12;
    return `${getMonthName(nextMonth)} ${currentYear}`;
  } else {
    const nextMonth = (currentMonth + 2) % 12;
    const nextYear = currentMonth === 10 || currentMonth === 11 ? currentYear + 1 : currentYear;
    return `${getMonthName(nextMonth)} ${nextYear}`;
  }
};

export const generateFileName = (name: string, userId: number) => {
  const date = new Date();
  return `user_uploads/user_${userId}/file_${date.getDate()}_${date.getMonth()}_${date.getFullYear()}_${Math.floor(Math.random() * 4999) + 1000}_${name.replace(' ', '')}`;
};

export const validateFile = (file: File) => {
  let error = '';

  if (!allowedFileTypes.includes(file.type)) {
    error = componentsCopy.allowedFileTypes;
  }

  if (file.size > 52428800) {
    error = componentsCopy.fileSizeLimit;
  }

  return error;
};

export const downloadFile = (file: File): void => {
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = file.name;

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
  });
  a.dispatchEvent(clickEvent);

  URL.revokeObjectURL(url);
};

export const getFirstActiveConnection = (objects: ConnectionDetails[]): ConnectionDetails | null => {
  for (const obj of objects) {
    if (obj.status === 'active') {
      return obj;
    }
  }
  return null;
};

export const handleExternalLink = (url: string) => {
  window.open(url, '_blank');
};

export const formatDateWithTime = (inputDate: string): string => {
  const date = new Date(inputDate);

  const day = date.getDate();
  let daySuffix = 'th';

  if (day === 1 || day === 21 || day === 31) {
    daySuffix = 'st';
  } else if (day === 2 || day === 22) {
    daySuffix = 'nd';
  } else if (day === 3 || day === 23) {
    daySuffix = 'rd';
  }

  const formattedDate = `${day}${daySuffix} ${date.toLocaleString('en-US', { month: 'long' })} ${date.getFullYear()}`;
  const formattedTime = date.toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true });

  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return formattedDateTime;
};

export const formatMergeDateToCDDate = (inputDate: string): string => {
  const [year, month, day] = inputDate.split('-');
  const date = new Date(`${year}-${month}-${day}`);
  const formattedDay = date.getDate().toString().padStart(2, '0');
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month is 0-based, so we add 1
  const formattedYear = date.getFullYear();
  const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;
  return formattedDate;
};

export const parseDate = (dateString: string): string => {
  const [day, month, year] = dateString.split('/');
  const formattedDay = day.padStart(2, '0');
  const formattedMonth = month.padStart(2, '0');
  return `${year}-${formattedMonth}-${formattedDay}`;
};

export const sortEmployeesAscending = (employee1: RecipientRowProps, employee2: RecipientRowProps): number => {
  switch (fieldToSort) {
    case SortingFieldName.Department:
      return employee1.department.localeCompare(employee2.department);
    case SortingFieldName.Birthday:
      return new Date(parseDate(employee1.birthday)).getTime() - new Date(parseDate(employee2.birthday)).getTime();
    case SortingFieldName.StartDate:
      return new Date(parseDate(employee1.startDate)).getTime() - new Date(parseDate(employee2.startDate)).getTime();
    case SortingFieldName.DietaryRequirements:
      if (employee1.dietaryRequirements && employee2.dietaryRequirements) {
        return employee1.dietaryRequirements.localeCompare(employee2.dietaryRequirements);
      }
      return 0;
    case SortingFieldName.HomeAddress:
      return employee1.homeAddress.localeCompare(employee2.homeAddress);
    default:
      return employee1.name.localeCompare(employee2.name);
  }
};

export const sortEmployeesDescending = (employee1: RecipientRowProps, employee2: RecipientRowProps): number => {
  switch (fieldToSort) {
    case SortingFieldName.Department:
      return employee2.department.localeCompare(employee1.department);
    case SortingFieldName.Birthday:
      return new Date(parseDate(employee2.birthday)).getTime() - new Date(parseDate(employee1.birthday)).getTime();
    case SortingFieldName.StartDate:
      return new Date(parseDate(employee2.startDate)).getTime() - new Date(parseDate(employee1.startDate)).getTime();
    case SortingFieldName.DietaryRequirements:
      if (employee1.dietaryRequirements && employee2.dietaryRequirements) {
        return employee2.dietaryRequirements.localeCompare(employee1.dietaryRequirements);
      }
      return 0;
    case SortingFieldName.HomeAddress:
      return employee2.homeAddress.localeCompare(employee1.homeAddress);
    default:
      return employee2.name.localeCompare(employee1.name);
  }
};

export const getFormattedAddress = (employeeHomeAddress: EmployeeHomeLocation): string => {
  const {
    street1, street2, city, state, zipCode,
  } = employeeHomeAddress;
  let address = street1 ? `${street1}` : '';
  address += street2 ? `, ${street2}` : '';
  address += city ? `, ${city}` : '';
  address += state ? `, ${state}` : '';
  address += zipCode ? `, ${zipCode}` : '';

  return address;
};

export const filterEmployeesByKeyword = (arr: RecipientRowProps[], keyword: string): Promise<RecipientRowProps[]> => {
  return new Promise<RecipientRowProps[]>((resolve, reject) => {
    try {
      const filteredArray = arr.filter((item: RecipientRowProps) => item.name.toLowerCase().includes(keyword.toLowerCase()));
      resolve(filteredArray);
    } catch {
      reject(new Error('Filtering was interrupted.'));
    }
  });
};

export const sortEmployeesInAscending = (arr: RecipientRowProps[], field? : string): Promise<RecipientRowProps[]> => {
  return new Promise<RecipientRowProps[]>((resolve, reject) => {
    try {
      fieldToSort = field || '';
      const sortedArray = arr.sort(sortEmployeesAscending);
      resolve(sortedArray);
    } catch {
      reject(new Error('Sorting was interrupted.'));
    }
  });
};

export const sortEmployeesInDescending = (arr: RecipientRowProps[], field? : string): Promise<RecipientRowProps[]> => {
  return new Promise<RecipientRowProps[]>((resolve, reject) => {
    try {
      fieldToSort = field || '';
      const sortedArray = arr.sort(sortEmployeesDescending);
      resolve(sortedArray);
    } catch {
      reject(new Error('Sorting was interrupted.'));
    }
  });
};

export const selectedEmployeesUnion = (setA: Set<number>, setB:Set<number>) => {
  const union = new Set(setA);
  setB.forEach((value: number) => {
    union.add(value);
  });
  return union;
};

export const selectedEmployeesDifference = (setA: Set<number>, setB:Set<number>) => {
  const difference = new Set(setA);
  setB.forEach((value: number) => {
    difference.delete(value);
  });
  return difference;
};

export const getPlanStartMonth = (): string => {
  const today = new Date();
  const currentMonth = today.getMonth();

  if (today.getDate() < 25) {
    const nextMonth = (currentMonth + 1) % 12;
    return (nextMonth + 1).toString();
  } else {
    const nextMonth = (currentMonth + 2) % 12;
    return (nextMonth + 1).toString();
  }
};

export const getBirthdayMonth = (birthday: string | null): string | null => {
  let birthdayMonth = null;
  const [, month] = birthday?.split('-') || [];
  try {
    const parsedMonth = parseInt(month);
    if (parsedMonth >= 1 && parsedMonth <= 12) {
      birthdayMonth = month;
    }
  } catch {
    return null;
  }
  return birthdayMonth;
};

export const getAnniversaryMonth = (startDate: string | null): string | null => {
  let anniversaryMonth = null;
  const [, month] = startDate?.split('-') || [];
  try {
    const parsedMonth = parseInt(month);
    if (parsedMonth >= 1 && parsedMonth <= 12) {
      anniversaryMonth = month;
    }
  } catch {
    return null;
  }
  return anniversaryMonth;
};

export const getPlanMonth = (planType : string, birthday?: string | null, startDate?: string | null) : string | null => {
  const planCelebrationMonth = null;
  const dateToSplit = planType === GiftingPlanType.birthday ? birthday : startDate;
  const [, month] = (dateToSplit?.split('-') || []);

  const parsedMonth = parseInt(month);
  return parsedMonth >= 1 && parsedMonth <= 12 ? month : planCelebrationMonth;
};

export const getPlanStartMonthForGifting = (planType : string, selectedEmployees: Set<number>, employeeMap: EmployeeMap, planStartMonth: string): number => {
  let planStartMonthForGifting = 0;

  if (selectedEmployees && selectedEmployees.size > 0) {
    selectedEmployees?.forEach((empId: number) => {
      const startMonth = Number(planStartMonth) <= 9 ? `0${planStartMonth}` : planStartMonth;
      const employeeHasMatchingMonth = planType === GiftingPlanType.birthday
        ? employeeMap[empId]?.birthdayMonth === startMonth
        : employeeMap[empId]?.anniversaryMonth === startMonth;

      if (employeeHasMatchingMonth) {
        planStartMonthForGifting += 1;
      }
    });
  }
  return planStartMonthForGifting;
};

export const getPlanStartMonthName = (): string => {
  const today = new Date();
  const currentMonth = today.getMonth();

  if (today.getDate() < 25) {
    const nextMonth = (currentMonth + 1) % 12;
    return getMonthName(nextMonth);
  } else {
    const nextMonth = (currentMonth + 2) % 12;
    return getMonthName(nextMonth);
  }
};

export const getPlanPaymentMonthName = (): string => {
  const today = new Date();
  const currentMonth = today.getMonth();

  if (today.getDate() < 25) {
    return getMonthName(currentMonth);
  } else {
    const nextMonth = (currentMonth + 1) % 12;
    return getMonthName(nextMonth);
  }
};

export const getPlanStartForBilling = (): string => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  if (today.getDate() < 25) {
    const nextMonth = currentMonth + 1;
    return `25/${nextMonth}/${currentYear}`;
  }
  const nextMonth = (currentMonth + 2) % 12;
  const nextYear = currentMonth === 10 || currentMonth === 11 ? currentYear + 1 : currentYear;
  return `${getMonthName(nextMonth)} ${nextYear}`;
};

export const formatDateToYYYYMMDD = (inputDate: Date): string => {
  const year = inputDate.getFullYear().toString().padStart(4, '0');
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  const day = inputDate.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const formatDateToMonthandYYYY = (inputDate: Date): string => {
  const options = { year: 'numeric', month: 'long' } as const;
  return inputDate.toLocaleDateString(undefined, options);
};

export const getPlanBillingStartDate = (): string => {
  const today = new Date();
  const targetDate = new Date(today.getFullYear(), today.getMonth(), 25);

  if (today.getDate() < 25) {
    return formatDateToYYYYMMDD(targetDate);
  }
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 25);
  return formatDateToYYYYMMDD(nextMonth);
};

const formatBillingStartDate = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getDietaryRequirementsOptions = (options: Array<VariantOptions>): string[] => {
  for (const option of options) {
    if (option.name === gifting.dietaryRequiements) {
      return option.values;
    }
  }
  return [];
};

export const openExternalLink = (externalLink: string) => {
  window.location.href = externalLink;
};

export const truncatingLongLabels = (label: string) => {
  const maxLabelLength = 30;
  const ellipseLength = 3;
  if (label.length > maxLabelLength) {
    return `${label.slice(0, maxLabelLength - ellipseLength)}...`;
  }
  return label;
};

export function checkDatetimeDifference(loggedDatetimeStr? : string) : boolean {
  if (!loggedDatetimeStr) {
    return false;
  }
  const loggedDatetime = new Date(loggedDatetimeStr);
  const currentDatetime = new Date();
  return (currentDatetime.getTime() - loggedDatetime.getTime()) / (1000 * 3600) > 48;
}
