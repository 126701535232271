/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const TableContainer = styled.div`
    position: relative;
    width: max-content;
    height: max-content;
    border-radius: 6px;
    z-index: 6;
    background: ${({ theme }) => theme.palette.background['ui-background']};
`;
export const TableContainerOuter = styled.div`
    position: relative;
    width: 100%;
    height: 65vh;
    max-height: 65vh;
    overflow: scroll;
    border-radius: 6px;
    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
export const TableHead = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: sticky;
    top: 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}`};
    width: max-content;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    z-index: 5;
    .active-arrow-icon {
        stroke: ${({ theme }) => theme.palette.button['button-01']};
    }
    .disabled-arrow-icon {
        stroke: ${({ theme }) => theme.palette.text['text-03']};
    }
`;

export const EmployeeEmptyState = styled.div`
    display: flex;
    padding: 32px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
`;

export const NameHeadElement = styled.div`
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    width: 260px;
    min-width: 260px;
    max-width: 260px;
    .custom-checkbox {
        margin-right: 14px;
    }
    .arrow-icon{
        cursor: pointer;
        min-height: 16px;
        min-width: 16px;
        max-width: 16px;
        max-height: 16px;
        transition: all 0.3s ease-in-out;
        transform: rotate(0deg);
        color: ${({ theme }) => theme.palette.button['button-01']};
        &:hover{ 
            color: ${({ theme }) => theme.palette.button['button-01-hover']};
        }
    }
    .sorting-icon{
        min-height: 16px;
        min-width: 16px;
        max-width: 16px;
        max-height: 16px;
        transform: rotate(0deg);
        animation: rotate 0.5s linear infinite;
        opacity: 0.7;
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
`;

export const HeaderElement = styled.div<{ width?: string}>`
    padding: 16px 8px;
    width: ${({ width }) => width ? width : 'auto'};
    min-width: ${({ width }) => width ? width : '144px'};
    max-width: ${({ width }) => width ? width : '144px'};
    display: flex;
    gap: 8px;
    &.sticky {
        position: sticky;
        left: 0;
        z-index: 4;
        background-color: white;
        transition: box-shadow 0.3s ease-in-out;
        will-change: box-shadow;
    }
    &.shadowed {
        box-shadow: rgba(41, 59, 97, 0.1) 1px 0px 8px 0px;
    }
     .arrow-icon{
        cursor: pointer;
        min-height: 16px;
        min-width: 16px;
        max-width: 16px;
        max-height: 16px;
        transition: all 0.3s ease-in-out;
        transform: rotate(0deg);
    }
    .inverted {
        transform: rotate(180deg);
    }
    z-index: 3;
`;
export const RowNameElement = styled.div<{ width?: string, isChecked?:boolean, isFromEditRecipients?: boolean}>`
    padding: 8px 8px;
    width: ${({ width }) => width ? width : 'auto'};
    min-width: ${({ width }) => width ? width : '144px'};
    max-width: ${({ width }) => width ? width : '144px'};
    &.sticky {
        position: sticky;
        z-index: 2;
        left: 0;
    }
    transition: box-shadow 0.3s ease-in-out;
    will-change: box-shadow;
    background: ${({ isChecked, theme, isFromEditRecipients }) => !isFromEditRecipients
    ? theme.palette.background['ui-background']
    : isChecked
      ? theme.palette.background['ui-background']
      : theme.palette['ui-base'].grey20};
`;

export const NameAndDesignation = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: flex-start;
    min-height: 38px;
    .designation {
        color: ${({ theme }) => theme.palette['ui-base'].grey70};
    }
`;

export const RowElement = styled.div<{ width?: string}>`
    padding: 16px 8px;
    width: ${({ width }) => width ? width : 'auto'};
    min-width: ${({ width }) => width ? width : '144px'};
    max-width: ${({ width }) => width ? width : '144px'};
    &.sticky {
        position: sticky;
    left: 0;
    }
    z-index: 1;
    p {
        white-space: wrap;
    }
`;
export const TableRowElement = styled.div<{ isChecked?: boolean, isFromEditRecipients?: boolean}>`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-bottom: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}`};
    width: max-content;
    background: ${({ isChecked, theme, isFromEditRecipients }) => !isFromEditRecipients
    ? theme.palette.background['ui-background']
    : isChecked
      ? theme.palette.background['ui-background']
      : theme.palette['ui-base'].grey20};
    &:last-child{
        border-bottom: unset;
    }
`;

export const NameRowElement = styled.div<{ isChecked?: boolean, isFromEditRecipients?: boolean}>`
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    width: 260px;
    min-width: 260px;
    background-color: white;
    .custom-checkbox {
        margin-right: 14px;
    }
    .badge-container {
        width: 8px;
        height: 8px;
        padding: 0px;
        flex-shrink: 0;
    }
    z-index: 2;
    background: ${({ isChecked, theme, isFromEditRecipients }) => !isFromEditRecipients
    ? theme.palette.background['ui-background']
    : isChecked
      ? theme.palette.background['ui-background']
      : theme.palette['ui-base'].grey20};
   
`;

export const TableShadowAndBorder = styled.div`
    position: fixed;
    width: calc(100% - 11px);
    height: calc(65vh - 11px);
    border-radius: 6px;
    z-index: 10;
    pointer-events: none;
    border: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}`};
    box-shadow: -8px 0px 8px 0px rgba(41, 59, 97, 0.10) inset;
`;

export const TableRowsContainer = styled.div`
    &.column-shadowed > div > .recipient-table-row-name {
     box-shadow: rgba(41, 59, 97, 0.1) 1px 0px 8px 0px;
    }
`;
