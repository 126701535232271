/* eslint-disable no-shadow */
import { breakpoints } from 'common/theme/breakpoints';
import theme from 'common/theme/theme';
import styled from 'styled-components';

export const MegaMenuContainer = styled.div<{$isOpen: boolean}>`
    z-index: 3;
    position: absolute;
    width: 100%;
    top: 72px;
    right: 0px;
    z-index: 9999;
    border-radius: ${({ theme }) => theme.borderRadius['border-radius-medium']};
    background: ${({ theme }) => theme.palette.brand['brand-03']};
    box-shadow: ${({ theme }) => `0px 0px 4px ${theme.palette['ui-base'].grey60}`};
    transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    overflow: hidden;
    opacity: ${({ $isOpen }) => $isOpen ? '1' : '0'};
    pointer-events: ${({ $isOpen }) => $isOpen ? 'all' : 'none'};
`;

export const PopularCollectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
`;

export const PopularCollectionProduct = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    cursor: pointer;
    .collection-block{
        img{
            width: 100px;
            height: 100px;
        }
        color: ${({ theme }) => theme.palette.text['text-04']} !important;
        font-family: "Raleway";
        font-weight: 400;
        line-height: 18px;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 18px;
        cursor: pointer;
        &:hover {
            .body-compact-01{
                color: ${({ theme }) => theme.palette.link['link-01-hover']} !important;
            }
        }
        .body-compact-01{
            color: ${({ theme }) => theme.palette.text['text-04']} !important;
        }
    }
`;

export const MegaMenuSubSContainer = styled.div`
    padding: 24px 40px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .heading {
      cursor: default;
    }
    @media ${breakpoints.desktopLarge} {
      padding: 24px 100px;
  }
`;
export const ViewMoreCollection = styled.div`
    .heading-compact-01{
      color: ${({ theme }) => theme.palette.link['link-01']};
    }
    &:hover {
      .heading-compact-01{
        color: ${({ theme }) => theme.palette.link['link-01-hover']};
      }
    }
`;

export const OccasionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    .occasion-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .body-compact-01 {
            cursor: pointer;
            color: ${({ theme }) => theme.palette.text['text-04']} !important;
            &:hover {
                color: ${({ theme }) => theme.palette.link['link-01-hover']} !important;
            }
        }
    }
`;

export const ViewCollectionContainer = styled.div`
    display: flex;
   cursor: pointer;
   .heading-compact-01{
    color: ${({ theme }) => theme.palette.link['link-01']};
  }
  &:hover {
    .heading-compact-01{
      color: ${({ theme }) => theme.palette.link['link-01-hover']};
    }
  }
`;

export const MobileMegaMenuContainer = styled.div`
  position: relative;
  display: inline-block;
  min-width: 348px;
`;

export const MegaMenuHeading = styled.div<{$isOpen: boolean}>`
  background-color: ${({ $isOpen }) => ($isOpen ? theme.palette['ui-base'].grey30 : theme.palette.background['ui-background'])};
  padding: 10px 24px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-height: 28px;
  display: flex;
  align-items: center;
  svg {
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    margin-left: 8px;
    min-width: 16px !important;
    min-height: 16px !important;
    max-width: 16px !important;
    max-height: 16px !important;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  .mobile-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.palette.text['text-01']};
  }
`;

export const DropdownContainer = styled.div<{$isOpen: boolean}>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 4px;
  z-index: 100;
  min-width: 348px;
  max-height: 80vh;
  overflow: auto;
  transition: display 0.3s;
`;

export const MegaMenuCollectionHeading = styled.div<{$collectionDropdownOpen: boolean}>`
  background-color: ${({ $collectionDropdownOpen }) => ($collectionDropdownOpen ? theme.palette['ui-base'].grey30 : theme.palette.background['ui-background'])};
  padding: 10px 24px 10px 40px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-height: 28px;
  display: flex;
  align-items: center;
  svg {
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    margin-left: 8px;
    min-width: 16px !important;
    min-height: 16px !important;
    max-width: 16px !important;
    max-height: 16px !important;
    transform: ${({ $collectionDropdownOpen }) => ($collectionDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  .mobile-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.palette.text['text-01']};
  }
`;

export const MegaMenuOcassionHeading = styled.div<{$ocassionDropdownOpen: boolean}>`
  background-color: ${({ $ocassionDropdownOpen }) => ($ocassionDropdownOpen ? theme.palette['ui-base'].grey30 : theme.palette.background['ui-background'])};
  padding: 10px 24px 10px 40px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-height: 28px;
  display: flex;
  align-items: center;
  svg {
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    margin-left: 8px;
    min-width: 16px !important;
    min-height: 16px !important;
    max-width: 16px !important;
    max-height: 16px !important;
    transform: ${({ $ocassionDropdownOpen }) => ($ocassionDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  .mobile-heading {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text['text-01']};
  }
`;

export const CollectionContainer = styled.div<{$collectionDropdownOpen: boolean}>`
  display: ${({ $collectionDropdownOpen }) => ($collectionDropdownOpen ? 'flex' : 'none')};
  flex-direction: column;
  left: 0;
  border-radius: 4px;
  z-index: 100;
  min-height: 370px;
  transition: display 0.3s;
  gap : 16px;
  padding: 10px 24px 10px 56px;
`;

export const PopularCollectionProductForMobile = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  max-width: 270px;
  align-items: center;
  gap: 18px;
  cursor: pointer;
  .collection-block {
    color: ${({ theme }) => theme.palette.text['text-01']} !important;
    font-family: 'Raleway';
    font-weight: 400;
    line-height: 18px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    cursor: pointer;
    img {
      width: 80px;
      height: 80px;
    }
    &:hover {
      .body-compact-01 {
        color: ${({ theme }) => theme.palette.link['link-01-hover']} !important;
      }
    }
    .body-compact-01 {
      color: ${({ theme }) => theme.palette.text['text-01']} !important;
    }
  }
`;

export const OccasionContainerForMobile = styled.div<{$ocassionDropdownOpen: boolean}>`
    display: ${({ $ocassionDropdownOpen }) => ($ocassionDropdownOpen ? 'flex' : 'none')};
    flex-direction: column;
    gap: 24px;
    .occasion-list {
        display: flex;
        flex-direction: column;
        gap: 24px;      
        .body-compact-01 {
           cursor: pointer;
           color: ${({ theme }) => theme.palette.text['text-01']} !important;
           &:hover {
              color: ${({ theme }) => theme.palette.link['link-01-hover']} !important;
           }
        }
        }
    }
    flex-direction: column;
    left: 0;
    border-radius: 4px;
    z-index: 100;
    transition: display 0.3s;
    gap : 16px;
    padding: 10px 24px 10px 56px;
`;

export const CorporateGiftingContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 24px;
  cursor: pointer;
  &:hover {
      .body-compact-01 {
        color: ${({ theme }) => theme.palette.link['link-01-hover']} !important;
      }
    }
  .body-compact-01 {
      color: ${({ theme }) => theme.palette.text['text-01']} !important;
  }
`;

export const ImageContainer = styled.div`
  margin-top: 10px;
`;
