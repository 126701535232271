import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { AuthState, Token, User } from 'features/account/types';
import type { RootState } from 'common/store/types';

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, token } }: PayloadAction<{ user: User | null; token: Token | null }>,
    ) => {
      state.user = user;
      state.token = token;
    },
    clearCredentials: (state) => {
      state.user = authSlice.getInitialState().user;
      state.token = authSlice.getInitialState().token;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state.user = authSlice.getInitialState().user;
      state.token = authSlice.getInitialState().token;
    });
  },
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
