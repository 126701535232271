import Typography from 'common/designSystem/typography';
import lang from 'common/lang';
import { LineItems } from 'features/home/types';
import { productUrl } from 'common/constants';
import { ProductDataContainer, LineItemContainer } from './styles';

export const ProductData = (props: LineItems) => {
  const {
    productTitle, variant,
    logo, website, brandGuidelines, ribbonDetail, postcardMessage,
    postcardDesign, recipients, shopifyProduct, quantity,
  } = props;
  const { order: orderCopy } = lang;
  const batchQuantity = shopifyProduct.batchQuantity || 1;
  const productMainUrl = `${productUrl}${shopifyProduct?.handle || ''}`;
  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };
  return (
    <ProductDataContainer>
      <Typography variant="heading-03" className="product-title" onClick={() => handleExternalLink(productMainUrl)}>
        {productTitle}
      </Typography>
      <Typography variant="body-01" noMargin>
        {variant}
      </Typography>
      {logo && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.logo}
          </Typography>
          <Typography variant="body-compact-01-underline" onClick={() => handleExternalLink(logo)}>
            {orderCopy.link}
          </Typography>
        </LineItemContainer>
      )}
      {website && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.website}
          </Typography>
          <Typography variant="body-01">
            {website}
          </Typography>
        </LineItemContainer>
      )}
      {brandGuidelines && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.brandGuidelines}
          </Typography>
          <Typography variant="body-compact-01-underline" onClick={() => handleExternalLink(brandGuidelines)}>
            {orderCopy.link}
          </Typography>
        </LineItemContainer>
      )}
      {ribbonDetail && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.ribbon}
          </Typography>
          {!ribbonDetail.includes('https') && (
            <Typography variant="body-01">
              {ribbonDetail}
            </Typography>
          )}
          {ribbonDetail.includes('https') && (
            <Typography variant="body-compact-01-underline" onClick={() => handleExternalLink(ribbonDetail)}>
              {orderCopy.link}
            </Typography>
          )}
        </LineItemContainer>
      )}
      {postcardMessage && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.postcardMessage}
          </Typography>
          <Typography variant="body-01">
            {postcardMessage}
          </Typography>
        </LineItemContainer>
      )}
      {postcardDesign && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.postcardDesign}
          </Typography>
          <Typography variant="body-compact-01-underline" onClick={() => handleExternalLink(postcardDesign)}>
            {orderCopy.link}
          </Typography>
        </LineItemContainer>
      )}
      {recipients && (
        <LineItemContainer>
          <Typography variant="body-01">
            {orderCopy.multipleRecipients}
          </Typography>
          <Typography variant="body-compact-01-underline" onClick={() => handleExternalLink(recipients)}>
            {orderCopy.link}
          </Typography>
        </LineItemContainer>
      )}
      <LineItemContainer>
        <Typography variant="body-01">
          {orderCopy.quantity}
        </Typography>
        <Typography variant="body-01">
          {`${quantity} (${quantity * batchQuantity} treats)`}
        </Typography>
      </LineItemContainer>
    </ProductDataContainer>
  );
};

export default ProductData;
