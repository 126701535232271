import React, { useState } from 'react';
import lang from 'common/lang';
import brandedTreats from 'assets/images/brandedTreats.png';
import classicTreats from 'assets/images/classicTreats.png';
import letterboxTreats from 'assets/images/letterboxTreats.png';
import seasonalTreats from 'assets/images/seasonalTreats.png';
import { externalLinks } from 'common/constants';
import { openExternalLink } from 'common/utils/helpers';
import {
  CollectionContainer,
  CorporateGiftingContainer,
  DropdownContainer,
  MegaMenuCollectionHeading,
  MegaMenuHeading,
  MobileMegaMenuContainer,
  PopularCollectionProductForMobile,
  ViewCollectionContainer,
} from './styles';
import { ChevronDown } from '../iconComponents';
import Typography from '../typography';
import { CollectionItem } from './types';

const MobileMegaMenu = () => {
  const { header: headerCopy, megaMenu: megaMenuCopy } = lang;
  const [isOpen, setIsOpen] = useState(false);
  const [collectionDropdownOpen, setCollectionDropdownOpen] = useState(false);

  const popularCollection = [
    { name: megaMenuCopy.brandedTreats, image: brandedTreats },
    { name: megaMenuCopy.letterboxTreats, image: letterboxTreats },
    { name: megaMenuCopy.classicTreats, image: classicTreats },
    { name: megaMenuCopy.seasonalTreats, image: seasonalTreats },
  ];

  const toggleShopDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleCollectionDropdown = () => {
    setCollectionDropdownOpen(!collectionDropdownOpen);
  };

  const handlePopularCollectionClick = (collection: string) => {
    let collectionUrl;
    switch (collection) {
      case `${megaMenuCopy.brandedTreats}`:
        collectionUrl = externalLinks.brandedTreatsLink;
        break;
      case `${megaMenuCopy.letterboxTreats}`:
        collectionUrl = externalLinks.letterBoxTreatsLink;
        break;
      case `${megaMenuCopy.classicTreats}`:
        collectionUrl = externalLinks.classicTreatsLink;
        break;
      default:
        break;
    }
    if (collectionUrl) {
      openExternalLink(collectionUrl);
    }
  };

  return (
    <>
      <MobileMegaMenuContainer>
        <MegaMenuHeading onClick={toggleShopDropdown} $isOpen={isOpen}>
          <Typography variant="body-compact-01" className="mobile-heading">
            {headerCopy.browseTreats}
            {' '}
            <ChevronDown />
          </Typography>
        </MegaMenuHeading>
        <DropdownContainer $isOpen={isOpen}>
          <MegaMenuCollectionHeading onClick={toggleCollectionDropdown} $collectionDropdownOpen={collectionDropdownOpen}>
            <Typography variant="body-compact-01" className="mobile-heading">
              {megaMenuCopy.cakedropPopularCollections}
              {' '}
              <ChevronDown />
            </Typography>
          </MegaMenuCollectionHeading>
          <CollectionContainer $collectionDropdownOpen={collectionDropdownOpen}>
            {popularCollection.map((collection: CollectionItem) => (
              <PopularCollectionProductForMobile key={collection.name} onClick={() => handlePopularCollectionClick(collection.name)}>
                <div className="collection-block">
                  <img src={collection.image} alt={collection.name} />
                  <Typography variant="body-compact-01">{collection.name}</Typography>
                </div>
              </PopularCollectionProductForMobile>
            ))}
            <ViewCollectionContainer onClick={() => openExternalLink(externalLinks.shop)} data-cy="view-more-collection-link">
              <Typography
                variant="heading-compact-01"
              >
                {megaMenuCopy.viewMoreCollection}
              </Typography>
            </ViewCollectionContainer>
          </CollectionContainer>
          {isOpen && (
            <CorporateGiftingContainer onClick={() => openExternalLink(externalLinks.corporateGiftingRegisterLink)}>
              <Typography variant="body-compact-01">
                {headerCopy.automatedGifting}
                {' '}
              </Typography>

            </CorporateGiftingContainer>
          )}
        </DropdownContainer>
      </MobileMegaMenuContainer>
      <CorporateGiftingContainer onClick={() => openExternalLink(externalLinks.corporateGiftingRegisterLink)} data-cy="automated-gifting-link">
        <Typography variant="body-compact-01">
          {headerCopy.automatedGifting}
          {' '}
        </Typography>
      </CorporateGiftingContainer>
    </>
  );
};

export default MobileMegaMenu;
