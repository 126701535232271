import {
  useState, useEffect, useMemo, useRef,
} from 'react';
import { useOnClickOutside } from 'common/hooks';
import { ModalProps } from './types';
import './styles.scss';
export const useModal = (dismissable = true) => {
  const modalRef = useRef(null);
  const [open, setModalOpen] = useState(false);
  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
      return;
    }
    document.body.style.overflowY = 'unset';
  }, [open]);

  const closeModal = () => {
    if (dismissable) {
      setModalOpen(false);
    }
  };

  useOnClickOutside(modalRef, closeModal);

  const Modal = useMemo(() => {
    const ModalComponent = (props: ModalProps) => {
      const { children } = props;
      return (
        <div className={`modal-overlay ${open ? 'show' : 'hide'}`}>
          <div className="children-container">
            <div className="children" ref={modalRef}>
              {children}
            </div>
          </div>
        </div>
      );
    };
    return ModalComponent;
  }, [open]);

  return { Modal, setModalOpen };
};

export default useModal;
