import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';
import { promptShadow } from 'common/constants';

export const SubscriptionAndOrdersContainer = styled.div`
    display: flex;
    gap: 32px;
    justify-content: center;
    padding-top: 8px;
    flex-direction: column;
    align-items: center;
    @media ${breakpoints.tablet} {
         flex-direction: row;
         align-items: unset;
         padding-top: 32px;
    }
    .subscription {
        max-width: 100%;
        width: 100%;
        @media ${breakpoints.tablet} {
            max-width: 630px;
            width: unset;
        }   
        .back-button {
            display: none;
            margin-bottom: 32px;
            @media ${breakpoints.mobileLarge} {
                display: flex;
            }  
        }
        .subscription-button-container{
            max-width: 100%;
            position: sticky;
            top: 32px;
            transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
            &.header-exists {
            top: 163px;
            }
            @media ${breakpoints.tablet} {
            max-width: 630px;
            }  
        }
    }
    .orders {
        max-width: 100%;
        width: 100%;
        @media ${breakpoints.tablet} {
            margin-top: 55px;
            max-width: 730px;
        }
    }
`;

export const Prompt = styled.div`
    display: flex;
    width: calc(100% - 48px);
    max-width: 464px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background-color: white;
    border-radius: 6px;
    box-shadow: ${promptShadow};
    .button-container {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        width: 100%;
        button {
            width: 50%;
        }
    }
`;

export const ResumeDatesContainer = styled.div`
    width: calc(100% - 32px);
    padding: 16px;
    display: flex;
    background-color: ${({ theme }) => theme.palette['ui-base'].grey20};
`;

export const BreadCrumpContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 16px 24px 16px;
    width: calc(100% - 48px);
    SVG{
       min-width: 18px;
       min-height: 18px;
       max-width: 18px;
       max-height: 18px;
    }
    .cancel-plan-button {
        display: none;
        width:calc(100% - 48px);
        @media ${breakpoints.mobileLarge} {
            display: flex;
        }
    }
    .my-plans-text {
        color: ${({ theme }) => theme.palette.link['link-01']};
        cursor: pointer;
        &:hover {
          color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
    }
    .plan-with-id {
        color: ${({ theme }) => theme.palette.link['text-01']};
        cursor: pointer;
        &:hover {
          color: ${({ theme }) => theme.palette.link['text-01-hover']};
        }
    }
`;

export const NoOrdersBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PromptForTypeform = styled.div`
    display: flex;
    width: 80vw;
    height: 80vh;
    max-width: 464px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background-color: white;
    border-radius: 6px;
    box-shadow: ${promptShadow};
    .cancel-plan-form{
        height: 75vh;
        width: 100%;
    }
`;
export const ConfirmInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

export const SingleInfoBlock = styled.div`
    display: flex;
    flex-direction: roe;
    gap: 8px;
    width: 100%;
`;
