import { ReactNode } from 'react';
import { UnauthHeader } from '../unauthHeader';
import Footer from '../footer';
import ContentLayout from '../contentLayout';

export const UnauthLayout = (props: { children?: ReactNode, backgroundColor?: string}) => {
  const { children, backgroundColor } = props;
  return (
    <>
      <UnauthHeader />
      <ContentLayout backgroundColor={backgroundColor}>
        <div style={{ marginTop: '54px' }}>
          {children}
        </div>
      </ContentLayout>
      <Footer />
    </>
  );
};

export default UnauthLayout;
