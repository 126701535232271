import theme from 'common/theme/theme';
import Typography from '../../typography';
import { BreadcrumbDisplayItemProps } from '../types';
export const BreadcrumbDisplayItem = (props: BreadcrumbDisplayItemProps) => {
  const {
    label, step, currentStep, setSelectedStep,
  } = props;
  let color = theme.palette.text['text-03'];
  if (step === currentStep) {
    color = theme.palette.text['text-01'];
  }
  if (step < currentStep) {
    color = theme.palette.link['link-01'];
  }

  const handleStepClick = () => {
    if (step < currentStep) {
      setSelectedStep(step);
    }
  };
  return (
    <Typography
      variant="heading-compact-01"
      color={color}
      style={{ transition: 'color 0.2s ease-in-out', cursor: step < currentStep ? 'pointer' : 'unset' }}
      onClick={handleStepClick}
      data-cy={`${label.replaceAll(' ', '').toLowerCase()}-breadcrumb-item`}
    >
      {label}
    </Typography>
  );
};

export default BreadcrumbDisplayItem;
