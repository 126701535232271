import {
  presignedUrl,
} from 'common/utils/network/endpoints';
import { baseApi } from 'common/store/baseApi';
import { GetPresignedUrlDTO, GetPresignedUrlResponseDTO } from './types';

export const uploadFileApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['User'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPresignedUrl: builder.mutation<GetPresignedUrlResponseDTO, GetPresignedUrlDTO>({
        query: (formData) => ({
          url: presignedUrl,
          method: 'POST',
          body: formData,
        }),
      }),
    }),
  });

export const {
  useGetPresignedUrlMutation,
} = uploadFileApi;
