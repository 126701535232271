/* eslint-disable no-shadow */
export type GiftingFooterProps = {
    lastStep: number;
};

export enum STEPS {
    treat = 1,
    plan = 2,
    hrIntegration = 3,
    recipients = 4,
    payment = 5,
}
