/* eslint-disable jsx-a11y/no-static-element-interactions */
import './option.scss';
import { SelectOptionProps } from './types';
import Typography from '../typography';

export const SelectOption = (props: SelectOptionProps) => {
  const {
    labelText, onSelect = () => null, ...rest
  } = props;

  const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>) => {
    onSelect(labelText);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className="select-option"
      onClick={(e) => handleSelect(e)}
      onKeyDown={(e) => handleSelect(e)}
      {...rest}
    >
      <Typography variant="body-compact-01">
        {labelText}
      </Typography>
    </div>
  );
};

export default SelectOption;
