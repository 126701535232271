import * as React from 'react';
import { SVGProps } from 'react';
const SvgAcgCalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="79" height="65" viewBox="0 0 79 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M53.5238 59.2553C67.3788 59.2553 78.6105 48.0615 78.6105 34.2532C78.6105 20.4448 67.3788 9.25098 53.5238 9.25098C39.6687 9.25098 28.437 20.4448 28.437 34.2532C28.437 48.0615 39.6687 59.2553 53.5238 59.2553Z" fill="#DDBDE8" />
    <path d="M65.4978 46.6092V62.2353C65.4978 63.209 64.7061 63.9981 63.729 63.9981H21.4246C20.4475 63.9981 19.6558 63.209 19.6558 62.2353L23.4193 20.3878C23.4193 19.4141 24.2111 18.625 25.1881 18.625H63.729C64.7061 18.625 65.4978 19.4141 65.4978 20.3878V46.6092Z" fill="#B8C3F2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.1881 19.625C24.7602 19.625 24.4193 19.9696 24.4193 20.3878V20.4327L20.6566 62.271C20.6753 62.673 21.0089 62.9981 21.4246 62.9981H63.729C64.157 62.9981 64.4978 62.6535 64.4978 62.2353V20.3878C64.4978 19.9696 64.157 19.625 63.729 19.625H25.1881ZM22.4197 20.3381C22.4463 18.832 23.6786 17.625 25.1881 17.625H63.729C65.2552 17.625 66.4978 18.8587 66.4978 20.3878V62.2353C66.4978 63.7645 65.2552 64.9981 63.729 64.9981H21.4246C19.8984 64.9981 18.6558 63.7645 18.6558 62.2353V62.1904L22.4197 20.3381Z" fill="#262827" />
    <path d="M56.4039 54.7356C56.4039 55.7093 55.6121 56.4984 54.6351 56.4984H9.19604C8.21903 56.4984 7.42725 55.7093 7.42725 54.7356L22.7927 20.0753C22.7927 19.1016 23.5845 18.6249 24.5615 18.6249L63.7294 18.3125C64.7064 18.3125 65.4982 19.1016 65.4982 20.0753V21.8402L56.4039 54.7356Z" fill="#DDBDE8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M63.7294 17.3125C65.2555 17.3125 66.4982 18.5462 66.4982 20.0754V21.9759L57.3999 54.8858C57.3217 56.3447 56.1109 57.4984 54.6351 57.4984H9.19604C7.66993 57.4984 6.42725 56.2648 6.42725 54.7356V54.5239L21.8029 19.8408C21.8632 19.1514 22.1903 18.5767 22.7094 18.186C23.2504 17.7789 23.9233 17.6256 24.5573 17.6249C24.5587 17.6249 24.5601 17.6249 24.5615 17.6249L63.7294 17.3125ZM63.7326 19.3125C63.7315 19.3125 63.7305 19.3125 63.7294 19.3125L24.5615 19.625C24.2197 19.625 24.01 19.7103 23.9121 19.784C23.8681 19.8171 23.8441 19.8488 23.8287 19.88C23.8133 19.9113 23.7927 19.9703 23.7927 20.0754V20.2871L8.44621 54.9045C8.52341 55.2427 8.82744 55.4984 9.19604 55.4984H54.6351C55.063 55.4984 55.4039 55.1538 55.4039 54.7356V54.5999L64.4982 21.7045V20.0754C64.4982 19.6582 64.159 19.3142 63.7326 19.3125Z" fill="#262827" />
    <path d="M49.2289 49.0839C48.4249 49.9359 47.2952 50.4045 46.1208 50.4045H2.45457C2.45457 50.4045 2.43422 50.4045 2.42608 50.4045C1.14782 50.3842 0.533121 48.8162 1.41243 47.8911C4.52665 44.6069 19.0312 33.3158 20.6432 18.4707H62.9457C63.9227 18.4707 64.7145 19.2598 64.7145 20.2335C64.7145 21.3898 64.4153 22.5279 63.8453 23.5361C63.8453 23.5361 57.1203 40.714 49.2309 49.0859L49.2289 49.0839Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.746 17.4707H62.9458C64.4719 17.4707 65.7146 18.7044 65.7146 20.2335C65.7146 21.5384 65.383 22.8228 64.751 23.9655C64.7378 23.9988 64.7196 24.0447 64.6964 24.1026C64.6442 24.233 64.5671 24.4242 64.4663 24.6699C64.2648 25.1613 63.9688 25.871 63.5891 26.7482C62.8299 28.5019 61.7351 30.9279 60.3914 33.6183C57.7167 38.9738 54.0097 45.4731 49.9588 49.7718L49.2546 50.519L49.1789 50.4438C48.2883 51.0713 47.2168 51.4045 46.1209 51.4045H2.41824L2.41031 51.4044C0.202281 51.3694 -0.750674 48.7161 0.687309 47.2026C1.15228 46.7123 1.87768 46.0349 2.75321 45.2174C3.37647 44.6355 4.07581 43.9824 4.81144 43.2753C6.61988 41.5369 8.74596 39.3826 10.8212 36.8782C14.9854 31.8529 18.8739 25.5021 19.6492 18.3627L19.746 17.4707ZM48.7654 48.1182C52.5053 44.0413 56.0026 37.9298 58.6021 32.7247C59.9259 30.0742 61.0053 27.6824 61.7537 25.9537C62.1277 25.0896 62.4187 24.3918 62.6158 23.9111C62.7144 23.6708 62.7894 23.4848 62.8396 23.3594C62.8647 23.2967 62.8836 23.2491 62.8961 23.2176L62.91 23.1823L62.9401 23.1056L62.9749 23.0439C63.46 22.1858 63.7146 21.2173 63.7146 20.2335C63.7146 19.8153 63.3737 19.4707 62.9458 19.4707H21.5253C20.4771 26.8031 16.4653 33.2015 12.3612 38.1543C10.2196 40.7388 8.03487 42.9509 6.19746 44.7171C5.38714 45.4961 4.66166 46.1728 4.03755 46.7549C3.21638 47.5208 2.5707 48.123 2.13817 48.5792L2.13732 48.5801C1.99421 48.7306 1.96485 48.9305 2.03907 49.1165C2.11065 49.2959 2.25051 49.3995 2.43617 49.4045H46.1209C47.0313 49.4045 47.8942 49.0413 48.5017 48.3976L48.7654 48.1182Z" fill="#262827" />
    <path d="M28.6201 32.224L24.4943 32.2342C23.9916 32.2342 23.6578 31.7189 23.8654 31.2625L24.8078 29.1873C24.9197 28.9418 25.164 28.7836 25.4347 28.7836L29.5605 28.7734C30.0633 28.7734 30.3971 29.2887 30.1895 29.7451L29.2471 31.8204C29.1351 32.0658 28.8909 32.224 28.6201 32.224Z" fill="#262827" />
    <path d="M38.3413 32.3803L34.2155 32.3904C33.7128 32.3904 33.3789 31.8752 33.5866 31.4187L34.529 29.3435C34.6409 29.0981 34.8852 28.9398 35.1559 28.9398L39.2817 28.9297C39.7845 28.9297 40.1183 29.4449 39.9107 29.9014L38.9682 31.9766C38.8563 32.2221 38.6121 32.3803 38.3413 32.3803Z" fill="#262827" />
    <path d="M48.063 32.5365L43.9372 32.5467C43.4344 32.5467 43.1006 32.0314 43.3082 31.575L44.2506 29.4998C44.3626 29.2543 44.6068 29.0961 44.8776 29.0961L49.0034 29.0859C49.5061 29.0859 49.84 29.6012 49.6323 30.0576L48.6899 32.1329C48.578 32.3783 48.3337 32.5365 48.063 32.5365Z" fill="#262827" />
    <path d="M25.7993 37.5365L21.6735 37.5467C21.1708 37.5467 20.837 37.0314 21.0446 36.575L21.987 34.4998C22.0989 34.2543 22.3432 34.0961 22.6139 34.0961L26.7397 34.0859C27.2425 34.0859 27.5763 34.6012 27.3687 35.0576L26.4263 37.1329C26.3143 37.3783 26.0701 37.5365 25.7993 37.5365Z" fill="#262827" />
    <path d="M35.52 37.6928L31.3942 37.7029C30.8915 37.7029 30.5577 37.1877 30.7653 36.7312L31.7077 34.656C31.8196 34.4106 32.0639 34.2523 32.3346 34.2523L36.4604 34.2422C36.9632 34.2422 37.297 34.7574 37.0894 35.2139L36.147 37.2891C36.035 37.5346 35.7908 37.6928 35.52 37.6928Z" fill="#262827" />
    <path d="M45.2398 37.849L41.1139 37.8592C40.6112 37.8592 40.2774 37.3439 40.485 36.8875L41.4274 34.8123C41.5394 34.5668 41.7836 34.4086 42.0543 34.4086L46.1801 34.3984C46.6829 34.3984 47.0167 34.9137 46.8091 35.3701L45.8667 37.4454C45.7547 37.6908 45.5105 37.849 45.2398 37.849Z" fill="#262827" />
    <path d="M22.1008 43.1614L18.093 43.1715C17.5496 43.1715 17.2178 42.5751 17.5089 42.1167L18.8278 40.0435C18.954 39.8447 19.1738 39.725 19.4079 39.725L23.4157 39.7148C23.9592 39.7148 24.2909 40.3112 23.9999 40.7697L22.6809 42.8429C22.5547 43.0417 22.3349 43.1614 22.1008 43.1614Z" fill="#262827" />
    <path d="M31.8215 43.3176L27.8137 43.3278C27.2703 43.3278 26.9385 42.7314 27.2296 42.2729L28.5485 40.1997C28.6747 40.0009 28.8946 39.8812 29.1286 39.8812L33.1364 39.8711C33.6799 39.8711 34.0116 40.4675 33.7206 40.926L32.4016 42.9992C32.2754 43.198 32.0556 43.3176 31.8215 43.3176Z" fill="#262827" />
    <path d="M41.5432 43.4739L37.5354 43.484C36.992 43.484 36.6602 42.8876 36.9512 42.4292L38.2702 40.356C38.3964 40.1572 38.6162 40.0375 38.8503 40.0375L42.8581 40.0273C43.4015 40.0273 43.7333 40.6237 43.4422 41.0822L42.1233 43.1554C41.9971 43.3542 41.7773 43.4739 41.5432 43.4739Z" fill="#262827" />
    <path d="M28.7508 27.064C29.9627 27.064 30.945 26.085 30.945 24.8772C30.945 23.6695 29.9627 22.6904 28.7508 22.6904C27.539 22.6904 26.5566 23.6695 26.5566 24.8772C26.5566 26.085 27.539 27.064 28.7508 27.064Z" fill="#262827" />
    <path d="M39.4125 27.064C40.6243 27.064 41.6067 26.085 41.6067 24.8772C41.6067 23.6695 40.6243 22.6904 39.4125 22.6904C38.2006 22.6904 37.2183 23.6695 37.2183 24.8772C37.2183 26.085 38.2006 27.064 39.4125 27.064Z" fill="#262827" />
    <path d="M50.0741 27.064C51.2859 27.064 52.2683 26.085 52.2683 24.8772C52.2683 23.6695 51.2859 22.6904 50.0741 22.6904C48.8623 22.6904 47.8799 23.6695 47.8799 24.8772C47.8799 26.085 48.8623 27.064 50.0741 27.064Z" fill="#262827" />
    <path d="M29.3452 24.8245C29.3635 25.5345 28.3723 25.8996 27.8125 25.3844C26.516 24.1936 25.6143 21.5707 25.6143 18.5258C25.6143 14.3631 27.2996 12.083 29.3778 12.083C31.0326 12.083 32.437 13.5274 32.9418 16.2152H29.6912L29.278 15.1522C29.278 15.1522 27.2976 17.8381 29.278 22.2239L29.3452 24.8204V24.8245Z" fill="#262827" />
    <path d="M39.4124 12.0627C41.0673 12.0627 42.4717 13.507 42.9765 16.1949H39.7259L39.3127 15.1319C39.3127 15.1319 37.3322 17.8177 39.3127 22.2035L39.3799 24.8001C39.3982 25.5101 38.4069 25.8752 37.8472 25.36C36.5506 24.1692 35.6489 21.5462 35.6489 18.5013C35.6489 14.3387 37.3343 12.0586 39.4124 12.0586" fill="#262827" />
    <path d="M50.0415 24.802C50.0598 25.512 49.0686 25.8772 48.5088 25.3619C47.2122 24.1711 46.3105 21.5482 46.3105 18.5033C46.3105 14.3407 47.9959 12.0605 50.0741 12.0605C51.7289 12.0605 53.1333 13.5049 53.6381 16.1928H50.3875L49.9743 15.1298C49.9743 15.1298 47.9939 17.8156 49.9743 22.2014L50.0415 24.798V24.802Z" fill="#262827" />
    <path d="M3.6803 10.5654L5.44502 11.1273C5.44502 11.1273 4.51686 13.2715 4.3927 14.4502C7.58019 13.3141 9.18411 12.0909 9.27978 16.5619C13.0107 11.6811 13.0677 15.8661 13.5399 19.5601C14.6757 18.6229 14.6655 18.8968 15.7932 19.9212C12.3309 23.6071 11.6775 19.8948 11.5269 16.6288C8.64676 20.3432 7.91197 18.7872 7.2993 14.9654C5.67909 15.9006 3.5541 16.5031 2.80506 15.649C1.95221 14.6773 3.67826 10.5654 3.67826 10.5654H3.6803Z" fill="#B8C3F2" />
    <path d="M11.4899 23.765L12.4689 25.5481C12.4933 25.5927 12.532 25.6313 12.5768 25.6556L14.3659 26.6313C14.5532 26.7348 14.5532 27.0026 14.3659 27.106L12.5768 28.0818C12.532 28.1061 12.4933 28.1447 12.4689 28.1893L11.4899 29.9724C11.3861 30.159 11.1174 30.159 11.0136 29.9724L10.0345 28.1893C10.0101 28.1447 9.97144 28.1061 9.92666 28.0818L8.13752 27.106C7.95026 27.0026 7.95026 26.7348 8.13752 26.6313L9.92666 25.6556C9.97144 25.6313 10.0101 25.5927 10.0345 25.5481L11.0136 23.765C11.1174 23.5783 11.3861 23.5783 11.4899 23.765Z" fill="#DDBDE8" />
    <path d="M17.6977 0.235822L19.0411 2.94803C19.1022 3.0738 19.2223 3.159 19.3607 3.17928L22.363 3.6134C22.711 3.66411 22.8515 4.09011 22.5991 4.33557L20.4273 6.44529C20.3275 6.54266 20.2807 6.68263 20.3051 6.82057L20.8181 9.80055C20.8771 10.1454 20.5127 10.4091 20.2013 10.2468L17.5166 8.83901C17.3924 8.77409 17.2459 8.77409 17.1217 8.83901L14.437 10.2468C14.1256 10.4091 13.7612 10.1454 13.8202 9.80055L14.3332 6.82057C14.3576 6.68263 14.3108 6.54266 14.211 6.44529L12.0392 4.33557C11.7868 4.09011 11.9252 3.66411 12.2753 3.6134L15.2776 3.17928C15.416 3.159 15.5361 3.07177 15.5972 2.94803L16.9406 0.235822C17.0973 -0.0786073 17.5471 -0.0786073 17.7018 0.235822H17.6977Z" fill="#DDBDE8" />
  </svg>
);
export default SvgAcgCalendarIcon;
