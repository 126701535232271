import * as React from 'react';
import { SVGProps } from 'react';
const SvgShowPasswordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.696 10.269a.843.843 0 0 1 0-.533 8.754 8.754 0 0 1 16.608-.005.843.843 0 0 1 0 .533 8.754 8.754 0 0 1-16.608.005Z"
    />
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12.5 10a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z" />
  </svg>
);
export default SvgShowPasswordIcon;
