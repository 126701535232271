import * as React from 'react';
import { SVGProps } from 'react';
const SvgUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.125 5a3.125 3.125 0 1 1-6.25 0 3.125 3.125 0 0 1 6.25 0ZM3.751 16.765a6.25 6.25 0 0 1 12.498 0A14.944 14.944 0 0 1 10 18.125c-2.23 0-4.347-.487-6.249-1.36Z"
    />
  </svg>
);
export default SvgUserIcon;
