import lang from 'common/lang';
import { CgPlanType } from 'features/plans/types';
import Badge from '../../badge';
import Typography from '../../typography';
import {
  ProductDetailsContainer, BadgeAndCoroprateGiftContainer, BadgeAndPlanTypeContainer, PricePerBoxContainer,
} from './styles';
import ProductSpecifics from './cgProductSpecifics';
import { Variant } from '../../badge/types';

export const CgProductDetails = (props: CgPlanType & { status: string }) => {
  const { order: orderCopy } = lang;
  const {
    planType, status, perBoxPrice,
  } = props;
  const badgeStatusMapping: { [key: string] : Variant} = {
    active: 'success',
    cancelled: 'error',
    paused: 'warning',
    expired: 'info',
  };
  return (
    <ProductDetailsContainer>
      <BadgeAndCoroprateGiftContainer>
        <BadgeAndPlanTypeContainer>
          <Badge
            label={!status ? orderCopy.placed : status}
            variant={badgeStatusMapping[status.toLowerCase()] || 'default'}
          />
          <Typography variant="heading-compact-01">
            {planType}
          </Typography>
        </BadgeAndPlanTypeContainer>
        <PricePerBoxContainer>
          <Typography variant="body-03" className="unit-price" noMargin>
            {`£${(perBoxPrice / 100).toFixed(2)}`}
          </Typography>
          <Typography variant="body-01" className="per-box" noMargin>
            {orderCopy.perBox}
          </Typography>
        </PricePerBoxContainer>
      </BadgeAndCoroprateGiftContainer>
      <ProductSpecifics {...props} />
    </ProductDetailsContainer>
  );
};

export default CgProductDetails;
