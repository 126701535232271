import { useEffect, useRef } from 'react';
import lang from 'common/lang';
import Typography from 'common/designSystem/typography';
import { CgSubscriptionHeaderContainer, CgSubscriptionNumberUrl } from './styles';
import { PlansHeaderProps } from './types';

export const CgSubscriptionHeader = (props: PlansHeaderProps) => {
  const { name, createdAt } = props;
  const { order: orderCopy } = lang;
  const authHeaderRef = useRef<HTMLHeadElement | null>(null);
  const orderHeaderRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    authHeaderRef.current = document.querySelector('.auth-header');
    window.addEventListener('scroll', handlePlansHeaderPosition);

    return () => window.removeEventListener('scroll', handlePlansHeaderPosition);
  }, []);

  const handlePlansHeaderPosition = () => {
    if (authHeaderRef?.current && orderHeaderRef?.current) {
      if (authHeaderRef.current.classList.contains('header-visible')) {
        orderHeaderRef.current.classList.add('header-exists');
        return;
      }
      orderHeaderRef.current.classList.remove('header-exists');
    }
  };

  return (
    <CgSubscriptionHeaderContainer className="order-header" ref={orderHeaderRef}>
      <CgSubscriptionNumberUrl>
        <Typography variant="heading-compact-02">{`${orderCopy.subscriptionNumber} #${name}`}</Typography>
      </CgSubscriptionNumberUrl>
      <Typography variant="heading-compact-02">{createdAt}</Typography>
    </CgSubscriptionHeaderContainer>
  );
};

export default CgSubscriptionHeader;
