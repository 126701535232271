/* eslint-disable react/button-has-type */
import './button.scss';
import { ButtonProps } from 'common/designSystem/button/types';

export const Button = ({
  variant = 'primary',
  size = 'medium',
  label,
  type = 'button',
  icon: Icon,
  className = '',
  iconRight: IconRight,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={['custom-button', size, variant, className].join(' ')}
      {...props}
      type={type}
    >
      {Icon !== undefined && <Icon />}
      {label}
      {IconRight !== undefined && <IconRight className="right-icon" />}
    </button>
  );
};

export default Button;
