/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import lang from 'common/lang';
import { PlanType } from 'features/plans/types';
import Typography from 'common/designSystem/typography';
import {
  ShippingAddressContainer,
} from './styles';

export const Shipping = (props: PlanType & { isMultipleRecipients?: boolean}) => {
  const {
    shippingCompany, shippingFirstName, shippingLastName = '',
    shippingAddress1, shippingAddress2, shippingCity,
    shippingPostcode, shippingCountry, shippingPhone,
    isMultipleRecipients = false,
  } = props;
  const { order: orderCopy } = lang;
  return (
    <ShippingAddressContainer>
      {!isMultipleRecipients && (
        <>
          <Typography variant="body-compact-01">
            {`${shippingFirstName}${shippingLastName ? ` ${shippingLastName}` : ''}`}
          </Typography>
          <Typography variant="body-compact-01">
            {`${shippingCompany ? `${shippingCompany},` : ''}
                ${shippingAddress1 ? `${shippingAddress1},` : ''}
                ${shippingAddress2 ? `${shippingAddress2},` : ''}
                ${shippingCity ? `${shippingCity},` : ''}
                ${shippingPostcode ? `${shippingPostcode},` : ''}
                ${shippingCountry ? `${shippingCountry}` : ''}
              `}
          </Typography>
          <Typography variant="body-compact-01">
            {shippingPhone || ''}
          </Typography>
        </>
      )}
      {isMultipleRecipients && (
        <Typography variant="body-compact-01">
          {orderCopy.multipleRecipientsShipping}
        </Typography>
      )}
    </ShippingAddressContainer>
  );
};

export default Shipping;
