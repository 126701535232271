import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';
export const BreadcrumbList = styled.div`
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    svg {
        min-width: 20px;
        height: 20px;
    }
    margin-bottom: 24px;
    display: none;
    @media ${breakpoints.mobileLarge} {
        display: flex;
    }
`;

export const StepContent = styled.div`
    position: relative;
    margin-bottom: 64px;
`;

export const StepContentItem = styled.div`
    width: 100%;
`;
