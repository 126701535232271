import styled from 'styled-components';

export const ProductSpecificsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 64px;
    row-gap: 16px;
    width: 100%;
    align-items: flex-start;
    .product-title{
        color: ${({ theme }) => theme.palette.link['link-01']};
        cursor: pointer;
        margin-bottom: 8px;
        &:hover{
            color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
    }
    .body-01{
        color: ${({ theme }) => theme.palette['ui-base'].grey70};
    }
`;

export const ProductDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
`;

export const LineItemContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    max-width: 100%;
    .body-01{
        margin-right: 3px;
        margin-bottom: unset;
        margin-top: unset;
    }
    .body-compact-01-underline{
        color: ${({ theme }) => theme.palette.link['link-01']};
        cursor: pointer;
        &:hover{
            color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
    }
    p{
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const PriceContainer = styled.div`
    display: flex;
    align-items: center;
    .unit-price{
        margin-right: 4px;
    }
    .quantity{
        margin-right: 24px;
    }
`;
