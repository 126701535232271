/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import lang from 'common/lang';
import { OrderType } from 'features/home/types';
import Typography from 'common/designSystem/typography';
import { ShippingAddressContainer } from './styles';

export const PricingAndShipping = (props: OrderType) => {
  const {
    shippingCompany, shippingFirstName, shippingLastName = '',
    shippingAddress1, shippingAddress2, shippingCity,
    shippingPostcode, shippingCountry,
  } = props;
  const { order: orderCopy } = lang;
  return (
    <ShippingAddressContainer>
      <Typography variant="heading-compact-02">
        {orderCopy.shippingAddress}
      </Typography>
      <>
        <Typography variant="body-compact-01">
          {`${shippingFirstName}${shippingLastName ? ` ${shippingLastName}` : ''}`}
        </Typography>
        <Typography variant="body-compact-01">
          {`${shippingCompany ? `${shippingCompany},` : ''}
            ${shippingAddress1 ? `${shippingAddress1},` : ''}
            ${shippingAddress2 ? `${shippingAddress2},` : ''}
            ${shippingCity ? `${shippingCity},` : ''}
            ${shippingPostcode ? `${shippingPostcode},` : ''}
            ${shippingCountry ? `${shippingCountry}` : ''}
          `}
        </Typography>
      </>
    </ShippingAddressContainer>
  );
};

export default PricingAndShipping;
