/* eslint-disable jsx-a11y/no-static-element-interactions */
import './option.scss';
import { MultiSelectOptionProps, OptionOperation } from './types';
import useUnlabelledCheckbox from '../unlabelledCheckbox/useUnlabelledCheckbox';
import Typography from '../typography';

export const MultiSelectOption = (props: MultiSelectOptionProps) => {
  const {
    labelText, value, onSelect = () => {}, ...rest
  } = props;
  const { setIsChecked, isChecked, UnlabelledCheckbox } = useUnlabelledCheckbox();

  const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>) => {
    const operation: OptionOperation = isChecked ? 'remove' : 'add';
    onSelect(value, labelText, operation);
    setIsChecked(!isChecked);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      className="multi-select-option"
      onClick={(e) => handleSelect(e)}
      onKeyDown={(e) => handleSelect(e)}
      {...rest}
    >
      <UnlabelledCheckbox
        id={value.toString()}
      />
      <Typography variant="body-compact-01">
        {labelText}
      </Typography>
    </div>
  );
};

export default MultiSelectOption;
