/* eslint-disable jsx-a11y/no-static-element-interactions */
import './styles.scss';
import {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { theme } from 'common/theme/theme';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import { MultiSelectOption } from './option';
import { MultiSelectProps, LabelValuePair, OptionOperation } from './types';
import Typography from '../typography';
import { ChevronDown } from '../iconComponents';

export const MultiSelect = ({
  labelText,
  id,
  name,
  error = false,
  errorMessage = '',
  helperText = '',
  options = [],
  onUpdate = () => {},
  ...rest
}: MultiSelectProps) => {
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const [values, setValues] = useState<Array<string | number>>([]);
  const [labels, setLabels] = useState<Array<string>>([]);
  const [showOptions, toggleShowOptions] = useState(false);
  const [renderedValue, setRenderedValue] = useState('Select');
  const handleToggle = (e?:
    React.MouseEvent<HTMLDivElement, MouseEvent>
    | React.KeyboardEvent<HTMLDivElement>
    | React.FocusEvent<HTMLDivElement, Element>
    | React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    toggleShowOptions(!showOptions);
    e?.stopPropagation();
    e?.preventDefault();
  };
  useEffect(() => {
    onUpdate(values);
  }, [values]);
  useEffect(() => {
    if (labels.length > 0) {
      setRenderedValue(labels.join(', '));
      return;
    }
    setRenderedValue('Select');
  }, [labels]);
  const updateValues = useCallback((value: string | number, label: string, operation: OptionOperation) => {
    if (operation === 'add') {
      setValues([...values, value]);
      setLabels([...labels, label]);
      return;
    }
    setValues([...values.filter((entry) => entry !== value)]);
    setLabels([...labels.filter((entry) => entry !== label)]);
  }, [values, labels]);
  const handleClickOutside = () => {
    toggleShowOptions(false);
  };

  useOnClickOutside(optionsRef, handleClickOutside);
  return (
    <div
      className="custom-label"
      onClick={(e) => handleToggle(e)}
      onKeyDown={(e) => handleToggle(e)}
      data-cy={`multi-select-${name}`}
      onBlur={(e) => handleToggle(e)}
    >
      <Typography variant="heading-compact-01">
        {labelText}
      </Typography>
      <div className="custom-multi-select-container">
        <div
          id={id}
          ref={optionsRef}
          className="custom-multi-select select-type"
          {...rest}
        >
          <Typography variant="body-01" className="rendered-value" noMargin>
            {renderedValue}
          </Typography>
          <div className={`multi-select-options ${showOptions ? 'show' : 'hide'}`}>
            {options.map((entry: LabelValuePair) => (
              <MultiSelectOption
                key={entry.value}
                labelText={entry.label}
                value={entry.value}
                onSelect={updateValues}
                data-cy={`multi-select-${entry.value}`}
              />
            ))}
          </div>
        </div>
        <ChevronDown
          className={`chevron-icon ${showOptions ? 'show-options' : ''}`}
        />
        {!error && helperText && (
          <Typography className="helper-text" variant="helper-text-01" color={theme.palette.text['text-02']} data-cy={`${name}-error`}>
            {helperText}
          </Typography>
        )}
        {error && errorMessage && (
          <Typography className="helper-text" variant="helper-text-01" color={theme.palette.support['support-01']}>
            {errorMessage}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default MultiSelect;
