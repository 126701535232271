import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';

export const CgPlansContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}`};
    align-items: flex-start;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    @media ${breakpoints.desktopSmall} {
        width: 100%;
    }
`;

export const Summary = styled.div`
    padding: 24px 24px 16px 24px;
    width: calc(100% - 48px);
`;

export const CgViewPlanDetailsContainer = styled.div`
    padding: 24px;
    width: calc(100% - 48px);
    text-align: left;
    border-top: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
`;

export const IntervalContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    padding-left: 24px;
    padding-bottom: 24px;
    svg {
        min-height: 18px;
        min-width: 18px;
        max-width: 18px;
        max-height: 18px;
    }
    .recipient-count {
        text-decoration: underline;
        color: ${({ theme }) => theme.palette.link['link-01']};
        cursor: pointer;
        &:hover {
          color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
    }
`;

export const NewRecipentsNotification = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    margin-left: 24px;
    width: calc(100% - 42px);
    border-radius: 6px;
    padding-bottom: 10px;
    background-color: ${({ theme }) => theme.palette.support['support-background-03']};
    @media ${breakpoints.desktopSmall} {
      flex-direction: row;
      padding-bottom: 0px;
    }
`;

export const NotificationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    max-width: 425px;
    svg {
        min-height: 18px;
        min-width: 18px;
        max-width: 18px;
        max-height: 18px;
        margin-right: 12px;
    }
    .custom-button.base.new-joiner-button {
        padding: 7px 12px;
    }
`;

export const NewJoinerButtons = styled.div`
   display: flex;
   gap:12px;
   align-items: center;
   .custom-button.base.new-joiner-button {
    padding: 7px 12px !important;
    height: 18px;
    }
    @media ${breakpoints.desktopSmall} {
        padding: 12px 0px;
    }
    .custom-button.base.review-button {
        padding: 7px 12px !important;
        height: 16px;
    }
`;
