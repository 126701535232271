import { useEffect, useMemo, useState } from 'react';
import { useDebounce, useUserDetails } from 'common/hooks';
import { RecipientRowProps } from 'common/designSystem/recipientsTable/types';
import {
  convertObjectFieldNamesToCamelCase, formatMergeDateToCDDate, sortEmployeesAscending,
  getFormattedAddress, filterEmployeesByKeyword, sortEmployeesInAscending, sortEmployeesInDescending,
  getBirthdayMonth, selectedEmployeesDifference, selectedEmployeesUnion, getPlanStartMonth, getFirstActiveConnection, getAnniversaryMonth, getPlanStartMonthForGifting,
} from 'common/utils/helpers';
import { useLocation, useParams } from 'react-router-dom';
import {
  ActiveConnectionDetailsResponse, ConnectionDetails, EmployeeDetails, EmployeesListingResponse,
} from 'features/gifting/types';
import { mixpanelCustomEvent, Dict, MixpanelEventName } from 'common/utils/mixpanel/eventTriggers';
import { numberOfMonths, platformFeeAmount, vatMultiplier } from 'common/constants';
import { useLazyCheckForActiveConnectionQuery, useLazyGetEmployeesQuery } from 'features/gifting/giftingSevice';
import Bugsnag from '@bugsnag/js';
import useCgPlanDetails from '../cgPlanDetails/useCgPlanDetails';
import { useGetCgPlanDetailsQuery, useUpdateCgPlanMutation } from '../plansService';

export const useRecipientDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromReview = queryParams.get('add_new_employee');
  const {
    employees: cgEmployeesList, cgPlanDetails,
  } = useCgPlanDetails({ id: id || 0 });
  const { refetch: refetchCgPlanDetails } = useGetCgPlanDetailsQuery({ id: id || 0 });
  const { user } = useUserDetails();
  const planStartMonthNumber = getPlanStartMonth();
  const applyPlatformFee = !!user?.platformFee;
  const [updateCgPlan] = useUpdateCgPlanMutation();
  const [checkForActiveConnections] = useLazyCheckForActiveConnectionQuery();
  const [getRemainingEmployees] = useLazyGetEmployeesQuery();
  const [employees, setEmployees] = useState<Array<RecipientRowProps>>([]);
  const [renderedEmployees, setRenderedEmployees] = useState<Array<RecipientRowProps>>([]);
  const [keyword, setKeyword] = useState('');
  const [filtering, setFiltering] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const debouncedKeyword = useDebounce<string>(keyword, 500);
  const [selectedEmployees, setSelectedEmployees] = useState<Set<number>>(new Set<number>());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [employeeMap, setEmployeeMap] = useState<{ [key: number]: RecipientRowProps }>({});
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [hrConnection, setHrConnection] = useState<ConnectionDetails | null>(null);
  const [newEmployees, setNewEmployees] = useState<Array<RecipientRowProps>>([]);
  const [mergedEmployees, setMergedEmployees] = useState<Array<RecipientRowProps>>([]);
  const [newlyJoinedEmployees, setNewlyJoinedEmployees] = useState<any>([]);
  const [manualSort, setManualSort] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    checkForActiveConnections()
      .unwrap()
      .then((res: ActiveConnectionDetailsResponse) => {
        if (res.length !== 0) {
          const activeConnection = getFirstActiveConnection(res);
          if (activeConnection) {
            setHrConnection({ ...activeConnection });
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err?.message || err);
      });
  }, []);

  useEffect(() => {
    if (cgEmployeesList.length > 0 && hrConnection && hrConnection.id) {
      setIsLoading(true);
      getRemainingEmployees({ connectionId: hrConnection.id })
        .unwrap()
        .then((res: EmployeesListingResponse) => {
          if (res.length > 0) {
            const filteredRes = res.filter((employee: EmployeeDetails) => !cgEmployeesList.some((cgEmployee: EmployeeDetails) => cgEmployee.id === employee.id));
            setNewlyJoinedEmployees(filteredRes);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.error(err?.message || err);
        });
    }
  }, [hrConnection, cgEmployeesList]);

  useEffect(() => {
    if (newlyJoinedEmployees.length > 0) {
      getEmployeesList();
      setManualSort(false);
      setIntiallySelectedEmployees();
    } else {
      setNewEmployees([]);
    }
  }, [newlyJoinedEmployees]);

  useEffect(() => {
    if (cgEmployeesList.length > 0 && newlyJoinedEmployees.length === 0) {
      getEmployeesList();
    }
  }, [cgEmployeesList, newlyJoinedEmployees]);

  useEffect(() => {
    setSelectedEmployees(selectedEmployees);
  }, [selectedEmployees]);

  useEffect(() => {
    if (newlyJoinedEmployees.length > 0) {
      const allSelected = mergedEmployees.length > 0 && mergedEmployees.every((recipient: RecipientRowProps) => (selectedEmployees.has(recipient.id)));
      setIsAllSelected(allSelected);
    } else {
      const allSelected = renderedEmployees.length > 0 && renderedEmployees.every((recipient: RecipientRowProps) => (selectedEmployees.has(recipient.id)));
      setIsAllSelected(allSelected);
    }
  }, [renderedEmployees, selectedEmployees, mergedEmployees]);

  useEffect(() => {
    if (debouncedKeyword) {
      setFiltering(true);
      filterEmployeesByKeyword(employees, debouncedKeyword)
        .then((filteredEmployees: Array<RecipientRowProps>) => {
          setRenderedEmployees([...filteredEmployees]);
        })
        .catch(() => {
          console.error('Failed to filter employees');
        })
        .finally(() => {
          setFiltering(false);
        });
      return;
    }
    if (isAscending && !manualSort) {
      setRenderedEmployees([...employees]);
      return;
    }
    if (!manualSort) {
      const employeesReversed = [...employees].reverse();
      setRenderedEmployees([...employeesReversed]);
    }
  }, [debouncedKeyword, employees, isAscending, manualSort]);

  const setIntiallySelectedEmployees = () => {
    const renderedEmployeesSet = new Set<number>();
    const newEmployeeDetailsMap: { [key: number]: RecipientRowProps } = { ...employeeMap };
    renderedEmployees.forEach((employee: RecipientRowProps) => {
      renderedEmployeesSet.add(employee.id);
    });
    const newSelectedEmployees = selectedEmployeesUnion(selectedEmployees, renderedEmployeesSet);
    setSelectedEmployees(newSelectedEmployees);
    setEmployeeMap(newEmployeeDetailsMap);
  };

  const formatEmployeeDetails = (employee : EmployeeDetails) => {
    return {
      id: employee.id,
      name: `${employee.firstName.charAt(0).toUpperCase() + employee.firstName.slice(1)} ${employee.lastName.charAt(0).toUpperCase() + employee.lastName.slice(1)}`,
      designation: employee.jobTitle,
      department: employee.department ? employee.department : '-',
      birthday: employee.dateOfBirth && formatMergeDateToCDDate(employee.dateOfBirth),
      startDate: employee.startDate ? formatMergeDateToCDDate(employee.startDate) : '-',
      dietaryRequirements: employee?.dietaryRequirements ? employee.dietaryRequirements : '-',
      homeAddress: employee.homeLocation ? getFormattedAddress(employee.homeLocation) : '-',
      birthdayUnformatted: employee.dateOfBirth,
      birthdayMonth: employee.dateOfBirth && getBirthdayMonth(employee.dateOfBirth),
      anniversaryMonth: employee.startDate && getAnniversaryMonth(employee.startDate),
      employmentStatus: employee?.employmentStatus || '',
    };
  };

  const getEmployeesList = () => {
    if (cgEmployeesList.length > 0 && !isSorting) {
      const resData: EmployeesListingResponse = convertObjectFieldNamesToCamelCase(cgEmployeesList);
      const employeesList: Array<RecipientRowProps> = [];
      const newEmployeeDetailsMap: { [key: number]: RecipientRowProps } = [];
      resData.forEach((employee: EmployeeDetails) => {
        const formattedEmployee = formatEmployeeDetails(employee);
        employeesList.push({ ...formattedEmployee });
      });

      const sortedCgEmployees = employeesList.sort(sortEmployeesAscending);
      setEmployees([...sortedCgEmployees]);
      setRenderedEmployees([...sortedCgEmployees]);
      let formattedNewEmployees;

      if (newlyJoinedEmployees.length > 0) {
        formattedNewEmployees = newlyJoinedEmployees.map((employee : EmployeeDetails) => formatEmployeeDetails(employee));
        const sortedNewEmployees = formattedNewEmployees.sort(sortEmployeesAscending);
        setNewEmployees([...sortedNewEmployees]);
      }

      const mergedEmployee = [...sortedCgEmployees, ...(formattedNewEmployees || [])];
      setMergedEmployees(mergedEmployee);
      const allEmployeeMap = mergedEmployee.reduce((map, employee) => {
        return { ...map, [employee.id]: { ...employee } };
      }, {});
      setEmployeeMap(allEmployeeMap);
    }
  };

  const handleSortAscending = (field? : string) => {
    setIsSorting(true);
    setManualSort(true);
    sortEmployeesInAscending(renderedEmployees, field)
      .then((sortedEmployees) => {
        setRenderedEmployees([...sortedEmployees]);
        setIsAscending(true);
        sortNewEmployeesAscending(field);
      })
      .catch((error) => {
        console.error('Failed to sort rendered employees:', error);
        setIsSorting(false);
      });
  };

  const handleSortDescending = (field? : string) => {
    setIsSorting(true);
    setManualSort(true);
    sortEmployeesInDescending(renderedEmployees, field)
      .then((sortedEmployees) => {
        setRenderedEmployees([...sortedEmployees]);
        setIsAscending(false);
        sortNewEmployeesInDescneding(field);
      })
      .catch((error) => {
        console.error('Failed to sort rendered employees:', error);
        setIsSorting(false);
      });
  };

  const sortNewEmployeesInDescneding = (field? : string) => {
    sortEmployeesInDescending(newEmployees, field)
      .then((sortedNewEmployees) => {
        setNewEmployees([...sortedNewEmployees]);
      })
      .catch((error) => {
        console.error('Failed to sort new employees:', error);
      })
      .finally(() => {
        setIsSorting(false);
      });
  };

  const sortNewEmployeesAscending = (field? : string) => {
    sortEmployeesInAscending(newEmployees, field)
      .then((sortedNewEmployees) => {
        setNewEmployees([...sortedNewEmployees]);
      })
      .catch((error) => {
        console.error('Failed to sort new employees:', error);
      })
      .finally(() => {
        setIsSorting(false);
      });
  };

  const handleSelectAll = () => {
    if (renderedEmployees.length > 0 || newlyJoinedEmployees.length > 0) {
      const renderedEmployeesSet = new Set<number>();
      const newEmployeeDetailsMap: { [key: number]: RecipientRowProps } = { ...employeeMap };

      if (newlyJoinedEmployees.length > 0) {
        const mergedEmployee = [...newlyJoinedEmployees, ...renderedEmployees];
        setMergedEmployees(mergedEmployee);

        mergedEmployees.forEach((employee: RecipientRowProps) => {
          renderedEmployeesSet.add(employee.id);
          const existingEmployeeDetails = newEmployeeDetailsMap[employee.id];
          const updatedEmployeeDetails = {
            ...existingEmployeeDetails,
            ...employee,
          };

          newEmployeeDetailsMap[employee.id] = updatedEmployeeDetails;
        });
      } else {
        renderedEmployees.forEach((employee: RecipientRowProps) => {
          renderedEmployeesSet.add(employee.id);
          const existingEmployeeDetails = newEmployeeDetailsMap[employee.id];
          const updatedEmployeeDetails = {
            ...existingEmployeeDetails,
            ...employee,
          };
          newEmployeeDetailsMap[employee.id] = updatedEmployeeDetails;
        });
      }
      const newSelectedEmployees = selectedEmployeesUnion(selectedEmployees, renderedEmployeesSet);
      setSelectedEmployees(newSelectedEmployees);
      setEmployeeMap(newEmployeeDetailsMap);
    }
    if (user) {
      const mixpanelProps: Dict = {
        $name: `${user.firstName} ${user?.lastName}`,
        $distinct_id: user.id,
        $email: user.email,
        totalEmployees: renderedEmployees?.length || 0,
        filtered: debouncedKeyword ? 'TRUE' : 'FALSE',
      };
      mixpanelCustomEvent({ mixpanelProps, id: user.id.toString(), eventName: MixpanelEventName.selectAll });
    }
  };

  const editRecipientsForNewEmployee = () => {
    if (renderedEmployees.length > 0) {
      const renderedEmployeesSet = new Set<number>();
      const newEmployeeDetailsMap: { [key: number]: RecipientRowProps } = { ...employeeMap };
      renderedEmployees.forEach((employee: RecipientRowProps) => {
        renderedEmployeesSet.add(employee.id);
        const existingEmployeeDetails = newEmployeeDetailsMap[employee.id];
        const updatedEmployeeDetails = {
          ...existingEmployeeDetails,
          ...employee,
        };
        newEmployeeDetailsMap[employee.id] = updatedEmployeeDetails;
      });

      const newSelectedEmployees = selectedEmployeesUnion(selectedEmployees, renderedEmployeesSet);
      setSelectedEmployees(newSelectedEmployees);
      setEmployeeMap(newEmployeeDetailsMap);
    }
    if (user) {
      const mixpanelProps: Dict = {
        $name: `${user.firstName} ${user?.lastName}`,
        $distinct_id: user.id,
        $email: user.email,
        totalEmployees: renderedEmployees?.length || 0,
        filtered: debouncedKeyword ? 'TRUE' : 'FALSE',
      };
      mixpanelCustomEvent({ mixpanelProps, id: user.id.toString(), eventName: MixpanelEventName.selectAll });
    }
  };

  const handleDeselectAll = () => {
    if (renderedEmployees.length > 0 || newlyJoinedEmployees.length === 0) {
      const renderedEmployeesSet = new Set<number>();
      if (newlyJoinedEmployees.length > 0) {
        const mergedEmployee = [...newlyJoinedEmployees, ...renderedEmployees];
        setMergedEmployees(mergedEmployee);

        mergedEmployees.forEach((employee: RecipientRowProps) => {
          renderedEmployeesSet.add(employee.id);
        });
      } else {
        renderedEmployees.forEach((employee: RecipientRowProps) => {
          renderedEmployeesSet.add(employee.id);
        });
      }
      const newSelectedEmployees = selectedEmployeesDifference(selectedEmployees, renderedEmployeesSet);
      setSelectedEmployees(newSelectedEmployees);
    }
    if (user) {
      const mixpanelProps: Dict = {
        $name: `${user.firstName} ${user?.lastName}`,
        $distinct_id: user.id,
        $email: user.email,
        totalEmployees: renderedEmployees?.length || 0,
        filtered: debouncedKeyword ? 'TRUE' : 'FALSE',
      };
      mixpanelCustomEvent({ mixpanelProps, id: user.id.toString(), eventName: MixpanelEventName.deselectAll });
    }
  };

  const handleRowCheckboxClick = (employmentStatus: string, isChecked: boolean) => {
    if (user) {
      const eventName = isChecked ? MixpanelEventName.selectRecipient : MixpanelEventName.deselectRecipient;
      const mixpanelProps: Dict = {
        $name: `${user.firstName} ${user?.lastName}`,
        $distinct_id: user.id,
        $email: user.email,
        employmentStatus,
      };
      mixpanelCustomEvent({ mixpanelProps, id: user.id.toString(), eventName });
    }
  };

  const updateSingleEmployeeSelection = (employeeId: number, selected: boolean) => {
    const newSelectedEmployees = new Set<number>(selectedEmployees);
    const newEmployeeDetailsMap: { [key: number]: RecipientRowProps } = { ...employeeMap };

    if (selected) {
      newSelectedEmployees.add(employeeId);
    } else {
      newSelectedEmployees.delete(employeeId);
    }
    const existingEmployeeDetails = newEmployeeDetailsMap[employeeId] || {};
    const updatedEmployeeDetails = {
      ...existingEmployeeDetails,
      selected,
    };
    newEmployeeDetailsMap[employeeId] = updatedEmployeeDetails;
    setSelectedEmployees(newSelectedEmployees);
    setEmployeeMap(newEmployeeDetailsMap);
  };

  const resetState = () => {
    setIsAllSelected(false);
  };

  const handleUpdateEmployeeForCgPlan = () => {
    if (selectedEmployees.size !== renderedEmployees.length) {
      setIsLoading(true);
      const planId = id ?? '';
      updateCgPlan({ id: planId.toString(), recipients: Array.from(selectedEmployees) })
        .then(() => {
          refetchCgPlanDetails();
          getRemainingEmployees({ connectionId: hrConnection?.id || 0 });
          const filteredRes = cgEmployeesList.filter((employee: EmployeeDetails) => !cgEmployeesList.some((cgEmployee: EmployeeDetails) => cgEmployee.id === employee.id));
          setNewlyJoinedEmployees(filteredRes);
          resetState();
          setIsLoading(false);
          setManualSort(false);
          setShowSuccessBanner(true);
          setTimeout(() => {
            setShowSuccessBanner(false);
          }, 9000);
        })
        .catch((error) => {
          console.error('Error updating CgPlan:', error);
        });
    }
  };

  const getAnnualCost = useMemo(() => {
    const platformFee = applyPlatformFee ? (platformFeeAmount * vatMultiplier * numberOfMonths) : 0;
    const treatPrice = (cgPlanDetails?.perBoxPrice ?? 0) * (cgPlanDetails?.taxable ? vatMultiplier : 1) * selectedEmployees.size;
    return ((platformFee + treatPrice) / 100).toFixed(2);
  }, [selectedEmployees, cgPlanDetails, applyPlatformFee, vatMultiplier, numberOfMonths]);

  const getNextPaymentDue = useMemo(() => {
    const platformFee = applyPlatformFee ? (platformFeeAmount * vatMultiplier) : 0;
    const noOfPlanStartMonth = getPlanStartMonthForGifting(cgPlanDetails?.planType || '', selectedEmployees, employeeMap, planStartMonthNumber);
    const treatPrice = (cgPlanDetails?.perBoxPrice ?? 0) * (cgPlanDetails?.taxable ? vatMultiplier : 1) * noOfPlanStartMonth;
    return (platformFee + treatPrice).toFixed(2);
  }, [selectedEmployees, employeeMap, planStartMonthNumber, cgPlanDetails, applyPlatformFee, vatMultiplier]);

  return {
    employees,
    newEmployees,
    editRecipientsForNewEmployee,
    fromReview,
    renderedEmployees,
    keyword,
    debouncedKeyword,
    filtering,
    isSorting,
    isAscending,
    setKeyword,
    handleSortAscending,
    handleSortDescending,
    handleSelectAll,
    handleDeselectAll,
    handleRowCheckboxClick,
    isAllSelected,
    selectedEmployees,
    updateSingleEmployeeSelection,
    getAnnualCost,
    getNextPaymentDue,
    cgPlanDetails,
    handleUpdateEmployeeForCgPlan,
    showSuccessBanner,
    resetState,
    loading,
  };
};

export default useRecipientDetails;
