import './alert.scss';
import {
  useState, useEffect,
} from 'react';
import { AlertErrorIcon, AlertSuccessIcon } from '../iconComponents';
import type { AlertProps } from './types';
import Typography from '../typography';

export const useAlert = () => {
  const [alertState, setAlertState] = useState<AlertProps>({
    show: false,
    type: 'success',
    title: '',
    content: '',
    variant: 'base',
  });
  const forceClose = () => {
    setAlertState({
      show: false,
      type: 'success',
      title: '',
      content: '',
      variant: 'base',
    });
  };
  useEffect(() => {
    if (alertState.show && alertState.variant === 'floating') {
      setTimeout(() => {
        setAlertState({
          show: false,
          type: 'success',
          title: '',
          content: '',
          variant: 'base',
        });
      }, 9000);
    }
  }, [alertState]);
  const Alert = () => {
    return (
      <div className={alertState.variant === 'floating' ? 'floating' : ''}>
        <div className={`custom-alert ${alertState.show ? 'show' : ''} ${alertState.type}`}>
          <div className="icon">
            {alertState.type === 'success' && <AlertSuccessIcon className="success" />}
            {alertState.type === 'error' && <AlertErrorIcon className="error" />}
            {alertState.type === 'info' && <AlertErrorIcon className="info" />}
          </div>
          <div className="text-content">
            {alertState.title && (
              <Typography variant="heading-compact-01" data-cy={`alert-${alertState.type}-title`} className="title">
                {alertState.title}
              </Typography>
            )}
            {typeof alertState.content === 'string' && (
              <Typography
                data-cy={`alert-${alertState.type}-content`}
                variant="body-compact-01"
                className="content"
              >
                {alertState.content}
              </Typography>
            )}
            {typeof alertState.content !== 'string'
                && alertState.content}
          </div>
        </div>
      </div>
    );
  };
  const StaticAlert = (props: AlertProps) => {
    const {
      show = true, type, title, content, style = {},
    } = props;
    return (
      <div className={`custom-alert ${show ? 'show' : ''} ${type}`} style={style}>
        <div className="icon">
          {type === 'success' && <AlertSuccessIcon className="success" />}
          {type === 'error' && <AlertErrorIcon className="error" />}
          {type === 'info' && <AlertErrorIcon className="info" />}
        </div>
        <div className="text-content">
          {title !== '' && (
            <Typography variant="heading-compact-01" className="title">
              {title}
            </Typography>
          )}
          {typeof content === 'string' && (
            <Typography variant="body-compact-01" className="content">
              {content}
            </Typography>
          )}
          {typeof content !== 'string' && content}
        </div>
      </div>
    );
  };
  return {
    setAlertState, Alert, alertState, StaticAlert, forceClose,
  };
};

export default useAlert;
