import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import lang from 'common/lang';
import { useGiftingDetails } from 'common/hooks';
import { handleExternalLink, openExternalLink } from 'common/utils/helpers';
import {
  externalLinks, internalLinks, riveUrlForAnimation, shopifyUrl,
} from 'common/constants';
import { Logo, CloseIcon, InformationCircleErrorIcon } from '../iconComponents';
import Typography from '../typography';
import Button from '../button/button';
import {
  Header, Prompt, PromptHeader, LoadingContent, EmployeeErrorPrompt, EmployeeErrorPromptContent,
} from './styles';
import { useModal } from '../modal';
import { STEPS } from '../giftingFooter/types';
import { LogoIcon } from '../authheader/styles';

export const GiftingHeader = () => {
  const { gifting: giftingCopy } = lang;
  const navigate = useNavigate();
  const { Modal: DiscardModal, setModalOpen: setDiscardModalOpen } = useModal();
  const { Modal: EmployeesLoadingModal, setModalOpen: setEmployeesLoadingModalOpen } = useModal(false);
  const {
    employeesLoaded, selectedStep, planCreated, employeeLoadingError,
  } = useGiftingDetails();
  const [progress, setProgress] = useState(0);
  const [showProgressiveLoader, setShowProgressiveLoader] = useState(true);

  useEffect(() => {
    if (selectedStep === STEPS.recipients) {
      setEmployeesLoadingModalOpen(!employeesLoaded);
      return;
    }
    setEmployeesLoadingModalOpen(false);
  }, [employeesLoaded, selectedStep]);

  useEffect(() => {
    if (selectedStep === STEPS.recipients) {
      const duration = 300000;
      const interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(interval);
            setShowProgressiveLoader(false);
            return 100;
          }
          return Math.min(oldProgress + (1200 / duration) * 100, 100);
        });
      }, 1200);

      return () => {
        clearInterval(interval);
      };
    }
  }, [selectedStep]);

  const renderLoadingContent = () => {
    if (employeeLoadingError) {
      return (
        <EmployeeErrorPrompt data-cy="employee-loading-error-modal">
          <EmployeeErrorPromptContent>
            <InformationCircleErrorIcon />
            <div>
              <Typography variant="heading-compact-02" data-cy="employee-loading-error-info1">
                {giftingCopy.employeeLoadingError1}
              </Typography>
              <Typography variant="body-01" data-cy="employee-loading-error-info2">
                {giftingCopy.employeeLoadingError}
              </Typography>
            </div>
            <div className="button-container">
              <Button
                label={giftingCopy.returnToMyPlans}
                variant="outlined"
                onClick={() => navigate('/plans')}
                data-cy="return-to-my-plans-button"
              />
              <Button
                label={giftingCopy.contactSupport}
                variant="primary"
                onClick={() => { handleExternalLink(externalLinks.contact); }}
                data-cy="conatct-support-button"
              />
            </div>
          </EmployeeErrorPromptContent>
        </EmployeeErrorPrompt>
      );
    }

    if (showProgressiveLoader) {
      return (
        <>
          <iframe style={{ border: 'none' }} width="120" height="120" src={riveUrlForAnimation} title="Animation" allowFullScreen></iframe>
          <Typography variant="heading-compact-02" style={{ textAlign: 'center' }}>
            {giftingCopy.employeeLoadingForProgressiveLoader}
          </Typography>
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <iframe style={{ border: 'none' }} width="120" height="120" src={riveUrlForAnimation} title="Animation" allowFullScreen></iframe>
          <Typography variant="heading-compact-02" style={{ textAlign: 'center' }}>
            {giftingCopy.employeeLoadingAfterProgressiveLoader}
          </Typography>

        </>
      );
    }
  };

  return (
    <>
      <Header className="header-visible auth-header">
        <LogoIcon onClick={() => openExternalLink(shopifyUrl)}>
          <Logo className="logo" />
        </LogoIcon>

        <Typography variant="heading-compact-02" data-cy="gifting-header-text">{giftingCopy.giftingHeader}</Typography>
        <CloseIcon className="close-icon" onClick={() => planCreated ? navigate('/plans') : setDiscardModalOpen(true)} data-cy="close-gifting-icon" />
      </Header>
      <DiscardModal>
        <Prompt data-cy="discard-modal">
          <PromptHeader>
            <Typography variant="heading-03">
              {giftingCopy.discardGiftingPlan}
            </Typography>
            <CloseIcon onClick={() => setDiscardModalOpen(false)} data-cy="close-discard-plan" />
          </PromptHeader>
          <Typography variant="body-compact-02" data-cy="discard-info">
            {giftingCopy.discardInfo}
          </Typography>
          <div className="button-container">
            <Button
              label={giftingCopy.stayOn}
              variant="outlined"
              onClick={() => setDiscardModalOpen(false)}
              data-cy="cancel-discard-button"
            />
            <Button
              label={giftingCopy.discard}
              variant="primary"
              onClick={() => { setDiscardModalOpen(false); navigate('/plans'); }}
              data-cy="confirm-discard-button"
            />
          </div>
        </Prompt>
      </DiscardModal>
      {selectedStep === STEPS.recipients && !employeesLoaded && (
        <EmployeesLoadingModal>
          <Prompt data-cy="loading-employees">
            <LoadingContent>
              {renderLoadingContent()}
            </LoadingContent>
          </Prompt>
        </EmployeesLoadingModal>
      )}
    </>
  );
};

export default GiftingHeader;
