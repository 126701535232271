import React from 'react';
import 'app.scss';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import theme from 'common/theme/theme';
import { persistor, store } from 'common/store/store';
import { Stamped } from 'common/utils/stamped';
import WebRoutes from './features/routes';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <WebRoutes />
          <Stamped />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
