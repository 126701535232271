import * as React from 'react';
import { SVGProps } from 'react';
const SvgTwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="M12.29 24.253c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.35 8.35 0 0 0 26 9.922a8.188 8.188 0 0 1-2.357.646 4.118 4.118 0 0 0 1.804-2.27 8.224 8.224 0 0 1-2.605.996 4.107 4.107 0 0 0-6.993 3.743A11.652 11.652 0 0 1 7.392 8.75a4.106 4.106 0 0 0 1.27 5.477 4.072 4.072 0 0 1-1.862-.512v.052a4.105 4.105 0 0 0 3.292 4.022 4.095 4.095 0 0 1-1.853.07 4.108 4.108 0 0 0 3.834 2.85A8.232 8.232 0 0 1 6 22.41a11.616 11.616 0 0 0 6.29 1.84"
    />
  </svg>
);
export default SvgTwitterIcon;
