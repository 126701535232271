import { rest } from 'msw';
import { signInUrl, signUpUrl } from '../utils/network/endpoints';

const apiMatcher = '*/api';
export const handlers = [
  rest.post(`${apiMatcher}${signInUrl}`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: {
          username: 'admin',
          token: 'some-token',
          type: 'some-token-type',
        },
      }),
    );
  }),

  rest.post(`${apiMatcher}${signUpUrl}`, (req, res, ctx) => {
    return res(
      ctx.status(201),
    );
  }),
];
