import './styles.scss';
import { useEffect, useState } from 'react';
import lang from 'common/lang';
import { theme } from 'common/theme/theme';
import { TextareaProps } from './types';
import Typography from '../typography';
export const Textarea = ({
  labelText,
  id,
  error = false,
  errorMessage = '',
  field,
  showCharacterCount = false,
  maxLength = 250,
  onChange,
  initialLength = 0,
  ...rest
}: TextareaProps) => {
  const { components: componentsCopy } = lang;
  const [characterCount, setCharacterCount] = useState(initialLength);
  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e);
    }
    setCharacterCount(e.target.value.length);
  };
  useEffect(() => {
    setCharacterCount(initialLength);
  }, [initialLength]);

  return (
    <label className="custom-textarea-label" htmlFor={id}>
      <Typography variant="heading-compact-01">
        {labelText}
      </Typography>
      <div className="custom-textarea-container">
        <textarea
          id={id}
          className="custom-textarea"
          maxLength={maxLength}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleOnChange(e)}
          {...field}
          {...rest}
        />
        {showCharacterCount && !error && (
          <Typography className="helper-text" variant="helper-text-01" color={theme.palette.text['text-02']}>
            {`${characterCount}/${maxLength} ${componentsCopy.characters}`}
          </Typography>
        )}
        {error && errorMessage && (
          <Typography className="helper-text" variant="helper-text-01" color={theme.palette.support['support-01']}>
            {errorMessage}
          </Typography>
        )}
      </div>
    </label>
  );
};

export default Textarea;
