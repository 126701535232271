import { useEffect } from 'react';
import { useUserDetails } from 'common/hooks';
import { storeUrl } from 'common/constants';
import {
  allowStamped, allowedStampedEmails,
  stampedPublicKey, storeId,
} from '../network/endpoints';
declare global {
    interface Window {
        StampedFn: any;
    }
}
let StampedFn:any;

export const Stamped = () => {
  const { user } = useUserDetails();
  useEffect(() => {
    if (user && (allowStamped || allowedStampedEmails.includes(user?.email))) {
      checkStampedFn();
    }
  }, [user]);
  const loadStamped = () => {
    if (user) {
      const {
        email,
        shopifyCustomerId = '',
        stampedAuthToken = '',
        firstName,
        lastName = '',
      } = user;
      StampedFn.init({ apiKey: stampedPublicKey, storeUrl, sId: storeId });
      StampedFn.on('stamped:init:starting', () => {
        StampedFn.initRewards({
          customerId: `${shopifyCustomerId}`,
          customerEmail: email,
          customerLocale: 'en',
          customerFirstName: firstName,
          customerLastName: lastName,
          authToken: stampedAuthToken,
        });
      });
    }
  };

  const checkStampedFn = () => {
    const intervalId = setInterval(() => {
      if ('StampedFn' in window) {
        clearInterval(intervalId);
        try {
          StampedFn = window.StampedFn;
          loadStamped();
        } catch (error) {
          console.error(error);
        }
      }
    }, 3000);
  };

  return <div></div>;
};

export default Stamped;
