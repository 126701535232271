import * as React from 'react';
import { SVGProps } from 'react';
const SvgRecipientsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.5 14.04a6.737 6.737 0 0 0 2.806-.359 2.25 2.25 0 0 0-3.512-2.04m.706 2.398v.024c0 .168-.009.335-.027.499A8.959 8.959 0 0 1 9 15.75a8.959 8.959 0 0 1-4.472-1.188 4.547 4.547 0 0 1-.028-.523m9 0a4.48 4.48 0 0 0-.706-2.397m0 0c-.799-1.25-2.2-2.08-3.794-2.08a4.497 4.497 0 0 0-3.793 2.08m0 0a2.25 2.25 0 0 0-3.511 2.04 6.738 6.738 0 0 0 2.804.357m.707-2.398A4.478 4.478 0 0 0 4.5 14.04m6.75-8.976a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm4.5 2.25a1.687 1.687 0 1 1-3.375 0 1.687 1.687 0 0 1 3.375 0Zm-10.125 0a1.688 1.688 0 1 1-3.375 0 1.688 1.688 0 0 1 3.375 0Z"
    />
  </svg>
);
export default SvgRecipientsIcon;
