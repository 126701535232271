import * as React from 'react';
import { SVGProps } from 'react';
const SvgUserGroupIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M30 31.199c.412.034.83.051 1.25.051 1.748 0 3.425-.299 4.985-.848a5 5 0 0 0-7.804-4.532M30 31.2v.051c0 .375-.02.745-.061 1.11a19.908 19.908 0 0 1-9.94 2.64c-3.617 0-7.01-.96-9.938-2.64A10.111 10.111 0 0 1 10 31.198m20 0a9.952 9.952 0 0 0-1.57-5.329m0 0A9.992 9.992 0 0 0 20 21.25a9.992 9.992 0 0 0-8.43 4.62m0 0a5 5 0 0 0-7.802 4.533 14.976 14.976 0 0 0 6.232.796m1.57-5.33A9.952 9.952 0 0 0 10 31.2M25 11.25a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm10 5a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Zm-22.5 0a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
    />
  </svg>
);
export default SvgUserGroupIcon;
