import { CgPlanType } from 'features/plans/types';
import { CgProductSpecificsContainer } from './styles';
import { CgProductData } from './cgProductData';
export const CgProductSpecifics = (props: CgPlanType) => {
  return (
    <CgProductSpecificsContainer>
      <CgProductData {...props} />
    </CgProductSpecificsContainer>
  );
};

export default CgProductSpecifics;
