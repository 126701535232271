import Typography from 'common/designSystem/typography';
import lang from 'common/lang';
import { CgPlanType } from 'features/plans/types';
import { CgLineItemContainer, CgProductDataContainer } from './styles';

export const CgProductData = (props: CgPlanType) => {
  const {
    productTitle, variant, postcardMessage, corporateSubscriptionFiles,
  } = props;
  const { order: orderCopy } = lang;
  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };
  return (
    <CgProductDataContainer>
      <Typography variant="heading-03" className="product-title">
        {productTitle}
      </Typography>
      {variant && (
        <Typography variant="body-01" noMargin>
          {variant}
        </Typography>
      )}
      {corporateSubscriptionFiles && Object.keys(corporateSubscriptionFiles).length > 0 && (
        Object.entries(corporateSubscriptionFiles).map(([key, link]) => (
          <CgLineItemContainer key={key}>
            <Typography variant="body-01">
              {key}
              :
            </Typography>
            <Typography variant="body-compact-01-underline" onClick={() => handleExternalLink(link)}>{orderCopy.link}</Typography>
          </CgLineItemContainer>
        ))
      )}
      {postcardMessage && (
        <CgLineItemContainer>
          <Typography variant="body-01">{`${orderCopy.postcardMessage} ${postcardMessage}`}</Typography>
        </CgLineItemContainer>
      )}
    </CgProductDataContainer>
  );
};

export default CgProductData;
