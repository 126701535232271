/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import lang from 'common/lang';
import {
  SubscriptionLineItems, PlanType, PlansActions, PlanStatus, BillingAttempt, BillingStatus,
} from 'features/plans/types';
import { formatPlanDates, getSampleLineItem, sortBillingAttempts } from 'common/utils/helpers';
import { productUrl } from 'common/constants';
import { IntervalIcon } from '../iconComponents';
import Button from '../button/button';
import ButtonSelect from '../buttonSelect';
import useAlert from '../alert/useAlert';
import {
  PlansContainer, Summary, ShippingContainer, ActionAndAlertContainer,
  IntervalContainer, ManagePlansContainer, ShippingHeaderAndEditor, UpcomingDeliveriesContainer, UpcomingDeliveriesItem, DatesNumber, DatesContainerListItem,
} from './styles';
import { SubscriptionHeader } from './subscriptionHeader';
import { ProductDetails } from './productDetails';
import { Shipping } from './productDetails/shipping';
import Typography from '../typography';
import { DateComponent } from '../subscription/styles';
export const SubscriptionDetail = (props: PlanType & PlansActions) => {
  const {
    subscriptionNumber, createdAt, subscriptionLineItems, deliveryInterval,
    status, onCancelSelect = () => null, onPauseSelect = () => null, onResumeSelect = () => null,
    cancelledAt, pausedAt, orders, closeAlert = () => null, nextDeliveryDate, nextPaymentDate, subscriptionBillingAttempts,
  } = props;
  const orderLineItem = getSampleLineItem(orders[0], subscriptionLineItems[0].title);
  const [isMultipleRecipients, setIsMultipleRecipients] = useState(false);
  const { order: orderCopy, plans: plansCopy } = lang;
  const { StaticAlert } = useAlert();
  const productMainUrl = `${productUrl}${orderLineItem?.shopifyProduct?.handle || ''}`;
  const filteredBillingAttempts = subscriptionBillingAttempts.filter((billingAttempt: BillingAttempt) => billingAttempt.status !== BillingStatus.completed);
  const sortedBillingAttempts = [...filteredBillingAttempts].sort(sortBillingAttempts);
  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <PlansContainer>
      <SubscriptionHeader
        name={subscriptionNumber.toString()}
        createdAt={formatPlanDates(createdAt)}
      />
      <Summary>
        {orderLineItem && subscriptionLineItems.map(
          (lineItem: SubscriptionLineItems) => (
            <ProductDetails
              key={`${lineItem.id}`}
              setIsMultipleRecipients={setIsMultipleRecipients}
              {...orderLineItem}
              status={status}
            />
          ),
        )}
      </Summary>
      <IntervalContainer>
        <IntervalIcon />
        <Typography variant="heading-compact-01">
          {`${plansCopy.intervalText} ${deliveryInterval}${deliveryInterval.includes('1') ? '' : 's'}`}
        </Typography>
      </IntervalContainer>
      {status === PlanStatus.active && (
        <>
          <ManagePlansContainer>
            <ButtonSelect
              labelText={plansCopy.managePlan}
              options={[
                { label: plansCopy.pausePlan, action: () => { onPauseSelect(); closeAlert(); } },
                { label: plansCopy.cancelPlan, action: () => { onCancelSelect(); closeAlert(); } }]}
              name={plansCopy.managePlan}
            />
          </ManagePlansContainer>
          {!nextDeliveryDate && !nextPaymentDate && sortedBillingAttempts.length === 0 && (
            <UpcomingDeliveriesContainer>
              <DateComponent>
                <Typography variant="heading-compact-01" data-cy="upcoming-deliveries">{plansCopy.upcomingDeliveries}</Typography>
                <Typography variant="body-compact-02" className="margin-top-16" data-cy="processing-dates">{plansCopy.processingDates}</Typography>
              </DateComponent>
            </UpcomingDeliveriesContainer>
          )}
          { nextDeliveryDate && nextPaymentDate && sortedBillingAttempts.length !== 0 && (
            <UpcomingDeliveriesContainer>
              <Typography variant="heading-compact-02" className="margin-bottom-16">{plansCopy.upcomingDeliveries}</Typography>
              {sortedBillingAttempts.map((item: BillingAttempt, index: number) => (
                <UpcomingDeliveriesItem key={index}>
                  <DatesNumber>{index + 1}</DatesNumber>
                  <DatesContainerListItem>
                    <DateComponent>
                      <Typography variant="label-01" data-cy="delivery-date">{plansCopy.deliveryDate}</Typography>
                      <Typography variant="body-compact-02" data-cy="plan-delivery-date">{formatPlanDates(item.deliveryDate)}</Typography>
                    </DateComponent>
                    <DateComponent>
                      <Typography variant="label-01" data-cy="payment-date">{plansCopy.paymentDueDate}</Typography>
                      <Typography variant="body-compact-02" data-cy="actual-payment-date">{formatPlanDates(item.date)}</Typography>
                    </DateComponent>
                  </DatesContainerListItem>
                </UpcomingDeliveriesItem>
              ))}
            </UpcomingDeliveriesContainer>
          )}
          <ShippingContainer>
            <ShippingHeaderAndEditor>
              <Typography variant="heading-compact-02" data-cy="shipping-address">
                {orderCopy.shippingAddress}
              </Typography>
              <Typography
                variant="heading-compact-01"
                className="edit-button"
                data-cy="edit-button"
              >
                {plansCopy.edit}
              </Typography>
            </ShippingHeaderAndEditor>
            <Shipping {...props} />
          </ShippingContainer>
        </>
      )}
      {status === PlanStatus.cancelled && (
        <ActionAndAlertContainer>
          <Button
            label={plansCopy.subscribeAgain}
            variant="secondary"
            onClick={() => handleExternalLink(productMainUrl)}
            data-cy="subscribe-again-button"
          />
          <StaticAlert
            show={true}
            type="info"
            title=""
            content={plansCopy.cancelledStaticAlert.replace('%date%', formatPlanDates(cancelledAt))}
          />
        </ActionAndAlertContainer>
      )}
      {status === PlanStatus.paused && (
        <ActionAndAlertContainer>
          <Button
            label={plansCopy.resumePlan}
            variant="secondary"
            onClick={() => { onResumeSelect(); closeAlert(); }}
            data-cy="resume-plan-button"
          />
          <StaticAlert
            show={true}
            type="info"
            title=""
            content={plansCopy.pausedStaticAlert.replace('%date%', formatPlanDates(pausedAt))}
          />
        </ActionAndAlertContainer>
      )}
    </PlansContainer>
  );
};

export default SubscriptionDetail;
