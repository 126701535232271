import * as React from 'react';
import { SVGProps } from 'react';
const SvgAlertErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m9.375 9.375.035-.017a.625.625 0 0 1 .885.71l-.59 2.364c-.13.518.408.95.885.71l.035-.017M17.5 10a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0ZM10 6.875h.006v.006H10v-.006Z"
    />
  </svg>
);
export default SvgAlertErrorIcon;
