/* eslint-disable react/no-array-index-key */
import { LineItems, OrderType } from 'features/home/types';
import { formatCreatedAtDate } from 'common/utils/helpers';
import { useUserDetails } from 'common/hooks';
import { OrderContainer, Summary } from './styles';
import { CgOrderHeader } from './cgOrderHeader';
import { ProductDetails } from './cgOrderProductDetails';
import { PricingAndShipping } from './cgOrderProductDetails/pricingAndShipping';
export const CgOrder = (props: OrderType) => {
  const {
    shopifyOrderNumber, createdAt, shopifyOrderStatusUrl, orderLineItems, id, isSubscription, perBoxPrice,
  } = props;
  const { user } = useUserDetails();
  return (
    <OrderContainer>
      <CgOrderHeader
        name={shopifyOrderNumber?.toString() || id.toString()}
        statusUrl={shopifyOrderStatusUrl}
        createdAt={formatCreatedAtDate(createdAt)}
        userId={user?.id.toString() || ''}
      />
      <Summary>
        {orderLineItems.map((lineItem: LineItems, index: number) => <ProductDetails key={index} {...lineItem} isSubscription={isSubscription} perBoxPrice={perBoxPrice} />)}
      </Summary>
      <PricingAndShipping {...props} />
    </OrderContainer>
  );
};

export default CgOrder;
