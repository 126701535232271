import theme from 'common/theme/theme';
import './contentLayout.scss';
import { ContentLayoutProps } from './types';
const ContentLayout = (props: ContentLayoutProps) => {
  const { children, backgroundColor = theme.palette['ui-base'].grey10 } = props;

  return (
    <div className="content-layout" style={{ backgroundColor }}>
      {children}
    </div>
  );
};

export default ContentLayout;
