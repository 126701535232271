/* eslint-disable react/button-has-type */
import './pillButton.scss';
import { ButtonProps } from './types';

export const PillButton = ({
  label,
  type = 'button',
  selected = false,
  className = '',
  ...props
}: ButtonProps) => {
  return (
    <button
      className={['pill-button', className, selected ? 'selected' : ''].join(' ')}
      {...props}
      type={type}
    >
      {label}
    </button>
  );
};

export default PillButton;
