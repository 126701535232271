import { useEffect, useMemo, useState } from 'react';
import Typography from 'common/designSystem/typography';
import {
  AnniverSaryCelebrationOption, PlanSummaryType,
  GiftingPlanType, GiftingPostCardOption,
} from 'features/gifting/types';
import lang from 'common/lang';
import { useCookie, useGiftingDetails, useUserDetails } from 'common/hooks';
import {
  HandIcon, LetterBoxIcon, ShieldCheckIcon,
} from 'common/designSystem/iconComponents';
import {
  downloadFile, getPlanPaymentMonthName, getPlanStartDate, getPlanStartMonth, getPlanStartMonthForGifting, truncatingLongLabels,
} from 'common/utils/helpers';
import { authCookieName, platformFeeAmount, vatDivider } from 'common/constants';
import { STEPS } from 'common/designSystem/giftingFooter/types';
import { useLazyGetCgPlansListQuery } from 'features/plans/plansService';
import theme from 'common/theme/theme';
import { CgPlansResponse } from 'features/plans/types';
import { useAppSelector } from 'common/hooks/state';
import { RootState } from 'common/store/types';
import { isEmpty } from 'lodash';
import Bugsnag from '@bugsnag/js';
import {
  GdprCompliant,
  NameAndPrice, NamePriceAndPhoto, NamePricePhotoAndDetailsContainer, PlanInfo, PlanInfoItem,
  PlanProperties, PlanPropertiesWithGdpr, PlanProperty, PricingPerBox,
} from './styles';
export const PlanSummary = (props: PlanSummaryType) => {
  const { getCookie } = useCookie();
  const isAuthenticated = (): boolean => {
    const token = useAppSelector((state: RootState) => state?.auth?.token);
    return !isEmpty(token);
  };
  const isLoggedIn = isAuthenticated() && getCookie(authCookieName);
  const { gifting: giftingCopy } = lang;
  const { user } = useUserDetails();
  const applyPlatformFee = !!user?.platformFee;
  const {
    variantSelection, customMessage, selectedStep,
    pricePerBox, selectedEmployees, taxable, employeeMap, postCardDesign, anniverSaryCelebrationFrequency,
    updateOpenGdprModal, openGdprModal, postCardOptionType,
  } = useGiftingDetails();
  const {
    title, price, imageSrc, planType,
  } = props;
  const planStartMonthNumber = getPlanStartMonth();
  const triggerDownload = (name: string, isPostcardDesign:boolean) => {
    const fileToDownload = isPostcardDesign ? postCardDesign.fileObject : variantSelection[name]?.fileObject;
    if (fileToDownload) {
      downloadFile(fileToDownload);
    }
  };
  const [cgPlansList] = useLazyGetCgPlansListQuery();
  const [latestCgPlanList, setLatestCgPlansList] = useState<CgPlansResponse>([]);

  const hasAnyActivePlan = latestCgPlanList?.some((plan) => (plan.planType.includes(`${GiftingPlanType.birthday} Plan`) || plan.planType.includes(`${GiftingPlanType.workAnniversary} Plan`)));

  useEffect(() => {
    if (isLoggedIn) {
      fetchCgPlanList();
    }
  }, [isLoggedIn]);

  const fetchCgPlanList = () => {
    cgPlansList().unwrap()
      .then((res : CgPlansResponse) => {
        return setLatestCgPlansList(res);
      })
      .catch((err) => {
        console.warn('Error fetching cg plans list', err);
      });
  };

  const getPlatformFee = () => {
    return applyPlatformFee && !hasAnyActivePlan;
  };

  const estimatedAnnualCost = useMemo(() => {
    const platformFee = getPlatformFee() ? (20 * 1.2 * 12) : 0;
    const treatPrice = (pricePerBox * (taxable ? 1.2 : 1)) * selectedEmployees.size || 0;
    return (platformFee + treatPrice).toFixed(2);
  }, [selectedEmployees, taxable, pricePerBox, applyPlatformFee]);

  const getPlanStartMonthsCount = useMemo(() => getPlanStartMonthForGifting(planType, selectedEmployees, employeeMap, planStartMonthNumber), [selectedEmployees, employeeMap]);

  const vatAmount = useMemo(() => {
    const platformFeeVat = getPlatformFee() ? platformFeeAmount * vatDivider : 0;
    const treatsVat = taxable ? pricePerBox * vatDivider * getPlanStartMonthsCount : 0;
    return platformFeeVat + treatsVat;
  }, [taxable, getPlanStartMonthsCount, pricePerBox, applyPlatformFee]);

  const totalDue = useMemo(() => {
    return (getPlanStartMonthsCount * pricePerBox) + (getPlatformFee() ? platformFeeAmount : 0) + vatAmount;
  }, [vatAmount, getPlanStartMonthsCount, pricePerBox, applyPlatformFee]);
  return (
    <>
      <NamePricePhotoAndDetailsContainer>
        <NamePriceAndPhoto>
          <NameAndPrice>
            <Typography variant="heading-03" data-cy="product-title">
              {title}
            </Typography>
            <PricingPerBox>
              <Typography variant="body-03" noMargin data-cy="product-price">
                {`£${price}`}
              </Typography>
              <Typography variant="body-01" noMargin data-cy="per-box">
                {giftingCopy.perBox}
              </Typography>
            </PricingPerBox>
          </NameAndPrice>
          <img src={imageSrc} alt={title} />
        </NamePriceAndPhoto>
        <div className="selection-info">
          {Object.keys(variantSelection).length > 0 && Object.keys(variantSelection).map((key: string) => (
            <div key={key}>
              {!variantSelection[key].selectedFile && (
                <Typography variant="body-01" noMargin key={`${key}noFile`} data-cy={`${key}-no-file`}>
                  {`${key}: ${variantSelection[key].value.includes(giftingCopy.custom) ? giftingCopy.custom : variantSelection[key].value}`}
                </Typography>
              )}
              {variantSelection[key].selectedFile && (
                <div style={{ display: 'flex' }}>
                  <Typography
                    variant="body-01"
                    noMargin
                    key={`${key}file`}
                    style={{ display: 'inline-block' }}
                  >
                    {`${key}: `}
                  </Typography>
                  {' '}
                  <div title={variantSelection[key].selectedFile}>
                    <Typography
                      variant="body-01"
                      noMargin
                      onClick={() => triggerDownload(key, false)}
                      className="downloadable"
                      data-cy={`${key}-file`}
                    >
                      {truncatingLongLabels(variantSelection[key]?.selectedFile || '')}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          ))}
          {postCardDesign && postCardDesign.fileObject && (
            <div style={{ display: 'flex' }}>
              <Typography variant="body-01" noMargin style={{ display: 'inline-block' }} data-cy="postcard-design">
                {`${giftingCopy.postcardDesign}: `}
              </Typography>
              {' '}
              <div title={postCardDesign?.selectedFile}>
                <Typography variant="body-01" noMargin className="downloadable" onClick={() => triggerDownload(giftingCopy.postcardDesign, true)}>
                  {truncatingLongLabels(postCardDesign?.selectedFile || '')}
                </Typography>
              </div>
            </div>
          )}
          {postCardOptionType === GiftingPostCardOption.Text && (
            <Typography variant="body-01" noMargin data-cy="custom-message">
              {`${giftingCopy.postcardMessage} ${customMessage}`}
            </Typography>
          )}
        </div>
      </NamePricePhotoAndDetailsContainer>
      <PlanInfo>
        <PlanInfoItem>
          {planType && (
            <>
              <Typography variant="heading-02" data-cy="plan-type">
                {giftingCopy.planType}
              </Typography>
              <Typography variant="body-02" noMargin data-cy="plan-type-selected">
                {planType}
                {anniverSaryCelebrationFrequency === AnniverSaryCelebrationOption.firstYear && ` (${anniverSaryCelebrationFrequency})`}
              </Typography>
            </>
          )}
        </PlanInfoItem>
        <PlanInfoItem>
          <Typography variant="heading-02" data-cy="plan-start">
            {giftingCopy.planStart}
          </Typography>
          <Typography variant="body-02" noMargin data-cy="plan-start-date">
            {getPlanStartDate()}
          </Typography>
        </PlanInfoItem>
      </PlanInfo>
      {selectedStep === STEPS.payment && selectedEmployees && selectedEmployees.size > 0 && (
        <>
          <PlanInfo>
            <PlanInfoItem>
              <Typography variant="heading-02" data-cy="total-recipeint">
                {giftingCopy.totalRecipients}
              </Typography>
              <Typography variant="body-02" noMargin data-cy="plan-type-selected">
                {selectedEmployees.size}
              </Typography>
            </PlanInfoItem>
            <PlanInfoItem>
              <Typography variant="heading-02" data-cy="annual-cost">
                {giftingCopy.estimatedAnnualCost}
              </Typography>
              <Typography variant="body-02" noMargin data-cy="plan-start-date">
                {`£${estimatedAnnualCost}`}
              </Typography>
            </PlanInfoItem>
          </PlanInfo>
          <PlanInfo>
            <PlanInfoItem>
              <Typography variant="heading-02" data-cy="next-payment-due">
                {`${giftingCopy.nextPaymentDue} ${getPlanPaymentMonthName()}`}
              </Typography>
            </PlanInfoItem>
            <PlanInfoItem>
              <Typography variant="heading-02" data-cy="plan-treat">
                {planType === GiftingPlanType.birthday ? giftingCopy.birthdayTreats : giftingCopy.anniversaryTreats}
              </Typography>
              <Typography variant="body-02" noMargin data-cy="plan-start-date">
                {`£${(getPlanStartMonthsCount * pricePerBox).toFixed(2)} (${getPlanStartMonthsCount} ${giftingCopy.treats})`}
              </Typography>
            </PlanInfoItem>
            <PlanInfoItem>
              <Typography variant="heading-02" data-cy="platform-fee">
                {giftingCopy.platformFee}
              </Typography>
              <Typography variant="body-02" noMargin data-cy="platform-fee-amount">
                {`£${(getPlatformFee() ? platformFeeAmount : 0).toFixed(2)}`}
              </Typography>
            </PlanInfoItem>
            <PlanInfoItem>
              <Typography variant="heading-02" data-cy="vat">
                {giftingCopy.vat}
              </Typography>
              <Typography variant="body-02" noMargin data-cy="vat-amount">
                {`£${vatAmount.toFixed(2)}`}
              </Typography>
            </PlanInfoItem>
            <PlanInfoItem>
              <Typography variant="heading-02" data-cy="total-due">
                {giftingCopy.totalDue}
              </Typography>
              <Typography variant="body-02" noMargin data-cy="total-due-amount">
                {`£${totalDue.toFixed(2)}`}
              </Typography>
            </PlanInfoItem>
          </PlanInfo>
        </>
      )}
      <PlanPropertiesWithGdpr>
        <PlanProperties>
          <PlanProperty>
            <LetterBoxIcon />
            <Typography variant="heading-compact-01">
              {giftingCopy.letterboxFriendly}
            </Typography>
          </PlanProperty>
          <PlanProperty>
            <HandIcon />
            <Typography variant="heading-compact-01">
              {giftingCopy.pauseOrCancel}
            </Typography>
          </PlanProperty>
        </PlanProperties>
        <GdprCompliant onClick={() => updateOpenGdprModal(!openGdprModal)}>
          <ShieldCheckIcon />
          <Typography variant="heading-compact-01" color={theme.palette.link['link-01']} data-cy="gdpr-compliant-modal-link">
            {giftingCopy.gdprCompliant}
          </Typography>
        </GdprCompliant>
      </PlanPropertiesWithGdpr>
    </>
  );
};

export default PlanSummary;
