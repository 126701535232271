/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import lang from 'common/lang';
import {
  PlansActions, PlanStatus, CgPlanType, CorporateSubscriptionPayment,
} from 'features/plans/types';
import { useNavigate } from 'react-router-dom';
import {
  formatDateToMonthandYYYY, formatPlanDates,
} from 'common/utils/helpers';
import {
  AlertErrorIcon, DocumentIcon, RecipientsIcon, TreatsIcon,
} from '../iconComponents';
import Button from '../button/button';
import {
  PlansContainer, Summary,
  IntervalContainer, ManagePlansContainer,
  UpcomingDeliveriesItem, DatesContainerListItem, PaymentForecastContainer, PricesInfoComponent,
  PricesAlertComponent,
  PaymentDetailComponent,
  NoOfTreatsComponent,
  AmountAndBadgeComponent,
  ViewInvoiceComponent,
  DateComponent,
} from './styles';
import { CgSubscriptionHeader } from './cgSubscriptionHeader';
import { CgProductDetails } from './cgProductDetails';
import Typography from '../typography';
import { UpcomingDeliveriesContainer } from '../subscriptionDetail/styles';
import { Variant } from '../badge/types';
import Badge from '../badge';

export const CgSubscriptionDetail = (props: CgPlanType & PlansActions) => {
  const navigate = useNavigate();
  const {
    id, createdAt, employeesCount, perBoxPrice, employees,
    status, onCancelSelect = () => null,
    closeAlert = () => null, corporateSubscriptionPayments,
    refetchCgPlanDetails = () => null,
  } = props;
  const { order: orderCopy, plans: plansCopy } = lang;
  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  const badgeStatusMapping: { [key: string] : Variant} = {
    paid: 'success',
    cancelled: 'error',
    paused: 'warning',
    forecast: 'info',
  };
  const corporateSubPayments = corporateSubscriptionPayments;
  const [generatedPattern, setGeneratedPattern] = useState<CorporateSubscriptionPayment[]>([]);

  useEffect(() => {
    const sortedData = [...corporateSubPayments].sort((first, second) => {
      const firstDate = first.nextPaymentDate ? new Date(first.nextPaymentDate) : null;
      const secondDate = second.nextPaymentDate ? new Date(second.nextPaymentDate) : null;
      return firstDate && secondDate ? firstDate.getTime() - secondDate.getTime() : 0;
    });
    const pattern: CorporateSubscriptionPayment[] = [];
    let lastPaidMonth = null;
    sortedData.forEach((record) => {
      if (record.status === 'paid') {
        lastPaidMonth = record.nextPaymentDate;
        pattern.push({
          id: record.id,
          corporateSubscriptionId: record.corporateSubscriptionId,
          nextPaymentDate: record.nextPaymentDate,
          numberOfTreats: record.numberOfTreats,
          status: 'paid',
          invoiceUrl: record.invoiceUrl,
          createdAt: record.createdAt,
          updatedAt: record.updatedAt,
        });
      }
    });
    if (lastPaidMonth) {
      const nextMonthsPattern = generateNextMonthsPattern(lastPaidMonth, 6);
      nextMonthsPattern.forEach((month) => {
        const foundRecord = corporateSubPayments.find((record) => record.nextPaymentDate === month);
        if (foundRecord) {
          pattern.push({
            id: foundRecord.id,
            corporateSubscriptionId: foundRecord.corporateSubscriptionId,
            nextPaymentDate: foundRecord.nextPaymentDate,
            numberOfTreats: foundRecord.numberOfTreats,
            status: foundRecord.status,
            invoiceUrl: foundRecord.invoiceUrl,
            createdAt: foundRecord.createdAt,
            updatedAt: foundRecord.updatedAt,
          });
        } else {
          pattern.push({
            id: null,
            corporateSubscriptionId: null,
            nextPaymentDate: month,
            numberOfTreats: null,
            status: 'forecast',
            invoiceUrl: null,
            createdAt: null,
            updatedAt: null,
          });
        }
      });
    }
    setGeneratedPattern(pattern);
  }, []);
  function generateNextMonthsPattern(startDate: Date, n: number) {
    const pattern = [];
    const currentDate = new Date(startDate);
    for (let i = 1; i <= n; i += 1) {
      currentDate.setMonth(currentDate.getMonth() + 1);
      pattern.push(currentDate.toISOString().substr(0, 10));
    }
    return pattern;
  }

  return (
    <PlansContainer>
      <CgSubscriptionHeader
        name={id.toString()}
        createdAt={formatPlanDates(createdAt)}
      />
      <Summary>
        <CgProductDetails
          {...props}
          status={status}
        />
      </Summary>
      <IntervalContainer className={`${status === PlanStatus.cancelled ? 'margin-8' : ''}`}>
        <RecipientsIcon />
        <Typography
          variant="heading-compact-01"
          className="recipient-count"
          data-cy="recipients-link"
          onClick={() => navigate(`/cgplans/${id}/recipients`)}
        >
          {employeesCount}
          {' '}
          {plansCopy.recipients}
        </Typography>
      </IntervalContainer>
      {status === PlanStatus.active && (
        <>
          <ManagePlansContainer>
            <Button
              label={plansCopy.cancelPlan}
              onClick={() => {
                onCancelSelect();
                closeAlert();
              }}
              variant="outlined"
              className="cancel-plan-button"
              data-cy="button-cancel-plan"
            />
          </ManagePlansContainer>
          {generatedPattern.length > 0 && (
            <>
              <PaymentForecastContainer>
                <PricesInfoComponent>
                  <Typography variant="heading-compact-02" data-cy="payment-forecast">{plansCopy.paymentForecast}</Typography>
                  <Typography variant="body-compact-01" className="margin-top-16" data-cy="charges-info">{plansCopy.chargesInfo}</Typography>
                  <PricesAlertComponent>
                    <AlertErrorIcon />
                    <Typography variant="body-01" className="charges-info" data-cy="per-month-charges-info">{plansCopy.perMonthChargesInfo}</Typography>
                  </PricesAlertComponent>
                </PricesInfoComponent>
              </PaymentForecastContainer>
              <UpcomingDeliveriesContainer>
                <Typography variant="heading-compact-02" className="margin-bottom-16" data-cy="upcoming-deliveries">{plansCopy.upcomingDeliveries}</Typography>
                {generatedPattern.map((payment: CorporateSubscriptionPayment, index: number) => (
                  <UpcomingDeliveriesItem key={index}>
                    <DatesContainerListItem>
                      <DateComponent>
                        <Typography variant="heading-compact-01" data-cy="next-payment-date">{payment.nextPaymentDate ? formatDateToMonthandYYYY(new Date(payment.nextPaymentDate)) : ''}</Typography>
                        <PaymentDetailComponent>
                          <NoOfTreatsComponent>
                            <TreatsIcon data-cy="treats-icon" />
                            <Typography variant="body-compact-02" className="margin-right-5" data-cy="no-of-treats">
                              {payment.numberOfTreats ? payment.numberOfTreats : 0}
                            </Typography>
                            <Typography variant="body-compact-01" data-cy="text-treats">
                              {orderCopy.treats}
                            </Typography>
                          </NoOfTreatsComponent>
                          <AmountAndBadgeComponent>
                            <DocumentIcon />
                            <Typography variant="body-compact-02" className="margin-right-5" data-cy="payment-info">{payment.numberOfTreats ? `£${((payment.numberOfTreats * perBoxPrice) / 100).toFixed(2)}` : '£0'}</Typography>
                            {payment?.status && payment?.numberOfTreats && (
                              <Badge label={payment?.status} variant={badgeStatusMapping[payment.status.toLowerCase()]} />
                            )}
                          </AmountAndBadgeComponent>
                          <ViewInvoiceComponent>
                            {payment?.status === 'paid' && payment?.invoiceUrl && (
                              <Typography variant="heading-compact-01" className="view-invoice-button" data-cy="link-invoice-url" onClick={() => handleExternalLink(payment.invoiceUrl ? payment.invoiceUrl : '')}>
                                {orderCopy.viewInvoice}
                              </Typography>
                            )}
                          </ViewInvoiceComponent>
                        </PaymentDetailComponent>
                      </DateComponent>
                    </DatesContainerListItem>
                  </UpcomingDeliveriesItem>
                ))}
              </UpcomingDeliveriesContainer>
            </>
          )}
        </>
      )}
    </PlansContainer>
  );
};

export default CgSubscriptionDetail;
