import {
  SignInDTO, SignInResponseDTO, SignUpDTO, ForgotPasswordDTO, SignUpResponseDTO,
  ResetPasswordDTO, VerifyEmailDTO, ExtendedBaseQuery, OccasionsResponse, TeamSizeResponse, ResendOtpDTO,
} from 'features/account/types';
import {
  signInUrl, signUpUrl, forgotPasswordUrl, resetPasswordUrl, logoutUrl,
  verifyEmailUrl, resendVerifyEmailUrl, occasionsListingUrl, teamSizeListingUrl,
} from 'common/utils/network/endpoints';
import { baseApi } from 'common/store/baseApi';
import { convertObjectFieldNamesToCamelCase, convertObjectFieldNamesFromCamelCaseToSnakeCase } from 'common/utils/helpers';

export const userApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['User'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      signIn: builder.mutation<SignInResponseDTO, SignInDTO>({
        query: (formData) => ({
          url: signInUrl,
          method: 'POST',
          body: formData,
        }),
      }),
      signUp: builder.mutation<SignUpResponseDTO, SignUpDTO>({
        query: (formData) => ({
          url: signUpUrl,
          method: 'POST',
          body: convertObjectFieldNamesFromCamelCaseToSnakeCase(formData),
        }),
      }),
      forgotPassword: builder.mutation<void, ForgotPasswordDTO>({
        query: (formData) => ({
          url: forgotPasswordUrl,
          method: 'POST',
          body: convertObjectFieldNamesFromCamelCaseToSnakeCase(formData),
        }),
      }),
      resetPassword: builder.mutation<void, ResetPasswordDTO>({
        query: (formData) => ({
          url: resetPasswordUrl,
          method: 'POST',
          body: convertObjectFieldNamesFromCamelCaseToSnakeCase(formData),
        }),
      }),
      logout: builder.mutation<void, void>({
        query: () => ({
          url: logoutUrl,
          method: 'POST',
        }),
      }),
      verifyEmail: builder.mutation<void, VerifyEmailDTO>({
        query: (formData) => ({
          url: verifyEmailUrl,
          method: 'POST',
          body: convertObjectFieldNamesFromCamelCaseToSnakeCase(formData),
        }),
      }),
      resendVerificationCode: builder.mutation<void, ResendOtpDTO>({
        query: (formData) => ({
          url: resendVerifyEmailUrl,
          method: 'POST',
          body: formData,
        }),
      }),
      getOccasionsList: builder.query<OccasionsResponse, void>({
        query: () => occasionsListingUrl,
        transformResponse: (response: ExtendedBaseQuery) => {
          const parseData = convertObjectFieldNamesToCamelCase(response.data);
          return parseData;
        },
      }),
      getTeamSizeList: builder.query<TeamSizeResponse, void>({
        query: () => teamSizeListingUrl,
        transformResponse: (response: ExtendedBaseQuery) => {
          const parseData = convertObjectFieldNamesToCamelCase(response.data);
          return parseData;
        },
      }),
    }),
  });

export const {
  useSignInMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  useVerifyEmailMutation,
  useResendVerificationCodeMutation,
  useGetOccasionsListQuery,
  useGetTeamSizeListQuery,
} = userApi;
