import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';

export const EmployeeCount = styled.div`
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    svg {
        color: ${({ theme }) => theme.palette.text['text-01']};
        min-width: 18px;
        min-height: 18px;
        max-width: 18px;
        max-height: 18px;
    }
`;
export const CountAndSearch = styled.div`
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;
export const ViewRecipentAndSearchBoxContainer = styled.div`
    display: flex;
    padding: 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    .back-button {
       border-radius: 6px;
        @media ${breakpoints.mobileLarge} {
            display: flex;
        }  
    }
`;
export const SucessBannerContainer = styled.div`
   display: flex;
   justify-content: center;
`;
export const InfoBannerContainer = styled.div`
    transform: scale(0);
    animation-delay: 0.5s;
    animation: showBannerContent 0.5s ease-in forwards;
    @keyframes showBannerContent {
        0% {
            opacity: 0;
            transform: scale(0);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
`;
export const SpinnerContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background: transparent;
    opacity: 0;
    animation: fadein 0.3s linear 0.5s forwards;
    @keyframes fadein {
        0% {
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
`;
export const EmptyResultsContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
    background: transparent;
    p {
        color: ${({ theme }) => theme.palette.text['text-03']};
    }
`;
export const BannerContent = styled.div`
    display: inline-block;
    p, h6 {
        display: inline;
    }
    .button-container{
        gap:12px;
        display: flex;
        min-width: 225px;
        align-items: center;
        .custom-button.base.update-plan-button {
            padding: 7px 12px !important;
            height: 18px;
        }
        .custom-button.base.cancel-button {
            padding: 7px 12px !important;
            height: 16px;
        }
    }
    
`;

export const BreadCrumpContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 16px 24px 16px 0px;
    width: calc(100% - 48px);
    margin-bottom: 32px;
    SVG{
       min-width: 18px;
       min-height: 18px;
       max-width: 18px;
       max-height: 18px;
    }
    .cancel-plan-button {
        display: none;
        width:calc(100% - 48px);
        @media ${breakpoints.mobileLarge} {
            display: flex;
        }
    }
    .my-plans-text {
        color: ${({ theme }) => theme.palette.link['link-01']};
        cursor: pointer;
        &:hover {
          color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
    }
    .plan-with-id {
        color: ${({ theme }) => theme.palette.link['text-01']};
        cursor: pointer;
        &:hover {
          color: ${({ theme }) => theme.palette.link['text-01-hover']};
        }
    }
`;
export const GiftingContentContainer = styled.div`
    margin: 0px auto 64px auto;
    @media ${breakpoints.mobileLarge} {
        margin: 54px auto 64px auto;
    }
    max-width: 1200px;
`;

export const RecipientsContentContainer = styled.div`
    opacity: 1;
    transform: translateX(0%) translateZ(0px)
`;
export const EligibleEmployeeModalLink = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: ${({ theme }) => theme.palette.brand['link-01']};
    cursor: pointer;
    margin-top: 16px;
`;
