import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import { useOnClickOutside } from 'common/hooks';
import './styles.scss';
import { ProductImage, ShopifyProduct } from 'features/gifting/types';
import { color } from 'framer-motion';
import Typography from '../typography';
import { CloseIcon } from '../iconComponents';

interface ImageGalleryModalProps {
  selectedImageProduct?: ShopifyProduct;
}

export const useImageGalleryModal = (dismissable = true) => {
  const modalRef = useRef(null);
  const [open, setModalOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [open]);

  const closeModal = () => {
    if (dismissable) {
      setModalOpen(false);
    }
  };

  useOnClickOutside(modalRef, closeModal);

  const ImageGalleryModal = useMemo(() => {
    const ImageGalleryModalComponent = (props: ImageGalleryModalProps) => {
      const { selectedImageProduct } = props;

      return (
        <div
          className={`modal-overlay-for-image ${open ? 'show' : 'hide'}`}
          data-cy="product-images-modal"
        >
          <div className="children-container" ref={modalRef}>

            <div className="title-container">
              <Typography variant="heading-03" data-cy="product-title">{selectedImageProduct?.title}</Typography>
              <CloseIcon data-cy="close-icon" onClick={closeModal} />
            </div>
            <div className="children">
              {open && (
                <div className="image-container">
                  {selectedImageProduct?.images.map((image: ProductImage) => (
                    <img
                      src={image.src}
                      alt={image.alt || ''}
                      style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    };

    return ImageGalleryModalComponent;
  }, [open]);

  return { ImageGalleryModal, setModalOpen };
};

export default useImageGalleryModal;
