import * as React from 'react';
import { SVGProps } from 'react';
const LogoForFooter = (props: SVGProps<SVGSVGElement>) => (
  <svg width="78" height="40" viewBox="0 0 78 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6152_10344)">
      <path d="M0.820862 9.72755C0.820862 3.86345 4.64125 0 10.4551 0C15.0336 0 17.7016 2.39079 17.7016 4.66974C17.7016 5.86514 16.9993 6.64334 15.5951 6.64334C13.938 6.64334 13.4044 5.50361 12.8708 4.28012C12.3088 2.97392 11.6627 1.58502 9.47206 1.58502C6.52279 1.58502 4.80948 4.08557 4.80948 7.64315C4.80948 11.5623 6.91602 16.6206 11.7757 16.6206C14.1909 16.6206 15.4269 15.2863 16.2133 14.3682C16.6628 13.8688 16.9998 13.5348 17.3368 13.5348C17.7022 13.5348 17.8704 13.8688 17.8704 14.3973C17.8704 16.5374 15.0341 19.4556 10.3437 19.4556C4.66964 19.4556 0.821387 15.5916 0.821387 9.72807H0.820862V9.72755Z" fill="#A679F8" />
      <path d="M19.191 18.4542C19.191 17.9549 19.4438 17.7593 19.8092 17.5652C20.1462 17.3431 20.5394 17.0924 20.8207 16.4255L27.2807 1.00135C27.4768 0.5285 27.8422 0.278809 28.3479 0.278809H29.8646C30.342 0.278809 30.6789 0.556071 30.8761 1.00135L37.2241 16.4255C37.4765 17.0924 37.8976 17.3712 38.2629 17.5652C38.5721 17.7874 38.8249 17.9549 38.8249 18.4542C38.8249 18.8714 38.5158 19.1768 38.0106 19.1768H32.8423C32.3371 19.1768 32.0279 18.8714 32.0279 18.4542C32.0279 18.037 32.2251 17.8425 32.4774 17.676C32.8144 17.4263 33.0957 17.2032 32.7865 16.425L31.7472 13.7023H23.6301L22.5345 16.3974C22.2254 17.1475 22.5908 17.3988 23.0119 17.6204C23.3489 17.7868 23.658 17.9824 23.658 18.4537C23.658 18.8709 23.321 19.1763 22.8437 19.1763H20.0348C19.5569 19.1763 19.1915 18.8709 19.1915 18.4537L19.191 18.4542ZM31.1568 12.1459L27.8422 3.50242L24.2757 12.1459H31.1568Z" fill="#A679F8" />
      <path d="M40.6508 18.4542C40.6508 17.9824 40.931 17.7874 41.2122 17.5652C41.4935 17.3712 41.7742 17.148 41.7742 16.6762V2.77936C41.7742 2.30755 41.493 2.08439 41.2122 1.89036C40.931 1.66823 40.6508 1.44508 40.6508 1.00135C40.6508 0.557631 40.9877 0.278809 41.4651 0.278809H46.268C46.7738 0.278809 47.1113 0.556071 47.1113 1.00135C47.1113 1.44664 46.83 1.66823 46.5493 1.89036C46.268 2.08439 45.9878 2.30755 45.9878 2.77936V9.81077L52.9536 2.80693C53.515 2.22328 53.2906 2.00116 52.9536 1.72389C52.7564 1.5283 52.5325 1.33427 52.5325 1.00135C52.5325 0.584161 52.8695 0.278809 53.3468 0.278809H56.3802C56.8859 0.278809 57.1951 0.584161 57.1951 1.00135C57.1951 1.50074 56.8581 1.7239 56.2961 2.05682C55.8187 2.33564 55.2289 2.7237 54.5544 3.41867L50.4538 7.47668L56.8581 16.4806C57.4474 17.3155 57.8974 17.5928 58.2623 17.7593C58.5714 17.9257 58.7396 18.0381 58.7396 18.3986C58.7396 19.0379 58.0657 19.4551 56.7451 19.4551C54.751 19.4551 53.2343 18.5094 52.1955 16.9811L47.5329 10.4225L45.9884 11.9233V16.6762C45.9884 17.148 46.2686 17.3712 46.5498 17.5652C46.8311 17.7874 47.1118 17.9824 47.1118 18.4542C47.1118 18.8995 46.7748 19.1768 46.2686 19.1768H41.4656C40.9883 19.1768 40.6513 18.8995 40.6513 18.4542H40.6508Z" fill="#A679F8" />
      <path d="M60.2847 18.4542C60.2847 17.9824 60.5649 17.7874 60.8462 17.5652C61.1274 17.3712 61.4082 17.148 61.4082 16.6762V2.77936C61.4082 2.30755 61.1269 2.08439 60.8462 1.89036C60.5649 1.66823 60.2847 1.44508 60.2847 1.00135C60.2847 0.557631 60.6217 0.278809 61.0991 0.278809H74.3003C74.9743 0.278809 75.3675 0.667389 75.3675 1.27861V4.75296C75.3675 5.19824 75.0863 5.53116 74.6373 5.53116C74.0753 5.53116 73.7941 4.94751 73.4855 4.16931C73.0922 3.11384 72.5592 1.83522 70.9578 1.83522H65.6218V8.56023H69.3013C70.256 8.56023 70.6776 7.97762 70.9862 7.42154C71.2675 6.92111 71.5203 6.58819 71.9977 6.58819C72.475 6.58819 72.7558 6.92111 72.7558 7.39396V11.2845C72.7558 11.7573 72.4756 12.0902 71.9977 12.0902C71.5198 12.0902 71.2675 11.7573 70.9862 11.2569C70.6776 10.7008 70.256 10.1182 69.3013 10.1182H65.6218V16.566C65.6218 17.2042 66.0429 17.6214 66.7174 17.6214H71.155C72.7279 17.6214 73.374 16.2596 73.823 15.176C74.1884 14.3135 74.497 13.6753 75.0589 13.6753C75.5084 13.6753 75.7892 14.0357 75.7892 14.5086V18.177C75.7892 18.7887 75.3959 19.1768 74.722 19.1768H61.0996C60.6222 19.1768 60.2852 18.8995 60.2852 18.4542H60.2847Z" fill="#A679F8" />
      <path d="M1.7771 38.9908C1.7771 38.5393 2.04574 38.3531 2.31386 38.1403C2.5825 37.9281 2.85114 37.7153 2.85114 37.2638V24.1389C2.85114 23.6613 2.5825 23.4746 2.31386 23.2624C2.04574 23.0501 1.7771 22.8374 1.7771 22.4118C1.7771 21.9608 2.12618 21.668 2.60931 21.668H10.8252C16.249 21.668 19.847 25.2542 19.847 30.7011C19.847 36.148 16.249 39.7342 10.718 39.7342H2.60931C2.12618 39.7342 1.7771 39.4418 1.7771 38.9903V38.9908ZM7.63043 23.3165V36.7332C8.46264 37.6362 9.56349 38.1674 10.6375 38.1674C13.6178 38.1674 14.9873 35.3245 14.9873 32.0567C14.9873 28.497 13.376 23.3165 9.48306 23.3165H7.63043Z" fill="#E0E6F9" />
      <path d="M21.6739 38.9908C21.6739 38.5393 21.9425 38.3531 22.2106 38.1403C22.4793 37.9281 22.7479 37.7153 22.7479 37.2638V24.1389C22.7479 23.6613 22.4793 23.4746 22.2106 23.2624C21.9425 23.0501 21.6739 22.8374 21.6739 22.4118C21.6739 21.9608 22.023 21.668 22.5061 21.668H31.5279C35.5823 21.668 38.2939 23.7139 38.2939 26.9016C38.2939 29.2128 36.844 31.0995 34.32 31.9495L37.7298 36.8648C38.2129 37.5821 38.7497 37.8745 39.2334 38.1133C39.6092 38.2724 39.7706 38.4587 39.7706 38.8041C39.7706 39.5485 38.8307 39.9995 37.3807 39.9995C35.4209 39.9995 33.6755 39.176 32.4674 37.2367L29.353 32.4276H27.5272V37.2633C27.5272 37.7143 27.7154 37.9275 28.0377 38.1398C28.3331 38.326 28.6018 38.5382 28.6018 38.9903C28.6018 39.4423 28.2795 39.7342 27.7696 39.7342H22.5066C22.0235 39.7342 21.6744 39.4418 21.6744 38.9903L21.6739 38.9908ZM27.5272 23.3165V30.8613H30.4807C32.521 30.8613 33.4878 29.4802 33.4878 27.7266C33.4878 25.8399 32.3601 23.3165 29.5407 23.3165H27.5272Z" fill="#E0E6F9" />
      <path d="M40.1739 30.7016C40.1739 25.096 43.9328 21.4032 49.5711 21.4032C55.2094 21.4032 58.9415 25.0955 58.9415 30.7016C58.9415 36.3077 55.2089 40 49.5711 40C43.9333 40 40.1739 36.3077 40.1739 30.7016ZM54.4313 33.4118C54.4313 29.1343 51.9342 23.1302 48.2021 23.1302C45.9468 23.1302 44.6851 25.2287 44.6851 27.9919C44.6851 32.349 47.2895 38.2735 50.9411 38.2735C53.1964 38.2735 54.4313 36.175 54.4313 33.4118Z" fill="#E0E6F9" />
      <path d="M60.7962 38.9908C60.7962 38.5393 61.0648 38.3531 61.333 38.1403C61.6016 37.9281 61.8702 37.7153 61.8702 37.2638V24.1389C61.8702 23.6613 61.6016 23.4746 61.333 23.2624C61.0648 23.0501 60.7962 22.8374 60.7962 22.4118C60.7962 21.9608 61.1453 21.668 61.6284 21.668H70.0057C74.3823 21.668 77.2816 23.9792 77.2816 27.646C77.2816 31.3128 74.3818 33.8097 69.8979 33.8097H66.649V37.2633C66.649 37.7143 66.9713 37.9275 67.3204 38.1398C67.6158 38.326 67.9113 38.5382 67.9113 38.9903C67.9113 39.4423 67.589 39.7342 67.0522 39.7342H61.6284C61.1453 39.7342 60.7962 39.4418 60.7962 38.9903V38.9908ZM66.6495 23.3165V31.2868C67.4549 31.8715 68.4217 32.2434 69.2808 32.2434C71.4556 32.2434 72.4487 30.7021 72.4487 28.7634C72.4487 26.6113 71.2138 23.317 68.2335 23.317H66.6495V23.3165Z" fill="#E0E6F9" />
    </g>
    <defs>
      <clipPath id="clip0_6152_10344">
        <rect width="77" height="40" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>

);
export default LogoForFooter;
