import {
  ExtendedBaseQuery,
} from 'features/account/types';
import {
  corporateGiftsProductsUrl,
  linkTokenUrl,
  hrConnectionIdUrl,
  hrActiveConnectonUrl,
  hrConnectionEmployessUrl,
  createCGplanUrl,
  createCgPlanDraftsUrl,
} from 'common/utils/network/endpoints';
import { baseApi } from 'common/store/baseApi';
import { convertObjectFieldNamesToCamelCase } from 'common/utils/helpers';
import {
  ShopifyProducts, GetLinkTokenResponse, ConnectionDetailsResponse, ActiveConnectionDetailsResponse, EmployeesListingResponse, CreateSubscriptionDTO, CreateCGPlanResponse,
  CreateSubscriptionDTODraft,
  DraftsResponse,
} from './types';

export const giftingApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['User'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCGProducts: builder.query<ShopifyProducts, void>({
        query: () => corporateGiftsProductsUrl,
        transformResponse: (response: ExtendedBaseQuery) => {
          const parseData = convertObjectFieldNamesToCamelCase(response.data);
          return parseData;
        },
      }),
      getLinkToken: builder.mutation<GetLinkTokenResponse, { id: number | string}>({
        query: (formData) => ({
          url: linkTokenUrl.replace('%id%', formData.id.toString()),
          method: 'POST',
        }),
      }),
      getConnectionId: builder.mutation<ConnectionDetailsResponse, { id: number | string, token: string}>({
        query: (formData) => ({
          url: hrConnectionIdUrl.replace('%id%', formData.id.toString()),
          method: 'POST',
          body: { token: formData.token },
        }),
      }),
      checkForActiveConnection: builder.query<ActiveConnectionDetailsResponse, void>({
        query: () => hrActiveConnectonUrl,
        transformResponse: (response: ExtendedBaseQuery) => {
          const parseData = convertObjectFieldNamesToCamelCase(response.data);
          return parseData;
        },
      }),
      getEmployees: builder.query<EmployeesListingResponse, { connectionId: number}>({
        query: (formData) => hrConnectionEmployessUrl.replace('%connection_id%', formData.connectionId.toString()),
        transformResponse: (response: ExtendedBaseQuery) => {
          const parseData = convertObjectFieldNamesToCamelCase(response.data);
          return parseData;
        },
      }),
      createCGplan: builder.mutation<CreateCGPlanResponse, CreateSubscriptionDTO>({
        query: (formData) => ({
          url: createCGplanUrl,
          method: 'POST',
          body: formData,
        }),
      }),
      createCGplanDrafts: builder.mutation<CreateCGPlanResponse, CreateSubscriptionDTODraft>({
        query: (formData) => ({
          url: createCgPlanDraftsUrl,
          method: 'POST',
          body: formData,
        }),
      }),
      getDrafts: builder.query<DraftsResponse, void>({
        query: () => createCgPlanDraftsUrl,
        transformResponse: (response: ExtendedBaseQuery) => {
          const parseData = convertObjectFieldNamesToCamelCase(response.data);
          return parseData;
        },
      }),
    }),
  });

export const {
  useGetCGProductsQuery,
  useGetLinkTokenMutation,
  useGetConnectionIdMutation,
  useLazyCheckForActiveConnectionQuery,
  useLazyGetEmployeesQuery,
  useCreateCGplanMutation,
  useCreateCGplanDraftsMutation,
  useLazyGetDraftsQuery,
} = giftingApi;
