import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';

export const PlansContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}`};
    align-items: flex-start;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    @media ${breakpoints.desktopSmall} {
        width: 100%;
    }
`;

export const Summary = styled.div`
    padding: 24px 24px 16px 24px;
    width: calc(100% - 48px);
`;

export const InvoiceContainer = styled.div`
    padding: 24px;
    width: calc(100% - 48px);
    text-align: left;
    margin-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
`;

export const DatesContainer = styled.div`
    display: flex;
    border-top: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    padding: 24px 24px 0px 24px;
    width: calc(100% - 48px);
`;

export const DateComponent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 180px;
`;

export const IntervalContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    padding-left: 24px;
    padding-bottom: 24px;
    svg {
        min-height: 18px;
        min-width: 18px;
        max-width: 18px;
        max-height: 18px;
    }
`;
