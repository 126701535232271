import { useCallback } from 'react';
import {
  setSelectedStep, setSelectedProduct, setDirection,
  resetGiftingState, setVariantSelection, setIsLoading,
  setCustomMessage, setConnectionDetails, setEmployeesLoaded,
  setEmployeeMap, setSelectedEmployees, setPricePerBox, setTaxable,
  setAlertState, setPlanCreated, setCreatedPlanId, setCustomPostcardDesign, setPlanType, setAnniverSaryCelebrationFrequency,
  setOpenEligibleEmployeeModal, setOpenGdprModal, setSelectedImage, setEmployeeLoadingError, setPostCardOptionType, setShowCgSignUpModal,
} from 'features/gifting/giftingSlice';
import lang from 'common/lang';
import {
  AnniverSaryCelebrationOption,
  ConnectionDetails, EmployeeMap, GiftingPlanType, GiftingPostCardOption, ShopifyProduct, VariantOption,
} from 'features/gifting/types';
import { STEPS } from 'common/designSystem/giftingFooter/types';
import { AlertProps } from 'common/designSystem/alert/types';
import { RootState } from '../store/types';
import { useAppSelector, useAppDispatch } from './state';
export const useGiftingDetails = () => {
  const { gifting: giftingCopy } = lang;
  const {
    selectedStep, selectedProduct, direction, variantSelection, createdPlanId,
    planType, isLoading, customMessage, connectionDetails, employeesLoaded,
    employeeMap, selectedEmployees, pricePerBox, taxable, alertState, planCreated, postCardDesign,
    anniverSaryCelebrationFrequency, openEligibleEmployeeModal, openGdprModal, selectedImageProduct,
    employeeLoadingError, postCardOptionType, showCgSignUpModal,
  } = useAppSelector((state: RootState) => state.gifting);
  const dispatch = useAppDispatch();

  const updateSelectedProduct = useCallback((product: ShopifyProduct) => {
    dispatch(setSelectedProduct({ product }));
    dispatch(setPricePerBox(parseInt(product?.variants[0]?.price) || 0));
    dispatch(setTaxable(product?.variants[0]?.taxable));
  }, []);

  const setStep = useCallback((step: number) => {
    if (step > selectedStep) {
      dispatch(setDirection({ direction: 1 }));
      dispatch(setSelectedStep({ step }));
      return;
    }
    dispatch(setDirection({ direction: -1 }));
    dispatch(setSelectedStep({ step }));
  }, [selectedStep]);

  const resetGiftingStatus = useCallback(() => {
    dispatch(resetGiftingState());
  }, []);

  const isPostcardOptionSelected = () => {
    return (
      postCardOptionType === GiftingPostCardOption.Text
      || (postCardOptionType === GiftingPostCardOption.Design && postCardDesign && postCardDesign.fileObject)
    );
  };

  const allowNextForStep = (step: number) => {
    if (step === STEPS.treat && selectedProduct !== null) {
      return true;
    }
    if (step === STEPS.plan && planType !== '' && isPostcardOptionSelected() && customVariantHasFile()) {
      return true;
    }
    if (step === STEPS.hrIntegration && connectionDetails) {
      return true;
    }
    if (step === STEPS.recipients && selectedEmployees && selectedEmployees.size > 0) {
      return true;
    }
    return false;
  };

  const updateVariantSelection = (option: string, value: string, fileName = '', selectedFile = '', fileObject: File | null = null) => {
    const selection: VariantOption = {
      value, isCustom: value.includes(giftingCopy.custom) || value.includes(giftingCopy.upload), fileName, selectedFile, fileObject,
    };
    dispatch(setVariantSelection({ option, selection }));
  };

  const setLoading = (status: boolean) => {
    dispatch(setIsLoading({ status }));
  };

  const updateCustomMessage = (message: string) => {
    dispatch(setCustomMessage(message));
  };

  const updateCustomPostcardDesign = (fileInfo: {selectedFile: string; uploadedFileName: string; fileObject: File | null }) => {
    dispatch(setCustomPostcardDesign({ selection: fileInfo }));
  };

  const customVariantHasFile = () => {
    return Object.keys(variantSelection).length > 0 && Object.values(variantSelection).every((value: VariantOption) => {
      if (!value.isCustom || (value.isCustom && value.fileName !== '')) {
        return true;
      }
      return false;
    });
  };

  const updateConnectionDetails = (connectionData: ConnectionDetails) => {
    dispatch(setConnectionDetails({ connectionDetails: { ...connectionData } }));
  };
  const updateSelectedImage = (product: ShopifyProduct) => {
    dispatch(setSelectedImage({ product }));
  };

  const updateEmployeesLoaded = (status: boolean) => {
    dispatch(setEmployeesLoaded(status));
  };

  const updateEmployeeMap = (employeeMapData: EmployeeMap) => {
    dispatch(setEmployeeMap(employeeMapData));
  };

  const updateSingleEmployeeSelection = (employeeId: number, selected: boolean) => {
    const newSelectedEmployees = new Set<number>(selectedEmployees);
    if (selected) {
      newSelectedEmployees.add(employeeId);
      dispatch(setSelectedEmployees(newSelectedEmployees));
      return;
    }
    if (selectedEmployees.has(employeeId)) {
      newSelectedEmployees.delete(employeeId);
      dispatch(setSelectedEmployees(newSelectedEmployees));
    }
  };

  const updateSelectedEmployees = (selectedEmployeesSet: Set<number>) => {
    dispatch(setSelectedEmployees(selectedEmployeesSet));
  };

  const updateAlertState = (alertStateData: AlertProps) => {
    dispatch(setAlertState(alertStateData));
  };

  const updatePlanCreatedStatus = (status: boolean) => {
    dispatch(setPlanCreated(status));
  };

  const updateCreatedPlanId = (planId: number) => {
    dispatch(setCreatedPlanId(planId));
  };

  const updatePlanType = (selectedPlanType : string) => {
    dispatch(setPlanType({ planType: selectedPlanType }));
    if (selectedPlanType === GiftingPlanType.workAnniversary) {
      dispatch(setAnniverSaryCelebrationFrequency({ celebrationFrequency: AnniverSaryCelebrationOption.everyYear }));
    }
  };

  const updatePostCardOptionType = (selectedOptionType : string) => {
    dispatch(setPostCardOptionType({ postCardOptionType: selectedOptionType }));
  };

  const updateCelebrationFrequency = (selectedFrequency : string) => {
    dispatch(setAnniverSaryCelebrationFrequency({ celebrationFrequency: selectedFrequency }));
  };

  const updateOpenEligibleEmployeeModal = (openMOdal : boolean) => {
    dispatch(setOpenEligibleEmployeeModal(openMOdal));
  };

  const updateOpenGdprModal = (openGdprMOdal : boolean) => {
    dispatch(setOpenGdprModal(openGdprMOdal));
  };

  const updateEmployeeFetchingError = (isError : boolean) => {
    dispatch(setEmployeeLoadingError(isError));
  };

  const updateShowSignUPModal = (showModal : boolean) => {
    dispatch(setShowCgSignUpModal({ showCgSignUpModal: showModal }));
  };

  return {
    isLoading,
    selectedStep,
    selectedProduct,
    direction,
    variantSelection,
    planType,
    customMessage,
    connectionDetails,
    employeesLoaded,
    employeeMap,
    selectedEmployees,
    pricePerBox,
    taxable,
    alertState,
    planCreated,
    createdPlanId,
    postCardDesign,
    anniverSaryCelebrationFrequency,
    openEligibleEmployeeModal,
    openGdprModal,
    selectedImageProduct,
    employeeLoadingError,
    postCardOptionType,
    showCgSignUpModal,
    updatePlanCreatedStatus,
    updateAlertState,
    updateSelectedProduct,
    setStep,
    resetGiftingStatus,
    allowNextForStep,
    updateVariantSelection,
    setLoading,
    updateCustomMessage,
    updateConnectionDetails,
    updateEmployeesLoaded,
    updateEmployeeMap,
    updateSingleEmployeeSelection,
    updateSelectedEmployees,
    updateCreatedPlanId,
    updateCustomPostcardDesign,
    updatePlanType,
    updateCelebrationFrequency,
    updateOpenEligibleEmployeeModal,
    updateOpenGdprModal,
    updateSelectedImage,
    updateEmployeeFetchingError,
    updatePostCardOptionType,
    updateShowSignUPModal,
  };
};

export default useGiftingDetails;
