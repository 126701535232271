import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';

export const PlansContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}`};
    align-items: flex-start;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    @media ${breakpoints.desktopSmall} {
        width: auto;
    }
    .margin-8 {
        margin-bottom: 16px;
    }
`;

export const Summary = styled.div`
    padding: 24px;
    width: calc(100% - 48px);
`;

export const ShippingContainer = styled.div`
    padding: 24px;
    width: calc(100% - 48px);
    text-align: left;
    margin-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
`;
export const PaymentForecastContainer = styled.div`
    padding: 24px 24px 0 24px ;
    width: calc(100% - 48px);
    text-align: left;
    margin-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
`;
export const DatesContainer = styled.div`
    display: flex;
    border-top: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    padding: 24px 24px 0px 24px;
    width: calc(100% - 48px);
`;

export const PricesInfoComponent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 180px;
    .margin-top-16 {
        margin-top: 16px;    
    }    
`;

export const PricesAlertComponent = styled.div`
    display: flex;
    flex-wrap:no-wrap;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    align-content: center;
    min-width: 180px;
    padding: 8px 16px;
    gap: 12px;
    align-self: stretch;
    border-radius: 6px;
    margin: 16px 0px;   
    background-color: ${({ theme }) => theme.palette.support['support-background-03']};
    .charges-info{
        color: ${({ theme }) => theme.palette['ui-base'].grey100};
    }
    svg {
        min-height: 20px;
        min-width: 20px;
        max-width: 20px;
        max-height: 20px;
        color: ${({ theme }) => theme.palette.link['link-01']};
    }
`;

export const IntervalContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    padding-left: 24px;
    svg {
        min-height: 18px;
        min-width: 18px;
        max-width: 18px;
        max-height: 18px;
    }
    .recipient-count {
        text-decoration: underline;
        color: ${({ theme }) => theme.palette.link['link-01']};
        cursor: pointer;
        &:hover {
          color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
    }
`;
export const ManagePlansContainer = styled.div`
    padding: 16px 24px 16px;
    width: calc(100% - 48px);
    .cancel-plan-button {
        display: none;
        width:calc(100% - 48px);
        @media ${breakpoints.mobileLarge} {
            display: flex;
        }
    }
`;

export const ShippingHeaderAndEditor = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .edit-button {
        display: none;
        cursor: pointer;
        color: ${({ theme }) => theme.palette.link['link-01']};
        &:hover{
            color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
    }
`;

export const DateComponent = styled.div`
    width: 100%;
`;

export const ActionAndAlertContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: calc(100% - 48px);
    padding: 16px 24px 24px 24px;
`;

export const DatesNumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.palette.support['support-background-03']};
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text['text-01']};
    border-radius: 50%;
`;

export const UpcomingDeliveriesItem = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
`;

export const DatesContainerListItem = styled.div`
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    width: 100%;
    gap: 8px;
`;

export const PaymentDetailComponent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 16px 0px;
`;

export const NoOfTreatsComponent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 150px;
    max-width: 150px;
    gap: 5px;
    svg {
        min-height: 18px;
        min-width: 18px;
        max-width: 18px;
        max-height: 18px;
        margin-right: 8px;
    }
`;

export const AmountAndBadgeComponent = styled.div`
    display: flex;
    flex-direction: row;
    flex-direction: row;
    align-items: center;
    min-width: 150px;
    max-width: 150px;
    svg {
        min-height: 18px;
        min-width: 18px;
        max-width: 18px;
        max-height: 18px;
        margin-right: 8px;
    }
    .margin-right-5 {
        margin-right: 5px;
    }
`;

export const ViewInvoiceComponent = styled.div`
    display: flex;
    min-width: 81px;
    .view-invoice-button {
        color: ${({ theme }) => theme.palette.link['link-01']};
        cursor: pointer;
        &:hover {
          color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
    }
  }
`;

export const NewRecipentsNotification = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    margin-left: 24px;
    margin-top: 8px;
    width: calc(100% - 72px);
    border-radius: 6px;
    background-color: ${({ theme }) => theme.palette.support['support-background-03']};
    @media ${breakpoints.desktopSmall} {
      flex-direction: row;
    }
`;

export const NotificationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    max-width: 305px;
    svg {
        min-height: 18px;
        min-width: 18px;
        max-width: 18px;
        max-height: 18px;
        margin-right: 12px;
    }
    .custom-button.base.new-joiner-button {
        padding: 7px 12px;
    }
`;

export const NewJoinerButtons = styled.div`
   display: flex;
   gap:12px;
   align-items: center;
   .custom-button.base.new-joiner-button {
        padding: 7px 12px !important;
        height: 18px;
    }
    .custom-button.base.review-button {
        padding: 7px 12px !important;
        height: 16px;
    }
`;
