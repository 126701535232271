import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';
export const OrdersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 1084px;
    margin: auto;
    @media ${breakpoints.tablet} {
        margin-top: 82px;
    }
`;

export const OrdersAndSupportContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media ${breakpoints.desktopSmall} {
        flex-direction: row;
    }
`;

export const EmptyState = styled.div`
    padding: 32px 24px;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    text-align: center;
    img {
        width: 100%;
        max-width: 320px;
        max-height: 320px;
        object-fit: cover;
        aspect-ratio: 1 / 1;
    }
    @media ${breakpoints.desktopSmall} {
        width: 100%;
        max-width: 602px;
        padding: 32px;
    }
`;
export const Contact = styled.div`
    padding: 24px 36px;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    svg {
        max-width: 80px;
        max-height: 80px;
        min-width: 80px;
        min-height: 80px;
    }
`;

export const OrdersList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
`;

export const ContactAndCgBanner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media ${breakpoints.desktopSmall} {
        height: fit-content;
        min-width: 256px;
        max-width: 330px;
        position: sticky;
        top: 184px;
    }
`;

export const CgBannerCalloutContainert = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: center;
    text-align: center;
    gap: 16px;
    background-color: ${({ theme }) => theme.palette.background['ui-background']};
    .no-margin {
        margin: 0;
    }
    svg {
        max-width: 80px;
        max-height: 80px;
        min-width: 80px;
        min-height: 80px;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
