import styled from 'styled-components';

export const CgProductDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  &:not(:first-child) {
    margin-top: 24px;
  }
`;

export const BadgeAndCorporateGiftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
`;
