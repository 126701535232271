import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import lang from 'common/lang';
import { bugsNagApiKey } from '../network/endpoints';

const { bugsnag: bugsNagCopy } = lang;
export const initializeBugsnag = () => {
  if (bugsNagApiKey) {
    Bugsnag.start({
      apiKey: bugsNagApiKey,
      plugins: [new BugsnagPluginReact()],
    });

    BugsnagPerformance.start({ apiKey: bugsNagApiKey });
  } else {
    console.error(bugsNagCopy.errorText);
  }
};

export const getErrorBoundary = (React : any) => {
  const plugin = Bugsnag.getPlugin('react');
  if (plugin) {
    return plugin.createErrorBoundary(React);
  } else {
    console.error(bugsNagCopy.errorPluginNotAvalible);
    return React.Fragment;
  }
};

export default Bugsnag;
