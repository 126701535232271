import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';
export const PlansContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 1084px;
    margin: auto;
    @media ${breakpoints.tablet} {
        margin-top: 82px;
    }
`;

export const PlansAndSupportContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media ${breakpoints.desktopSmall} {
        flex-direction: row;
    }
`;
export const EmptyStateContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    @media ${breakpoints.desktopSmall} {
        flex-direction: row;
        gap: 16px;
    }
`;

export const EmptyState = styled.div`
    padding: 32px 24px;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;
    width: calc(100% - 72px);
    height: calc(100% - 48px);
    justify-content: space-between;
    border-radius: 6px;
     img {
        width: 100%;
        max-width: 320px;
        max-height: 320px;
    }
    @media ${breakpoints.desktopSmall} {
        width: 100%;
        max-width: 602px;
        padding: 32px;
    }
`;
export const RecurringOrderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media ${breakpoints.desktopSmall} {
        height: fit-content;
        min-width: 256px;
        max-width: 330px;
        top: 184px;
    }
`;

export const SubTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    .no-margin {
        margin: 0;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

export const RecurringOrderCallout = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: center;
    text-align: center;
    gap: 16px;
    background-color: ${({ theme }) => theme.palette.background['ui-background']};
    .no-margin {
        margin: 0;
    }
    svg {
        max-width: 80px;
        max-height: 80px;
        min-width: 80px;
        min-height: 80px;
    }
`;

export const ImageContainer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: center;
    img {
        min-width: 200px;
        min-height: 200px;
    }
`;

export const Contact = styled.div`
    padding: 24px;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;
    svg {
        max-width: 80px;
        max-height: 80px;
        min-width: 80px;
        min-height: 80px;
    }
    @media ${breakpoints.desktopSmall} {
        height: fit-content;
        min-width: 256px;
    }
`;

export const PlansList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
`;

export const PromptHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    svg {
        cursor: pointer;
        min-height: 20px;
        min-width: 20px;
        max-width: 20px;
        max-height: 20px;
        transform: scale(1);
        transition: transform 0.2s ease-in-out;
        &:hover{
            transform: scale(1.15);
        }
    }
`;

export const OperationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    @media ${breakpoints.desktopSmall} {
        position: sticky;
        top: 74px;
    }
`;

export const OperationsContainerOuter = styled.div`
    display: block;
    position: relative;
`;

export const ScheduleTreats = styled.div`
    padding: 24px;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;
    svg {
        width: 70px;
        height: 70px;
        max-width: 70px;
        max-height: 70px;
        min-width: 40px;
        min-height: 40px;
    }
    @media ${breakpoints.desktopSmall} {
        height: fit-content;
        min-width: 256px;
    }
`;

export const ConnectedHrPlatform = styled.div`
    padding: 24px;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;
    opacity: 0;
    transform: scale(0);
    animation: showConnection 0.5s ease-in forwards;
    .disconnect {
        color: ${({ theme }) => theme.palette.button['button-01']};
        &:hover {
            color: ${({ theme }) => theme.palette.button['button-01-hover']};
        }
        cursor: pointer;
    }
    @keyframes showConnection {
        0% {
            opacity: 0;
            transform: scale(0);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    img, svg {
        max-width: 40px;
        max-height: 40px;
        min-width: 40px;
        min-height: 40px;
    }
    @media ${breakpoints.desktopSmall} {
        height: fit-content;
        min-width: 256px;
    }
`;

export const ConnectedIndicator = styled.div<{$isSyncError: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    border-radius: 10px;
    background-color: ${({ theme, $isSyncError }) => $isSyncError ? theme.palette.support['support-background-01'] : theme.palette.support['support-background-02']};
`;

export const SyncIssue = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
`;

export const CorporateSubscriptionAndSubscription = styled.div`
    display: flex;
    flex-direction: column;
`;
