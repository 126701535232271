/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Logo, FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon,
} from 'common/designSystem/iconComponents';
import lang from 'common/lang';
import theme from 'common/theme/theme';
import { socialLinks, externalLinks } from 'common/constants';
import Typography from '../typography';
import Link from '../link';
import { FooterContainer, SocialLinksContainer, OtherLinksContainer } from './styles';
import LogoForFooter from '../iconComponents/LogoForFooter';

export const Footer = () => {
  const { footer: footerCopy } = lang;
  return (
    <FooterContainer>
      <LogoForFooter />
      <SocialLinksContainer>
        <a
          href={socialLinks.facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon data-cy="facebook-icon" />
        </a>
        <a
          href={socialLinks.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon data-cy="instagram-icon" />
        </a>
        <a
          href={socialLinks.linkedIn}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon data-cy="linkdin-icon" />
        </a>
        <a
          href={socialLinks.twitter}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon data-cy="twitter-icon" />
        </a>
      </SocialLinksContainer>
      <Typography variant="body-compact-01" color={theme.palette.text['text-04']} style={{ textAlign: 'center' }}>
        {footerCopy.copyright}
      </Typography>
      <OtherLinksContainer>
        <Link
          type="light"
          href={externalLinks.shop}
          target="_blank"
          rel="noopener noreferrer"
          data-cy="shop-link"
        >
          {footerCopy.shop}
        </Link>
        <Link
          type="light"
          href={externalLinks.contact}
          target="_blank"
          rel="noopener noreferrer"
          data-cy="contact-link"
        >
          {footerCopy.contact}
        </Link>
        <Link
          type="light"
          href={externalLinks.tandCs}
          target="_blank"
          rel="noopener noreferrer"
          data-cy="tandcs-link"
        >
          {footerCopy.tandCs}
        </Link>
        <Link
          type="light"
          href={externalLinks.privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
          data-cy="privacy-policy-link"
        >
          {footerCopy.privacyPolicy}
        </Link>
      </OtherLinksContainer>
    </FooterContainer>
  );
};

export default Footer;
