import { ShopifyProduct } from 'features/gifting/types';
import { CheckSolidIcon } from 'common/designSystem/iconComponents';
import theme from 'common/theme/theme';
import lang from 'common/lang';
import { useUserDetails } from 'common/hooks';
import { mixpanelCustomEvent, Dict, MixpanelEventName } from 'common/utils/mixpanel/eventTriggers';
import { getDietaryRequirementsOptions } from 'common/utils/helpers';
import {
  PricingPerBox, ProductCardContainer, TitleAndPrice, ImageContainer, DietaryRequirementsContainer, ProductCardItems,
} from './styles';
import { ImageWithSkeleton } from '../imageWithSkeleton';
import Typography from '../typography';
import Badge from '../badge';
import Button from '../button/button';
import useImageGalleryModal from '../imageModal';
import MagnifyingGlassIcon from '../iconComponents/MagnifyingGlassIcon';

type ExtendedProps = {
  price: string,
  selectedProduct: ShopifyProduct | null,
  selectedImageProduct: ShopifyProduct | null,
  setSelectedProduct: React.Dispatch<React.SetStateAction<ShopifyProduct | null>>;
  setSelectedImage: React.Dispatch<React.SetStateAction<ShopifyProduct | null>>;
};
const ProductCard = (props: ShopifyProduct & ExtendedProps) => {
  const {
    id, title, status, bodyHtml, price, image, selectedProduct, selectedImageProduct, setSelectedProduct, setSelectedImage, options, ...rest
  } = props;
  const { user } = useUserDetails();
  const { gifting: giftingCopy } = lang;
  const dietaryOptions = getDietaryRequirementsOptions(options);
  const { ImageGalleryModal, setModalOpen } = useImageGalleryModal();

  const handleImageSelect = () => {
    setSelectedImage(props);
    setModalOpen(true);
  };

  const handleProductSelect = () => {
    setSelectedProduct(props);
    if (user) {
      const mixpanelProps: Dict = {
        $name: `${user.firstName} ${user?.lastName}`,
        $distinct_id: user.id,
        $email: user.email,
        treat: title,
        price: `£${price}`,
      };
      mixpanelCustomEvent({ mixpanelProps, id: user.id.toString(), eventName: MixpanelEventName.selectTreat });
    }
  };
  return (
    <>
      <ProductCardContainer className={id === selectedProduct?.id ? 'selected' : ''} {...rest}>
        <ProductCardItems>
          <ImageContainer className={id === selectedProduct?.id ? 'selected' : ''} onClick={() => handleImageSelect()} data-cy="product-image">
            <ImageWithSkeleton
              src={image.src}
              alt={title}
              loading="lazy"
              decoding="async"
              borderRadius="6px"
            />
            <MagnifyingGlassIcon />
            {id === selectedProduct?.id && <CheckSolidIcon color={theme.palette.brand['brand-01']} />}
          </ImageContainer>
          <TitleAndPrice>
            <Typography variant="heading-03" data-cy="product-title">
              {title}
            </Typography>
            <PricingPerBox>
              <Typography variant="heading-compact-02" data-cy="product-price">
                {`£${price}`}
              </Typography>
              <Typography variant="body-01" noMargin data-cy="text-per-box">
                {giftingCopy.perBox}
              </Typography>
            </PricingPerBox>
          </TitleAndPrice>
          <DietaryRequirementsContainer>
            {dietaryOptions.map((option) => (
              <Badge key={option} label={option} size="small" data-cy="dietary-option-badge" />
            ))}
          </DietaryRequirementsContainer>
          <div className="raw-html-content" dangerouslySetInnerHTML={{ __html: `${bodyHtml}` }} />
        </ProductCardItems>
        <div className="button-container">
          <Button
            label={id === selectedProduct?.id ? giftingCopy.selected : giftingCopy.selectTreat}
            variant="outlined"
            className={id === selectedProduct?.id ? 'selected-treat-button' : 'select-treat-button'}
            data-cy="select-treat-button"
            onClick={handleProductSelect}
          />
        </div>

      </ProductCardContainer>
      <ImageGalleryModal selectedImageProduct={selectedImageProduct !== null ? selectedImageProduct : undefined} />
    </>
  );
};

export default ProductCard;
