import { Dispatch, SetStateAction, useEffect } from 'react';
import lang from 'common/lang';
import { LineItems } from 'features/home/types';
import Badge from '../../badge';
import Typography from '../../typography';
import {
  ProductDetailsContainer, BadgeAndRecurringTreatsContainer,
} from './styles';
import ProductSpecifics from './productSpecifics';
import { Variant } from '../../badge/types';
export const ProductDetails = (props: LineItems & { setIsMultipleRecipients: Dispatch<SetStateAction<boolean>> } & { status: string }) => {
  const { order: orderCopy } = lang;
  const { recipients: multipleRecipients, setIsMultipleRecipients, status } = props;
  const badgeStatusMapping: { [key: string] : Variant} = {
    active: 'success',
    cancelled: 'error',
    paused: 'warning',
    expired: 'info',
  };
  useEffect(() => {
    if (multipleRecipients) {
      setIsMultipleRecipients(true);
    }
  }, [multipleRecipients]);
  return (
    <ProductDetailsContainer>
      <BadgeAndRecurringTreatsContainer>
        <Badge
          label={!status ? orderCopy.placed : status}
          variant={badgeStatusMapping[status.toLowerCase()] || 'default'}
        />
        <Typography variant="heading-compact-01">
          {orderCopy.recurringTreats}
        </Typography>
      </BadgeAndRecurringTreatsContainer>
      <ProductSpecifics {...props} />
    </ProductDetailsContainer>
  );
};

export default ProductDetails;
