import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';
import { promptShadow } from 'common/constants';

export const Header = styled.header`
    position: fixed;
    width: calc(100vw - 56px);
    z-index: 9999;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.background['ui-background']};
    gap: 8px;
    .logo {
        min-height: 40px;
        min-width: 76px;
    }
    .close-icon {
        height: 20px;
        min-width: 20px;
        cursor: pointer;
        transform: scale(1);
        transition: transform 0.2s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
    }
    h6 {
        text-align: center;
        font-size: 14px;
    }
    padding: 16px 28px;
    @media ${breakpoints.tablet} {
        h6 {
            font-size: 16px;
        }
        padding: 16px 80px;
        width: calc(100vw - 160px);
    }
    box-shadow: 0px 2px 4px rgba(30, 30, 30, 0.1);
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    transform: translate3d(0px,0px,0px);
`;

export const Prompt = styled.div`
    display: flex;
    max-width: 464px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background-color: white;
    border-radius: 6px;
    box-shadow: ${promptShadow};
    .button-container {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        width: 100%;
        button {
            width: 50%;
        }
    }
    .button-width {
        width: 50%;
    }
`;
export const PromptHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    svg {
        cursor: pointer;
        min-height: 20px;
        min-width: 20px;
        max-width: 20px;
        max-height: 20px;
        transform: scale(1);
        transition: transform 0.2s ease-in-out;
        &:hover{
            transform: scale(1.15);
        }
    }
`;

export const LoadingContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    .progress-bar {
        width: 100%;
        height: 20px;
        border-radius: 50px;
        background-color: #EBEAFD;
        border: 2px solid #AFABF8;
    }
    .progress {
        height: 100%;
        width: 0;
        border-radius: 50px;
        background-color: #AFABF8;
        transition: width 2m linear;
    }
`;

export const EmployeeErrorPrompt = styled.div`
    display: flex;
    width: calc(100% - 48px);
    max-width: 464px;
    padding: 0px 24px 24px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background-color: white;
    border-radius: 6px;
    .button-container {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        width: 100%;
        button {
            width: 50%;
        }
    }
`;
export const EmployeeErrorPromptHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    justify-content: flex-end;
    svg {
        cursor: pointer;
        min-height: 20px;
        min-width: 20px;
        max-width: 20px;
        max-height: 20px;
        transform: scale(1);
        transition: transform 0.2s ease-in-out;
        &:hover{
            transform: scale(1.15);
        }
    }
`;

export const EmployeeErrorPromptContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap : 24px;
    svg {
        width : 40px;
        heoght : 40px;
    }
`;
