import { mainDomainName } from 'common/constants';
export const useCookie = () => {
  let domainName = mainDomainName;
  if (window.location.host.includes('localhost')) {
    domainName = 'localhost';
  } else if (window.location.host.includes('herokuapp')) {
    domainName = window.location.host;
  }
  const setCookie = (name: string, value: string | boolean) => {
    const myDate = new Date();
    myDate.setHours(myDate.getHours() + 24);
    document.cookie = `${name}=${value};expires=${myDate
    };domain=${domainName};path=/`;
  };

  const expireCookie = (name: string, value: string | boolean = 'true') => {
    const myDate = 'Thu, 01 Jan 1970 00:00:01 GMT';
    document.cookie = `${name}=${value};expires=${myDate
    };domain=${domainName};path=/`;
  };

  const getCookie = (name: string) => {
    const escape = (s: string) => { return s.replace(/([.*+?^$(){}|[\]/\\])/g, '\\$1'); };
    const match = document.cookie.match(RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`));
    return match ? match[1] : null;
  };

  return { setCookie, expireCookie, getCookie };
};

export default useCookie;
