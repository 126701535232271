import { Dispatch, SetStateAction, useEffect } from 'react';
import Badge from 'common/designSystem/badge';
import lang from 'common/lang';
import { LineItems } from 'features/home/types';
import {
  ProductDetailsContainer,
} from './styles';
import ProductSpecifics from './productSpecifics';

export const ProductDetails = (props: LineItems & { setIsMultipleRecipients: Dispatch<SetStateAction<boolean>> }) => {
  const { order: orderCopy } = lang;
  const { recipients: multipleRecipients, setIsMultipleRecipients, status } = props;

  useEffect(() => {
    if (multipleRecipients) {
      setIsMultipleRecipients(true);
    }
  }, [multipleRecipients]);
  return (
    <ProductDetailsContainer>
      <Badge label={!status ? orderCopy.placed : status} />
      <ProductSpecifics {...props} />
    </ProductDetailsContainer>
  );
};

export default ProductDetails;
