import styled from 'styled-components';
import { promptShadow } from 'common/constants';

export const Prompt = styled.div`
    display: flex;
    width: calc(100% - 48px);
    max-width: 464px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background-color: white;
    border-radius: 6px;
    box-shadow: ${promptShadow};
    .button-container {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        width: 100%;
        button {
            width: 50%;
        }
    }
    .button-width {
        width: 50%;
    }
`;
export const PromptHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    svg {
        cursor: pointer;
        min-height: 20px;
        min-width: 20px;
        max-width: 20px;
        max-height: 20px;
        transform: scale(1);
        transition: transform 0.2s ease-in-out;
        &:hover{
            transform: scale(1.15);
        }
    }
`;
