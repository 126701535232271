/* eslint-disable no-unused-expressions */
import {
  useState, useMemo, ChangeEvent, useEffect,
} from 'react';

import lang from 'common/lang';
import { useUserDetails } from 'common/hooks';
import { generateFileName, truncatingLongLabels, validateFile } from 'common/utils/helpers';
import Bugsnag from '@bugsnag/js';
import { useGetPresignedUrlMutation } from './uploadFileService';
import Typography from '../typography';
import Button from '../button/button';
import { UploadFileProps } from './types';
import { UploadFileContainer } from './styles';
import { TrashIcon } from '../iconComponents';

export const useUploadFile = (props: UploadFileProps) => {
  const { components: componentsCopy } = lang;
  const { user } = useUserDetails();
  const {
    label = componentsCopy.uploadFile,
    id,
    existingSelectedFile = '',
    existingUploadedFile = '',
    existingFileObject = null,
  } = props;
  const [selectedFile, setSelectedFile] = useState(existingSelectedFile);
  const [uploadedFileName, setUploadedFileName] = useState(existingUploadedFile);
  const [fileObject, setFileObject] = useState<File | null>(existingFileObject);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [getPresignedUrl] = useGetPresignedUrlMutation();

  useEffect(() => {
    setSelectedFile(existingSelectedFile);
    setUploadedFileName(existingUploadedFile);
  }, [existingSelectedFile, existingUploadedFile]);

  const handleChange = (files: FileList | null) => {
    if (!files) {
      return;
    }
    if (files.length !== 0) {
      setIsLoading(true);
      setError('');
      const file = files[0];
      const errorMessage = validateFile(file);
      if (errorMessage) {
        setError(errorMessage);
        setIsLoading(false);
        return;
      }
      const newName = generateFileName(file.name, user?.id || 0);
      getPresignedUrl({ name: newName })
        .unwrap()
        .then(({ data }) => {
          const { formInputs } = data;
          const body = new FormData();
          formInputs && Object.keys(formInputs).map((key) => body.append(key, formInputs[key as keyof typeof formInputs]));
          body.append('file', file);
          body.append('Content-Type', file.type);
          fetch(data.formAttributes.action, { method: 'POST', body })
            .then(() => {
              setSelectedFile(file.name);
              setUploadedFileName(newName);
              setFileObject(file);
            })
            .catch(() => {
              setError(componentsCopy.failedToUpload);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }).catch(() => {
          setError(componentsCopy.failedToUpload);
          setIsLoading(false);
        });
    }
  };
  const resetSelection = () => {
    setSelectedFile('');
    setUploadedFileName('');
    setFileObject(null);
  };

  const UploadFile = useMemo(() => {
    const UploadFileComponent = () => {
      return (
        <UploadFileContainer>
          {!selectedFile
          && (

            <label htmlFor={id} data-cy="link-upload-file">
              <Typography variant="heading-compact-01">
                {label}
              </Typography>
              <div>
                <input
                  id={id}
                  type="file"
                  name={id}
                  style={{ display: 'none' }}
                  accept="application/pdf,image/png,image/jpeg,image/jpg"
                  onChange={(e:ChangeEvent<HTMLInputElement>) => handleChange(e.currentTarget.files)}
                />
              </div>
            </label>
          )}
          {selectedFile
        && (
          <Button
            label={truncatingLongLabels(selectedFile)}
            title={selectedFile}
            variant="outlined"
            size="small"
            iconRight={TrashIcon}
            onClick={resetSelection}
          />
        )}
        </UploadFileContainer>
      );
    };
    return UploadFileComponent;
  }, [selectedFile, existingSelectedFile, existingUploadedFile, existingFileObject]);

  return {
    UploadFile, selectedFile, isLoading, error, uploadedFileName, fileObject,
  };
};

export default useUploadFile;
