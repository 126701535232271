import lang from 'common/lang';
import { CgPlanType } from 'features/plans/types';
import { formatPlanDates } from 'common/utils/helpers';
import { useNavigate } from 'react-router-dom';
import { internalLinks } from 'common/constants';
import Button from '../button/button';
import { RecipientsIcon } from '../iconComponents';
import {
  CgPlansContainer, Summary, CgViewPlanDetailsContainer, IntervalContainer,
} from './styles';
import { CgSubscriptionHeader } from './cgSubscriptionHeader';
import { CgProductDetails } from './cgProductDetails';
import Typography from '../typography';

export const CgSubscription = (props: CgPlanType) => {
  const {
    createdAt, id, status, employeesCount,
  } = props;
  const { plans: plansCopy } = lang;
  const navigate = useNavigate();
  return (
    <CgPlansContainer>
      <CgSubscriptionHeader
        name={id.toString()}
        createdAt={formatPlanDates(createdAt)}
      />
      <Summary>
        <CgProductDetails key={id} {...props} status={status} />
      </Summary>
      <IntervalContainer>
        <RecipientsIcon />
        <Typography variant="heading-compact-01" className="recipient-count" onClick={() => navigate(`${internalLinks.corporateGiftingPlan}${id}${internalLinks.recipients}`)} data-cy="recipients-link">
          {employeesCount}
          {' '}
          {plansCopy.recipients}
        </Typography>
      </IntervalContainer>

      <CgViewPlanDetailsContainer>
        <Button
          label={plansCopy.viewPlanDetails}
          variant="primary"
          size="base"
          data-cy="button-view-plan-details"
          onClick={() => navigate(`${internalLinks.corporateGiftingPlan}${id}`)}
        />
      </CgViewPlanDetailsContainer>
    </CgPlansContainer>
  );
};

export default CgSubscription;
