/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import lang from 'common/lang';
import { OrderType } from 'features/home/types';
import Typography from 'common/designSystem/typography';
import {
  TotalPricingContainer, PricingSummaryItem, ShippingAddressContainer,
} from './styles';

export const PricingAndShipping = (props: OrderType & { isMultipleRecipients: boolean}) => {
  const {
    shippingCompany, shippingFirstName, shippingLastName = '',
    shippingAddress1, shippingAddress2, shippingCity,
    shippingPostcode, shippingCountry, shippingPhone, shipping,
    isMultipleRecipients, subtotalPrice, totalDiscounts, totalTax, totalPrice,
    shopifyOrderNumber,
  } = props;
  const { order: orderCopy } = lang;
  return (
    <>
      {!!shopifyOrderNumber && (
        <TotalPricingContainer>
          <PricingSummaryItem>
            <Typography variant="body-compact-02">
              {orderCopy.subtotal}
            </Typography>
            <Typography variant="body-compact-02">
              {`£${((subtotalPrice + totalDiscounts) / 100).toFixed(2)}`}
            </Typography>
          </PricingSummaryItem>
          {totalDiscounts > 0 && (
            <PricingSummaryItem>
              <Typography variant="body-compact-02">
                {orderCopy.discount}
              </Typography>
              <Typography variant="body-compact-02">
                {`-£${(totalDiscounts / 100).toFixed(2)}`}
              </Typography>
            </PricingSummaryItem>
          )}
          <PricingSummaryItem>
            <Typography variant="body-compact-02">
              {orderCopy.shipping}
            </Typography>
            <Typography variant="body-compact-02">
              {`£${(shipping / 100).toFixed(2)}`}
            </Typography>
          </PricingSummaryItem>
          <PricingSummaryItem>
            <Typography variant="body-compact-02">
              {orderCopy.vat}
            </Typography>
            <Typography variant="body-compact-02">
              {`£${(totalTax / 100).toFixed(2)}`}
            </Typography>
          </PricingSummaryItem>
          <PricingSummaryItem>
            <Typography variant="body-compact-02">
              {orderCopy.total}
            </Typography>
            <Typography variant="heading-03">
              {`£${(totalPrice / 100).toFixed(2)}`}
            </Typography>
          </PricingSummaryItem>
        </TotalPricingContainer>
      )}
      <ShippingAddressContainer>
        <Typography variant="heading-compact-02">
          {orderCopy.shippingAddress}
        </Typography>
        {!isMultipleRecipients && (
          <>
            <Typography variant="body-compact-01">
              {`${shippingFirstName}${shippingLastName ? ` ${shippingLastName}` : ''}`}
            </Typography>
            <Typography variant="body-compact-01">
              {`${shippingCompany ? `${shippingCompany},` : ''}
                ${shippingAddress1 ? `${shippingAddress1},` : ''}
                ${shippingAddress2 ? `${shippingAddress2},` : ''}
                ${shippingCity ? `${shippingCity},` : ''}
                ${shippingPostcode ? `${shippingPostcode},` : ''}
                ${shippingCountry ? `${shippingCountry}` : ''}
              `}
            </Typography>
            <Typography variant="body-compact-01">
              {shippingPhone || ''}
            </Typography>
          </>
        )}
        {isMultipleRecipients && (
          <Typography variant="body-compact-01">
            {orderCopy.multipleRecipientsShipping}
          </Typography>
        )}
      </ShippingAddressContainer>
    </>
  );
};

export default PricingAndShipping;
