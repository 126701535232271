/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortPlansAndOrders } from 'common/utils/helpers';
import { EmployeeDetails } from 'features/gifting/types';
import { useGetCgPlanDetailsQuery } from '../plansService';
import { CgPlanType } from '../types';
type CgPlanDetailsType = {
  id: number | string;
}
export const useCgPlanDetails = (props: CgPlanDetailsType) => {
  const { id } = props;
  const {
    data, isLoading: isCgPlansLoading, isError, refetch: refetchCgPlanDetails,
  } = useGetCgPlanDetailsQuery({ id });
  const singleSub = data && data[0] ? data[0] : null;
  const [cgPlanDetails, setCgPlanDetails] = useState<CgPlanType | null>(null);
  const ordersList = singleSub?.orders ? [...singleSub.orders].sort(sortPlansAndOrders) : [];
  const [statusLoading, setStatusLoading] = useState(false);
  const [employees, setEmployees] = useState<EmployeeDetails[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      navigate('/plans');
    }
  }, [isError]);
  useEffect(() => {
    if (singleSub) {
      setCgPlanDetails({ ...singleSub });
    }
  }, [singleSub]);

  useEffect(() => {
    if (data && data[0]) {
      setEmployees(data[0].employees);
    }
  }, [data]);

  return {
    cgPlanDetails,
    ordersList,
    isCgPlansLoading,
    statusLoading,
    setStatusLoading,
    employees,
    refetchCgPlanDetails,
  };
};

export default useCgPlanDetails;
