import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ConnectionDetails,
  EmployeeMap,
  GiftingState, PostCardDesign, ShopifyProduct, VariantOption, GiftingPostCardOption,
} from 'features/gifting/types';
import lang from 'common/lang';
import { AlertProps } from 'common/designSystem/alert/types';

const { gifting: giftingCopy } = lang;
const giftingSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    selectedStep: 1,
    selectedProduct: null,
    selectedImageProduct: null,
    direction: 0,
    variantSelection: {},
    postCardDesign: {},
    planType: '',
    postCardOptionType: '',
    anniverSaryCelebrationFrequency: '',
    customMessage: giftingCopy.defaultCustomMessage,
    connectionDetails: null,
    employeesLoaded: false,
    employeeLoadingError: false,
    employeeMap: {},
    selectedEmployees: new Set<number>(),
    pricePerBox: 0,
    taxable: false,
    alertState: {
      show: false,
      type: 'success',
      title: '',
      content: '',
      variant: 'floating',
    },
    planCreated: false,
    createdPlanId: null,
    openEligibleEmployeeModal: false,
    openGdprModal: false,
    showCgSignUpModal: false,
  } as GiftingState,
  reducers: {
    setIsLoading: (
      state,
      { payload: { status } }: PayloadAction<{ status: boolean }>,
    ) => {
      state.isLoading = status;
    },
    setPlanType: (
      state,
      { payload: { planType } }: PayloadAction<{ planType: string }>,
    ) => {
      state.planType = planType;
    },
    setPostCardOptionType: (
      state,
      { payload: { postCardOptionType } }: PayloadAction<{ postCardOptionType: string }>,
    ) => {
      if (postCardOptionType === GiftingPostCardOption.Text) {
        state.postCardOptionType = GiftingPostCardOption.Text;
      } else if (postCardOptionType === GiftingPostCardOption.Design) {
        state.postCardOptionType = GiftingPostCardOption.Design;
      } else {
        state.postCardOptionType = '';
      }
    },
    setAnniverSaryCelebrationFrequency: (
      state,
      { payload: { celebrationFrequency } }: PayloadAction<{ celebrationFrequency: string }>,
    ) => {
      state.anniverSaryCelebrationFrequency = celebrationFrequency;
    },
    setCustomMessage: (
      state,
      { payload: message }: PayloadAction<string>,
    ) => {
      state.customMessage = message;
    },
    setSelectedStep: (
      state,
      { payload: { step } }: PayloadAction<{ step: number }>,
    ) => {
      state.selectedStep = step;
    },
    setSelectedProduct: (
      state,
      { payload: { product } }: PayloadAction<{ product: ShopifyProduct | null }>,
    ) => {
      state.selectedProduct = product;
      state.isLoading = false;
      state.selectedStep = 1;
      state.direction = 0;
      state.variantSelection = {};
      state.postCardDesign = {};
      state.planType = '';
      state.anniverSaryCelebrationFrequency = '';
      state.postCardOptionType = '';
      state.customMessage = giftingCopy.defaultCustomMessage;
      state.openEligibleEmployeeModal = false;
      state.openGdprModal = false;
      state.showCgSignUpModal = false;
    },
    setSelectedImage: (
      state,
      { payload: { product } }: PayloadAction<{ product: ShopifyProduct | null }>,
    ) => {
      state.selectedImageProduct = product;
    },
    setDirection: (
      state,
      { payload: { direction } }: PayloadAction<{ direction: number }>,
    ) => {
      state.direction = direction;
    },
    setVariantSelection: (
      state,
      { payload: { option, selection } }: PayloadAction<{ option: string, selection: VariantOption}>,
    ) => {
      state.variantSelection[option] = { ...selection };
    },
    setCustomPostcardDesign: (
      state,
      { payload: { selection } }: PayloadAction<{ selection: PostCardDesign }>,
    ) => {
      state.postCardDesign = { ...selection };
    },

    setConnectionDetails: (
      state,
      { payload: { connectionDetails } }: PayloadAction<{ connectionDetails: ConnectionDetails }>,
    ) => {
      state.connectionDetails = { ...connectionDetails };
    },
    setEmployeesLoaded: (
      state,
      { payload: status }: PayloadAction<boolean>,
    ) => {
      state.employeesLoaded = status;
    },
    setEmployeeLoadingError: (
      state,
      { payload: isError }: PayloadAction<boolean>,
    ) => {
      state.employeeLoadingError = isError;
    },
    setEmployeeMap: (
      state,
      { payload: employeeMap }: PayloadAction<EmployeeMap>,
    ) => {
      state.employeeMap = { ...employeeMap };
    },
    setSelectedEmployees: (
      state,
      { payload: selectedEmployees }: PayloadAction<Set<number>>,
    ) => {
      state.selectedEmployees = new Set(selectedEmployees);
    },
    setPricePerBox: (
      state,
      { payload: pricePerBox }: PayloadAction<number>,
    ) => {
      state.pricePerBox = pricePerBox;
    },
    setTaxable: (
      state,
      { payload: taxable }: PayloadAction<boolean>,
    ) => {
      state.taxable = taxable;
    },
    setAlertState: (
      state,
      { payload: alertState }: PayloadAction<AlertProps>,
    ) => {
      state.alertState = { ...alertState };
    },
    setPlanCreated: (
      state,
      { payload: status }: PayloadAction<boolean>,
    ) => {
      state.planCreated = status;
    },
    setCreatedPlanId: (
      state,
      { payload: id }: PayloadAction<number>,
    ) => {
      state.createdPlanId = id;
    },
    setOpenEligibleEmployeeModal: (
      state,
      { payload: openMOdal }: PayloadAction<boolean>,
    ) => {
      state.openEligibleEmployeeModal = openMOdal;
    },
    setOpenGdprModal: (
      state,
      { payload: openGdprMOdal }: PayloadAction<boolean>,
    ) => {
      state.openGdprModal = openGdprMOdal;
    },
    setShowCgSignUpModal: (
      state,
      { payload: { showCgSignUpModal } }: PayloadAction<{ showCgSignUpModal: boolean }>,
    ) => {
      state.showCgSignUpModal = showCgSignUpModal;
    },
    resetGiftingState: (
      state,
    ) => {
      state.isLoading = false;
      state.selectedStep = 1;
      state.selectedProduct = null;
      state.direction = 0;
      state.variantSelection = {};
      state.planType = '';
      state.anniverSaryCelebrationFrequency = '';
      state.customMessage = giftingCopy.defaultCustomMessage;
      state.postCardDesign = {};
      state.connectionDetails = null;
      state.employeesLoaded = false;
      state.employeeLoadingError = false;
      state.employeeMap = {};
      state.selectedEmployees = new Set<number>();
      state.pricePerBox = 0;
      state.taxable = false;
      state.alertState = {
        show: false,
        type: 'success',
        title: '',
        content: '',
        variant: 'floating',
      };
      state.planCreated = false;
      state.createdPlanId = null;
      state.openEligibleEmployeeModal = false;
      state.showCgSignUpModal = false;
    },
  },
});

export const {
  setSelectedStep, setSelectedProduct, setDirection,
  resetGiftingState, setVariantSelection, setIsLoading,
  setCustomMessage, setConnectionDetails, setEmployeesLoaded,
  setEmployeeMap, setSelectedEmployees, setPricePerBox, setTaxable,
  setAlertState, setPlanCreated, setCreatedPlanId, setCustomPostcardDesign, setPlanType,
  setAnniverSaryCelebrationFrequency, setOpenEligibleEmployeeModal, setOpenGdprModal, setSelectedImage,
  setEmployeeLoadingError, setPostCardOptionType, setShowCgSignUpModal,
} = giftingSlice.actions;

export default giftingSlice.reducer;
