/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef, useState } from 'react';
import {
  AuthLayout, Button, Loader, Search, Typography, useAlert, useModal,
} from 'common/designSystem';
import { ChevronRight, CloseIcon, RecipientsIcon } from 'common/designSystem/iconComponents';
import lang from 'common/lang';
import { TableContainer, TableHeader, TableRow } from 'common/designSystem/recipientsTable';
import { RecipientRowProps } from 'common/designSystem/recipientsTable/types';
import { TableContainerOuter, TableRowsContainer, TableShadowAndBorder } from 'common/designSystem/recipientsTable/styles';
import Spinner from 'common/designSystem/loader/spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import QuestionMarkCircleIcon from 'common/designSystem/iconComponents/QuestionMarkCircleIcon';
import theme from 'common/theme/theme';
import { Prompt } from 'common/styles';
import { internalLinks } from 'common/constants';
import {
  CountAndSearch, EmployeeCount, EmptyResultsContainer,
  SpinnerContainer, GiftingContentContainer,
  BreadCrumpContainer, RecipientsContentContainer, ViewRecipentAndSearchBoxContainer, BannerContent, SucessBannerContainer, EligibleEmployeeModalLink,
} from './styles';
import { useRecipientDetails } from './useRecipientDetails';
import { PromptHeader } from '../styles';
import { PlanStatus } from '../types';

export const CgSelectRecipients = () => {
  const { StaticAlert } = useAlert();
  const { id } = useParams();
  const navigate = useNavigate();
  const { Modal: EligibleEmployeeModal, setModalOpen: setEligibleEmployeeModalOpen } = useModal();
  const {
    employees, newEmployees, renderedEmployees, keyword, filtering,
    isSorting, isAscending, handleSortAscending, handleSortDescending, setKeyword, handleSelectAll,
    handleDeselectAll, handleRowCheckboxClick, isAllSelected, updateSingleEmployeeSelection, selectedEmployees,
    getNextPaymentDue, getAnnualCost, cgPlanDetails, handleUpdateEmployeeForCgPlan, showSuccessBanner, loading, editRecipientsForNewEmployee,
  } = useRecipientDetails();
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const { gifting: giftingCopy, plans: plansCopy } = lang;
  const tableRef = useRef<HTMLDivElement | null>(null);
  const tableRowsRef = useRef<HTMLDivElement | null>(null);

  const checkForScroll = () => {
    const tableHeaderNameEl = document.querySelector('.recipient-table-header-name');
    if (tableRef && tableRef.current && tableRef.current?.scrollLeft > 0) {
      tableHeaderNameEl?.classList?.add('shadowed');
      tableRowsRef?.current?.classList.add('column-shadowed');
    }
    if (tableRef && tableRef.current && tableRef.current?.scrollLeft === 0) {
      tableHeaderNameEl?.classList?.remove('shadowed');
      tableRowsRef?.current?.classList.remove('column-shadowed');
    }
  };

  useEffect(() => {
    if (tableRef?.current) {
      tableRef.current.addEventListener('scroll', checkForScroll);
    }
    return (() => {
      if (tableRef?.current) {
        tableRef.current.removeEventListener('scroll', checkForScroll);
      }
    });
  }, []);

  useEffect(() => {
    if (newEmployees.length > 0) {
      setShowCheckbox(true);
    }
  }, []);

  const handleUpdatePlanClick = () => {
    if (selectedEmployees.size > 0) {
      handleUpdateEmployeeForCgPlan();
    }
    setShowCheckbox(false);
  };

  const handleEditRecipient = () => {
    setShowCheckbox(true);
    if (newEmployees.length > 0) {
      editRecipientsForNewEmployee();
    } else {
      handleSelectAll();
    }
  };

  const handleCancelClick = () => {
    setShowCheckbox(false);
    setIsCancelClicked(true);
  };

  const showNoOfRecipient = () => {
    const employeeCount = employees.length + (newEmployees.length > 0 ? newEmployees.length : 0);
    const recipientCount = selectedEmployees.size !== 1 ? selectedEmployees.size : renderedEmployees.length;
    const recipientText = selectedEmployees.size !== 1 ? giftingCopy.recipientsLower : giftingCopy.singleRecipient;
    const text = `${recipientCount} ${recipientText} ${employeeCount} ${giftingCopy.employees}`;

    return text;
  };

  return (
    <>
      <AuthLayout>
        {loading && <Loader />}
        <GiftingContentContainer>
          <RecipientsContentContainer>
            <SucessBannerContainer>
              {showSuccessBanner && (
                <StaticAlert
                  title=""
                  show={showSuccessBanner}
                  type="success"
                  content={plansCopy.apiSuccess}
                />
              )}
            </SucessBannerContainer>
            <BreadCrumpContainer>
              <Typography variant="heading-compact-02" className="my-plans-text" data-cy="link-my-plans" onClick={() => navigate(internalLinks.plans)}>{plansCopy.myPlans}</Typography>
              <ChevronRight />
              <Typography variant="heading-compact-02" className="my-plans-text" data-cy={`link-to-plan-${id}`} onClick={() => navigate(`${internalLinks.corporateGiftingPlan}${id}`)}>{`${plansCopy.plan} #${id}`}</Typography>
              <ChevronRight />
              <Typography variant="heading-compact-02" className="plan-with-id">{`${plansCopy.breadcrumpbRecipents}`}</Typography>
            </BreadCrumpContainer>
            <CountAndSearch>
              <EmployeeCount>
                <RecipientsIcon />
                <Typography variant="heading-compact-02">
                  {showNoOfRecipient()}
                </Typography>
                <Typography variant="heading-compact-02">
                </Typography>
              </EmployeeCount>
              <ViewRecipentAndSearchBoxContainer>
                {(!showCheckbox || selectedEmployees.size === 0) && cgPlanDetails?.status !== PlanStatus.cancelled && (
                  <Button
                    label={plansCopy.editRecipients}
                    variant="outlined"
                    className="back-button"
                    data-cy="button-edit-recipients"
                    onClick={handleEditRecipient}
                  />
                )}
                <Search
                  id={giftingCopy.giftingSearch}
                  placeholder={giftingCopy.searchByName}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onClear={() => setKeyword('')}
                />
              </ViewRecipentAndSearchBoxContainer>
            </CountAndSearch>
            <AnimatePresence>
              {(showCheckbox || selectedEmployees.size === 0) && (
                <motion.div
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 100 }}
                  transition={{ duration: 0.5, ease: 'easeIn' }}
                >
                  <StaticAlert
                    title=""
                    show={(showCheckbox || selectedEmployees.size === 0)}
                    type="info"
                    content={(
                      <BannerContent>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <Typography variant="body-01" noMargin>
                              {giftingCopy.paymentForecast1}
                            </Typography>
                            <Typography variant="heading-compact-01">
                              {` £${(+getNextPaymentDue / 100).toFixed(2)} `}
                            </Typography>
                            <Typography variant="body-01" noMargin>
                              {giftingCopy.paymentForecast2}
                            </Typography>
                            <Typography variant="heading-compact-01">
                              {` £${getAnnualCost} `}
                            </Typography>

                            <Typography variant="body-01" noMargin>
                              {giftingCopy.paymentForecast3}
                            </Typography>
                            <Typography variant="heading-compact-01">
                              {` £${(((cgPlanDetails?.perBoxPrice ?? 0) * (cgPlanDetails?.taxable ? 1.2 : 1)) / 100)?.toFixed(2)} `}
                            </Typography>
                            <Typography variant="body-01" noMargin>
                              {giftingCopy.paymentForecast4}
                            </Typography>
                          </div>
                          <div
                            className="button-container"
                          >
                            <Button
                              label={plansCopy.updatePlan}
                              variant="primary"
                              className="update-plan-button"
                              size="base"
                              data-cy="button-update-plan"
                              onClick={handleUpdatePlanClick}
                            />
                            <Button
                              label={plansCopy.cancel}
                              variant="outlined"
                              size="base"
                              className="cancel-button"
                              data-cy="button-cancel"
                              onClick={handleCancelClick}
                            />
                          </div>
                        </div>
                      </BannerContent>
                    )}
                    style={{ marginBottom: '16px' }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: 0, x: 0 }}
              animate={{ opacity: 1, y: 0, x: 0 }}
              transition={{ duration: 0.3, ease: 'easeIn' }}
            >
              <TableContainerOuter ref={tableRef}>
                <TableContainer>
                  <TableShadowAndBorder />
                  <TableHeader
                    isSorting={isSorting}
                    isAscending={isAscending}
                    onSortAscending={handleSortAscending}
                    onSortDescending={handleSortDescending}
                    showCheckbox={showCheckbox}
                    onSelectAll={handleSelectAll}
                    onDeselectAll={handleDeselectAll}
                    isAllSelected={isAllSelected}
                  />
                  <TableRowsContainer ref={tableRowsRef}>
                    {renderedEmployees.map((recipient: RecipientRowProps) => (
                      <TableRow
                        {...recipient}
                        updateSingleEmployeeSelection={(empId, isChecked) => updateSingleEmployeeSelection(empId, isChecked)}
                        key={recipient.id}
                        onCheckboxClick={handleRowCheckboxClick}
                        showCheckbox={showCheckbox}
                        selectedEmployees={selectedEmployees}
                        employmentStatus={recipient.employmentStatus}
                        isFromEditRecipients={true}
                        isNewEmployee={false}
                        isCancelClicked={isCancelClicked}
                      />
                    ))}
                    {newEmployees.map((recipient: RecipientRowProps) => (
                      <TableRow
                        {...recipient}
                        updateSingleEmployeeSelection={(empId, isChecked) => updateSingleEmployeeSelection(empId, isChecked)}
                        key={recipient.id}
                        onCheckboxClick={handleRowCheckboxClick}
                        showCheckbox={showCheckbox}
                        selectedEmployees={selectedEmployees}
                        employmentStatus={recipient.employmentStatus}
                        isFromEditRecipients={true}
                        isNewEmployee={true}
                        isCancelClicked={isCancelClicked}
                      />
                    ))}
                  </TableRowsContainer>
                </TableContainer>
                {(filtering || isSorting) && (
                  <SpinnerContainer>
                    <Spinner />
                  </SpinnerContainer>
                )}
                {(!filtering && employees.length !== 0 && renderedEmployees.length === 0 && newEmployees.length === 0) && (
                  <EmptyResultsContainer>
                    <Typography variant="body-compact-01" style={{ marginTop: '82px' }}>
                      {giftingCopy.noSearchResults}
                    </Typography>
                  </EmptyResultsContainer>
                )}
              </TableContainerOuter>
              <EligibleEmployeeModalLink onClick={() => setEligibleEmployeeModalOpen(true)}>
                <QuestionMarkCircleIcon />
                <Typography variant="heading-compact-01" color={theme.palette.link['link-01']} data-cy="eligible-employee-modal-link">
                  {giftingCopy.eligibleEmployeeModalLink}
                </Typography>
              </EligibleEmployeeModalLink>
            </motion.div>
          </RecipientsContentContainer>
        </GiftingContentContainer>
      </AuthLayout>
      <EligibleEmployeeModal data-cy="eligible-employee-modal-recipient">
        <Prompt>
          <PromptHeader>
            <Typography variant="heading-03">
              {giftingCopy.eligibleEmployeeModalHeader}
            </Typography>
            <CloseIcon onClick={() => setEligibleEmployeeModalOpen(false)} data-cy="close-eligible-employee-modal" />
          </PromptHeader>
          <Typography variant="body-compact-02" data-cy="eligible-employee-modal-info-text1">
            {giftingCopy.eligibleEmployeeModalText1}
          </Typography>
          <Typography variant="body-compact-02" data-cy="eligible-employee-modal-info-text2">
            {giftingCopy.eligibleEmployeeModalText2}
          </Typography>
          <Typography variant="body-compact-02" data-cy="eligible-employee-modal-info-text3">
            {giftingCopy.eligibleEmployeeModalText3}
          </Typography>
          <div className="button-container button-width">
            <Button
              label={giftingCopy.gotIt}
              variant="outlined"
              onClick={() => setEligibleEmployeeModalOpen(false)}
              data-cy="got-it-button"
            />
          </div>
        </Prompt>
      </EligibleEmployeeModal>

    </>
  );
};

export default CgSelectRecipients;
