import theme from 'common/theme/theme';
import './styles.scss';
import type { LabelledCheckboxProps } from './types';
import Typography from '../typography';
export const CheckboxField = ({
  labelText, field, error = false, errorMessage = '',
}: LabelledCheckboxProps) => {
  return (
    <label className="checkbox-container" htmlFor={field.name}>
      <div className="checkbox-content">
        {labelText}
        {error && errorMessage && (
          <Typography
            className="helper-text"
            variant="helper-text-01"
            color={theme.palette.support['support-01']}
            data-cy={`${field?.name}-error`}
          >
            {errorMessage}
          </Typography>
        )}
      </div>
      <input className="checkbox" type="checkbox" id={field.name} {...field} checked={field.value || false} />
      <span className="checkspan" data-cy={`${field?.name}-checkbox`} />
    </label>
  );
};

export default CheckboxField;
