import * as React from 'react';
import { SVGProps } from 'react';
const SvgFacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26 16c0-5.523-4.477-10-10-10S6 10.477 6 16c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V16h2.54v-2.203c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V16h2.773l-.443 2.89h-2.33v6.988C22.343 25.128 26 20.991 26 16Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFacebookIcon;
