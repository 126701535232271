import { memo, useState, useEffect } from 'react';
import lang from 'common/lang';
import { useCookie, useGiftingDetails } from 'common/hooks';
import { useAppSelector } from 'common/hooks/state';
import { authCookieName } from 'common/constants';
import { isEmpty } from 'lodash';
import { RootState } from 'common/store/types';
import { convertObjectFieldNamesFromCamelCaseToSnakeCase, getPlanBillingStartDate } from 'common/utils/helpers';
import { useCreateCGplanDraftsMutation } from 'features/gifting/giftingSevice';
import Bugsnag from '@bugsnag/js';
import PlanSummary from '../../../features/gifting/planSummary';
import { Button } from '../button/button';
import {
  Footer, FooterPlanSummaryContainer, PlanSummaryIndicatorContainer, PlanSummaryInnerContainer,
} from './styles';
import { GiftingFooterProps, STEPS } from './types';
import Typography from '../typography';
import { ChevronDown } from '../iconComponents';
export const GiftingFooter = (props: GiftingFooterProps) => {
  const [showSummary, setShowSummary] = useState(false);
  const {
    selectedStep, setStep, allowNextForStep, planType, selectedProduct, updateShowSignUPModal, variantSelection, customMessage, postCardOptionType, postCardDesign,
  } = useGiftingDetails();
  const [createCGPlanDrafts] = useCreateCGplanDraftsMutation();
  const { lastStep } = props;
  const { gifting: giftingCopy } = lang;
  const { getCookie } = useCookie();
  const isAuthenticated = (): boolean => {
    const token = useAppSelector((state: RootState) => state.auth.token);
    return !isEmpty(token);
  };
  const isLoggedIn = isAuthenticated() && getCookie(authCookieName);
  const handleBack = () => {
    setStep(selectedStep - 1);
  };

  const handleNext = () => {
    const createSubscriptionDTODraft = {
      selectedProduct,
      planType,
      planStart: getPlanBillingStartDate(),
      variantSelection,
      postCardOptionType,
      postCardDesign,
      postcardMessage: customMessage,
    };

    if (!isLoggedIn && selectedStep === STEPS.plan) {
      updateShowSignUPModal(true);
    } else {
      const payload = createSubscriptionDTODraft;
      if (payload && selectedStep === STEPS.plan) {
        const formattedPayload = convertObjectFieldNamesFromCamelCaseToSnakeCase({ data: { ...payload } });
        createCGPlanDrafts(formattedPayload).unwrap()
          .catch((err) => {
            console.error('err', err);
          });
      }
      setStep(selectedStep + 1);
    }
  };
  useEffect(() => {
    setShowSummary(false);
  }, [selectedStep]);
  return (
    <>
      {(selectedStep === STEPS.plan || selectedStep === STEPS.hrIntegration || selectedStep === STEPS.payment) && (
        <FooterPlanSummaryContainer className={showSummary ? 'show' : ''}>
          <PlanSummaryInnerContainer data-cy="plan-summary-mobile">
            <PlanSummary
              title={selectedProduct?.title || ''}
              price={selectedProduct?.variants[0]?.price || ''}
              planType={planType}
              imageSrc={selectedProduct?.image.src || ''}
            />
          </PlanSummaryInnerContainer>
          <PlanSummaryIndicatorContainer className={showSummary ? 'flip' : ''} onClick={() => setShowSummary(!showSummary)}>
            <Typography variant="heading-01" data-cy="plan-summary">
              {giftingCopy.planSummary}
            </Typography>
            <ChevronDown />
          </PlanSummaryIndicatorContainer>
        </FooterPlanSummaryContainer>
      )}
      <Footer>
        {selectedStep === STEPS.treat
          ? <div></div>
          : (
            <Button
              label={giftingCopy.back}
              variant="outlined"
              onClick={handleBack}
              data-cy="gifting-back-button"
              className={selectedStep === lastStep ? 'button-right-margin' : ''}
            />
          )}
        {selectedStep !== lastStep && (
          <Button
            label={giftingCopy.next}
            variant="primary"
            onClick={handleNext}
            disabled={!allowNextForStep(selectedStep)}
            data-cy="gifting-next-button"
            className="button-right-margin"
          />
        )}
      </Footer>
    </>
  );
};

export default memo(GiftingFooter);
