import * as React from 'react';
import { SVGProps } from 'react';
const SvgLetterBoxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 19 18" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.5 2.813v12.374M2.187 9h14.626M5.28 12.938A3.656 3.656 0 0 0 8.938 9.28V9m4.78 3.938a3.656 3.656 0 0 1-3.655-3.657V9m-6.75 6.188h12.374c.622 0 1.126-.504 1.126-1.126V3.938c0-.622-.504-1.126-1.125-1.126H3.312c-.62 0-1.124.504-1.124 1.126v10.124c0 .622.503 1.126 1.124 1.126Zm9.465-7.08c-1.056 1.057-3.182.796-3.182.796s-.26-2.126.796-3.182a1.687 1.687 0 1 1 2.386 2.386ZM8.58 5.722c1.056 1.056.795 3.182.795 3.182s-2.125.26-3.182-.796A1.688 1.688 0 0 1 8.58 5.722Z"
    />
  </svg>
);
export default SvgLetterBoxIcon;
