import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import lang from 'common/lang';
import { externalLinks, navBoxShadow, shopifyUrl } from 'common/constants';
import { useOnClickOutside } from 'common/hooks';
import accountcreationprompt from 'assets/images/account_creation_prompt.jpg';
import { openExternalLink } from 'common/utils/helpers';
import {
  Logo, UserIcon, HamburgerIcon, CloseIcon, SparkleIcon, ChevronDown,
} from '../iconComponents';
import { Button } from '../button/button';
import Typography from '../typography';
import {
  Header, Menu, MenuOptions, MenuOption,
  LogoAndLinks, Links, MobileMenu, Overlay, MobileMenuContent, MenuIconContainer, LogoIcon,
} from './styles';
import MegaMenu from '../megaMenu';
import MobileMegaMenu from '../megaMenu/mobileMegaMenu';
export const UnauthHeader = () => {
  const { header: headerCopy } = lang;
  const headerRef = useRef<HTMLHeadElement | null>(null);
  const menuOptionsRef = useRef<HTMLDivElement | null>(null);
  const prevScollPosition = useRef<number>(window.pageYOffset);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [isMenuAccessed, setIsMenuAccessed] = useState(false);
  const megaMenuRef = useRef<HTMLDivElement | null>(null);
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);

  useEffect(() => {
    if (headerRef && headerRef?.current) {
      window.addEventListener('scroll', dropShadow);
      window.addEventListener('scroll', hideNav);
    }
    return (() => {
      window.removeEventListener('scroll', dropShadow);
      window.removeEventListener('scroll', hideNav);
    });
  }, []);

  const dropShadow = () => {
    if (headerRef && headerRef.current) {
      if (window.pageYOffset > 10) {
        headerRef.current.style.boxShadow = navBoxShadow;
        return;
      }
      headerRef.current.style.boxShadow = 'none';
    }
  };

  const hideNav = () => {
    setMenuOpen(false);
    if (headerRef && headerRef.current) {
      if (window.pageYOffset > 64) {
        const currentScrollPos = window.pageYOffset;
        if (prevScollPosition.current > currentScrollPos) {
          headerRef.current.style.transform = 'translate3d(0px, 0px, 0px)';
          headerRef.current.classList.add('header-visible');
          prevScollPosition.current = currentScrollPos;
          return;
        }
        headerRef.current.style.transform = 'translate3d(0px, -74px, 0px)';
        headerRef.current.classList.remove('header-visible');
        prevScollPosition.current = currentScrollPos;
      }
    }
  };
  const toggleMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setMenuOpen(!menuOpen);
    setIsMenuAccessed(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleMegaMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setMegaMenuOpen(!megaMenuOpen);
  };

  const closeMegaMenu = () => {
    setMegaMenuOpen(false);
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [mobileMenuOpen]);
  useOnClickOutside(menuOptionsRef, closeMenu);
  useOnClickOutside(megaMenuRef, closeMegaMenu);
  return (
    <Header ref={headerRef} className="header-visible auth-header">
      <MobileMenuContent className={`${mobileMenuOpen ? 'hide' : ''}`} data-cy="mobile-shop-link">
        <MobileMegaMenu />
      </MobileMenuContent>
      <Overlay className={`${mobileMenuOpen ? '' : 'hide'}`} onClick={handleMobileMenuToggle} />
      <LogoAndLinks>
        <MobileMenu>
          <HamburgerIcon className={`${mobileMenuOpen ? 'hide' : 'show'}`} onClick={handleMobileMenuToggle} />
          <CloseIcon className={`${mobileMenuOpen ? 'show' : 'hide'}`} onClick={handleMobileMenuToggle} />
        </MobileMenu>
        <LogoIcon onClick={() => openExternalLink(shopifyUrl)}>
          <Logo />
        </LogoIcon>
        <Links $isOpen={megaMenuOpen} onClick={(e) => toggleMegaMenu(e)} ref={megaMenuRef} data-cy="browse-treat-link">
          <Typography variant="heading-compact-01" onClick={() => setMegaMenuOpen(true)}>
            {headerCopy.browseTreats}
            {' '}
            <ChevronDown />
          </Typography>
          <MegaMenu megaMenuOpen={megaMenuOpen} />
        </Links>
        <Links onClick={() => openExternalLink(externalLinks.corporateGiftingRegisterLink)} data-cy="automated-gifting-link">
          <Typography variant="heading-compact-01">
            {headerCopy.automatedGifting}
          </Typography>
        </Links>
      </LogoAndLinks>
      <Menu $isOpen={menuOpen} onClick={(e) => toggleMenu(e)} ref={menuOptionsRef}>
        <MenuIconContainer>
          <div className="menu-container">
            {!menuOpen && !isMenuAccessed ? (
              <>
                <div className="menu-icon user-icon"><UserIcon data-cy="user-icon" /></div>
                <div className="menu-icon sparkle-icon"><SparkleIcon data-cy="sparkle-icon" /></div>
              </>
            ) : (<div className="menu-icon-for-user "><UserIcon data-cy="user-icon" /></div>) }
          </div>
        </MenuIconContainer>
        <MenuOptions $isOpen={menuOpen}>
          <img src={accountcreationprompt} draggable="false" alt="account_creation_prompt" />

          <MenuOption>
            <Button
              label={headerCopy.signup}
              variant="primary"
              size="medium"
              onClick={() => navigate('/account/signup')}
              data-cy="header-signup-button"
            />
          </MenuOption>
          <MenuOption>
            <Button
              label={headerCopy.login}
              variant="outlined"
              size="medium"
              onClick={() => navigate('/account/signin')}
              data-cy="header-login-button"
            />

          </MenuOption>
        </MenuOptions>
      </Menu>
    </Header>
  );
};

export default UnauthHeader;
