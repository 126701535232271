import { LineItems } from 'features/home/types';
import { ProductSpecificsContainer } from './styles';
import ProductData from './productData';
export const ProductSpecifics = (props: LineItems) => {
  return (
    <ProductSpecificsContainer>
      <ProductData {...props} />
    </ProductSpecificsContainer>
  );
};

export default ProductSpecifics;
