import { useEffect, useState } from 'react';
import lang from 'common/lang';
import { SortingFieldName } from 'features/gifting/types';
import { ArrowDownIcon } from '../iconComponents';
import useUnlabelledCheckbox from '../unlabelledCheckbox/useUnlabelledCheckbox';
import { TableHead, HeaderElement, NameHeadElement } from './styles';
import Typography from '../typography';
import { HeaderProps } from './types';

export const TableHeader = (props: HeaderProps) => {
  const {
    isSorting = false,
    isAscending = true,
    onSortAscending = (field? : string) => null,
    onSortDescending = (field? : string) => null,
    onSelectAll = () => null,
    onDeselectAll = () => null,
    isAllSelected = false,
    showCheckbox = true,
  } = props;

  const { gifting: giftingCopy } = lang;
  const { isChecked, setIsChecked, UnlabelledCheckbox } = useUnlabelledCheckbox();
  const [sortingOrder, setSortingOrder] = useState('name');
  const [activeField, setActiveField] = useState('name');

  useEffect(() => {
    onSortAscending(SortingFieldName.Name);
    setActiveField(SortingFieldName.Name);
  }, []);

  useEffect(() => {
    if (isAllSelected && !isChecked) {
      setIsChecked(true);
      return;
    }
    if (!isAllSelected && isChecked) {
      setIsChecked(false);
    }
  }, [isAllSelected]);

  const handleCheckboxClick = () => {
    isChecked ? onDeselectAll() : onSelectAll();
  };

  const handleSort = (field : string) => {
    activeField === field && isAscending ? onSortDescending(field) : onSortAscending(field);
    setSortingOrder(field);
    setActiveField(field);
  };

  const getArrowIconClassName = (field: string) => {
    if (sortingOrder === field) {
      return isAscending ? '' : 'inverted';
    }
    return '';
  };

  const getArrowIconColor = (field: string) => {
    return activeField === field ? 'active-arrow-icon' : 'disabled-arrow-icon';
  };

  return (
    <TableHead>
      <HeaderElement width="260px" className="sticky recipient-table-header-name">
        <NameHeadElement>
          { showCheckbox && (
            <UnlabelledCheckbox
              id={giftingCopy.name}
              onClickOverride={handleCheckboxClick}
              withTooltip={true}
              checkedTooltipText={giftingCopy.clearSelection}
              unCheckedTooltipText={giftingCopy.selectAll}
            />
          )}
          <Typography variant="heading-compact-01">
            {giftingCopy.name}
          </Typography>
          <ArrowDownIcon
            className={`arrow-icon ${getArrowIconClassName(SortingFieldName.Name)} ${getArrowIconColor(SortingFieldName.Name)}`}
            onClick={() => handleSort(SortingFieldName.Name)}
          />
        </NameHeadElement>
      </HeaderElement>

      <HeaderElement width="224px">
        <Typography variant="heading-compact-01">
          {giftingCopy.department}
        </Typography>
        <ArrowDownIcon
          className={`arrow-icon ${getArrowIconClassName(SortingFieldName.Department)} ${getArrowIconColor(SortingFieldName.Department)}`}
          onClick={() => handleSort(SortingFieldName.Department)}
        />
      </HeaderElement>

      <HeaderElement width="180px">
        <Typography variant="heading-compact-01">
          {giftingCopy.birthday}
        </Typography>
        <ArrowDownIcon
          className={`arrow-icon ${getArrowIconClassName(SortingFieldName.Birthday)} ${getArrowIconColor(SortingFieldName.Birthday)}`}
          onClick={() => handleSort(SortingFieldName.Birthday)}
        />
      </HeaderElement>

      <HeaderElement width="180px">
        <Typography variant="heading-compact-01">
          {giftingCopy.startDate}
        </Typography>
        <ArrowDownIcon
          className={`arrow-icon ${getArrowIconClassName(SortingFieldName.StartDate)} ${getArrowIconColor(SortingFieldName.StartDate)}`}
          onClick={() => handleSort(SortingFieldName.StartDate)}
        />
      </HeaderElement>

      <HeaderElement width="200px">
        <Typography variant="heading-compact-01">
          {giftingCopy.dietaryRequirements}
        </Typography>
        <ArrowDownIcon
          className={`arrow-icon ${getArrowIconClassName(SortingFieldName.DietaryRequirements)} ${getArrowIconColor(SortingFieldName.DietaryRequirements)}`}
          onClick={() => handleSort(SortingFieldName.DietaryRequirements)}
        />
      </HeaderElement>

      <HeaderElement width="384px">
        <Typography variant="heading-compact-01">
          {giftingCopy.homeAddress}
        </Typography>
        <ArrowDownIcon
          className={`arrow-icon ${getArrowIconClassName(SortingFieldName.HomeAddress)} ${getArrowIconColor(SortingFieldName.HomeAddress)}`}
          onClick={() => handleSort(SortingFieldName.HomeAddress)}
        />
      </HeaderElement>
    </TableHead>
  );
};

export default TableHeader;
