import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import lang from 'common/lang';
import {
  navBoxShadow, externalLinks, authCookieName, authUserCookieName, internalLinks, shopifyUrl, stagingAppHostName, stagingAppUrl,
} from 'common/constants';
import { useOnClickOutside, useCookie } from 'common/hooks';
import { useLogoutMutation } from 'features/account/userService';
import { openExternalLink } from 'common/utils/helpers';
import {
  ChevronDown, Logo, HamburgerIcon, CloseIcon,
} from '../iconComponents';
import Typography from '../typography';
import {
  Header, Menu, MenuOptions, MenuOption, LogoAndLinks, SubNav,
  Links, MobileMenu, Overlay, MobileMenuContent, MainNav, MegaMenuLinks, LogoIcon,
} from './styles';
import { AuthheaderProps } from './types';
import MobileMegaMenu from '../megaMenu/mobileMegaMenu';
import MegaMenu from '../megaMenu';
export const AuthHeader = (props: AuthheaderProps) => {
  const { firstName, lastName } = props;
  const { header: headerCopy } = lang;
  const headerRef = useRef<HTMLHeadElement | null>(null);
  const menuOptionsRef = useRef<HTMLDivElement | null>(null);
  const prevScollPosition = useRef<number>(window.pageYOffset);
  const [logout] = useLogoutMutation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { expireCookie } = useCookie();
  const navigate = useNavigate();
  const megaMenuRef = useRef<HTMLDivElement | null>(null);
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);

  useEffect(() => {
    if (headerRef && headerRef?.current) {
      window.addEventListener('scroll', dropShadow);
      window.addEventListener('scroll', hideNav);
    }
    return (() => {
      window.removeEventListener('scroll', dropShadow);
      window.removeEventListener('scroll', hideNav);
    });
  }, []);

  const dropShadow = () => {
    if (headerRef && headerRef.current) {
      if (window.pageYOffset > 10) {
        headerRef.current.style.boxShadow = navBoxShadow;
        return;
      }
      headerRef.current.style.boxShadow = 'none';
    }
  };

  const hideNav = () => {
    const yDisplacement = '-133px';
    setMenuOpen(false);
    if (headerRef && headerRef.current) {
      if (window.pageYOffset > 64) {
        const currentScrollPos = window.pageYOffset;
        if (prevScollPosition.current > currentScrollPos) {
          headerRef.current.style.transform = 'translate3d(0px, 0px, 0px)';
          headerRef.current.classList.add('header-visible');
        } else {
          headerRef.current.style.transform = `translate3d(0px, ${yDisplacement}, 0px)`;
          headerRef.current.classList.remove('header-visible');
        }
        prevScollPosition.current = currentScrollPos;
      }
    }
  };
  const toggleMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setMenuOpen(!menuOpen);
  };
  const triggerLogout = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    logout().unwrap().then(() => {
      localStorage.clear();
    });
    expireCookie(authCookieName);
    expireCookie(authUserCookieName);
    setTimeout(() => {
      window.location.href = window.location.hostname === stagingAppHostName ? stagingAppUrl : externalLinks.shop;
    }, 0);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [mobileMenuOpen]);

  const toggleMegaMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setMegaMenuOpen(!megaMenuOpen);
  };

  const closeMegaMenu = () => {
    setMegaMenuOpen(false);
  };

  useOnClickOutside(menuOptionsRef, closeMenu);
  useOnClickOutside(megaMenuRef, closeMegaMenu);

  return (
    <Header ref={headerRef} className="header-visible auth-header">
      <MainNav>
        <MobileMenuContent className={`${mobileMenuOpen ? 'hide' : ''}`}>
          <MobileMegaMenu />
        </MobileMenuContent>
        <Overlay className={`${mobileMenuOpen ? '' : 'hide'}`} onClick={handleMobileMenuToggle} />
        <LogoAndLinks>
          <MobileMenu>
            <HamburgerIcon className={`${mobileMenuOpen ? 'hide' : 'show'}`} onClick={handleMobileMenuToggle} />
            <CloseIcon className={`${mobileMenuOpen ? 'show' : 'hide'}`} onClick={handleMobileMenuToggle} />
          </MobileMenu>
          <LogoIcon onClick={() => openExternalLink(shopifyUrl)}>
            <Logo />
          </LogoIcon>
          <MegaMenuLinks className="main-nav-links" $isOpen={megaMenuOpen} onClick={(e) => toggleMegaMenu(e)} ref={megaMenuRef}>
            <Typography variant="heading-compact-01" onClick={() => setMegaMenuOpen(true)}>
              {headerCopy.browseTreats}
              {' '}
              <ChevronDown />
            </Typography>
            <MegaMenu megaMenuOpen={megaMenuOpen} />
          </MegaMenuLinks>
          <MegaMenuLinks className="main-nav-links" data-cy="automated-gifting-link" onClick={() => openExternalLink(externalLinks.corporateGiftingRegisterLink)}>
            <Typography variant="heading-compact-01">
              {headerCopy.automatedGifting}
            </Typography>
          </MegaMenuLinks>
        </LogoAndLinks>
        <Menu $isOpen={menuOpen} onClick={toggleMenu} ref={menuOptionsRef} data-cy="user-dropdown">
          <Typography variant="body-compact-01" noMargin>
            {firstName}
            {lastName ? ` ${lastName}` : ''}
          </Typography>
          <ChevronDown />
          <MenuOptions $isOpen={menuOpen}>
            <MenuOption onClick={() => navigate(internalLinks.orders)} data-cy="orders-button">
              <Typography variant="body-compact-02">{headerCopy.myOrders}</Typography>
            </MenuOption>
            <MenuOption onClick={() => navigate(internalLinks.plans)} data-cy="plans-button">
              <Typography variant="body-compact-02">{headerCopy.myPlans}</Typography>
            </MenuOption>
            <MenuOption onClick={() => navigate(internalLinks.rewards)} data-cy="rewards-button">
              <Typography variant="body-compact-02">{headerCopy.rewards}</Typography>
            </MenuOption>
            <MenuOption onClick={triggerLogout} data-cy="logout-button">
              <Typography variant="body-compact-02">{headerCopy.logout}</Typography>
            </MenuOption>
          </MenuOptions>
        </Menu>
      </MainNav>
      <SubNav>
        <Links className="sub-nav-links">
          <Typography className={window.location.pathname === internalLinks.orders ? 'active' : ''} variant="heading-compact-01" onClick={() => navigate(internalLinks.orders)} data-cy="my-orders">
            {headerCopy.myOrders}
          </Typography>
          <Typography className={window.location.pathname === internalLinks.plans ? 'active' : ''} variant="heading-compact-01" onClick={() => navigate(internalLinks.plans)} data-cy="my-plans">
            {headerCopy.myPlans}
          </Typography>
          <Typography className={window.location.pathname === internalLinks.rewards ? 'active' : ''} variant="heading-compact-01" onClick={() => navigate(internalLinks.rewards)} data-cy="rewards-tab">
            {headerCopy.rewards}
          </Typography>
        </Links>
      </SubNav>
    </Header>
  );
};

export default AuthHeader;
