import styled from 'styled-components';

export const ProductDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    &:not(:first-child) {
        margin-top: 24px;
    }
`;

export const TotalPricingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: calc(100% - 48px);
    padding: 0 24px 24px 24px;
`;

export const PricingSummaryItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ShippingAddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 16px;
    width: calc(100% - 32px);
    border-radius: 6px;
    background-color: ${({ theme }) => theme.palette['ui-base'].grey20};
`;

export const BadgeAndCoroprateGiftContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    width:100%;
    justify-content: space-between;
`;

export const BadgeAndPlanTypeContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
`;

export const PricePerBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    .per-box {
        color: ${({ theme }) => theme.palette.text['text-01']};
    }
`;
