/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import lang from 'common/lang';
import { LineItems, OrderType } from 'features/home/types';
import { formatCreatedAtDate, formatDeliveryDate } from 'common/utils/helpers';
import { useUserDetails } from 'common/hooks';
import { mixpanelCustomEvent, Dict, MixpanelEventName } from 'common/utils/mixpanel/eventTriggers';
import Button from '../button/button';
import { OrderContainer, Summary, InvoiceContainer } from './styles';
import { OrderHeader } from './orderHeader';
import { ProductDetails } from './productDetails';
import { PricingAndShipping } from './productDetails/pricingAndShipping';
export const Order = (props: OrderType) => {
  const {
    shopifyOrderNumber, createdAt, shopifyOrderStatusUrl, orderLineItems, totalPrice, id, isSubscription,
  } = props;
  const { user } = useUserDetails();
  const [isMultipleRecipients, setIsMultipleRecipients] = useState(false);
  const { order: orderCopy } = lang;
  let mixpanelProps: Dict = {};
  if (user) {
    mixpanelProps = {
      $name: `${user.firstName} ${user?.lastName}`,
      $distinct_id: user.id,
      $email: user.email,
      total_products: orderLineItems.length,
      total_price: (totalPrice / 100).toFixed(2),
      delivery_date: orderLineItems[0].deliveryDate ? formatDeliveryDate(orderLineItems[0].deliveryDate) : '',
      order_id: id,
      order_number: shopifyOrderNumber,
    };
  }
  const handleExternalLink = (url: string) => {
    mixpanelCustomEvent({ mixpanelProps, id: user?.id.toString() || '', eventName: MixpanelEventName.viewInvoice });
    window.open(url, '_blank');
  };

  return (
    <OrderContainer>
      <OrderHeader
        name={shopifyOrderNumber?.toString() || id.toString()}
        statusUrl={shopifyOrderStatusUrl}
        createdAt={formatCreatedAtDate(createdAt)}
        mixpanelProps={mixpanelProps}
        userId={user?.id.toString() || ''}
      />
      <Summary>
        {orderLineItems.map((lineItem: LineItems, index: number) => <ProductDetails key={`${lineItem.orderId}${index}`} setIsMultipleRecipients={setIsMultipleRecipients} {...lineItem} isSubscription={isSubscription} />)}
      </Summary>
      <PricingAndShipping isMultipleRecipients={isMultipleRecipients} {...props} />
      <InvoiceContainer>
        { !!shopifyOrderNumber && (
          <Button
            label={orderCopy.viewInvoice}
            variant="primary"
            size="base"
            onClick={() => handleExternalLink(shopifyOrderStatusUrl)}
          />
        )}
      </InvoiceContainer>
    </OrderContainer>
  );
};

export default Order;
