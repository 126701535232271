import styled from 'styled-components';

export const SubscriptionHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px;
    gap: 8px;
    width: calc(100% - 32px);
    align-items: flex-start;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: ${({ theme }) => theme.palette.background['ui-background-02']};
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    &.header-exists {
        top: 131px;
    }
`;

export const SubscriptionNumberUrl = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
`;
