import { breakpoints } from 'common/theme/breakpoints';
import styled from 'styled-components';

export const WidgetsContainer = styled.div`
     width : 100%;
     padding : 32px 0px;
     display: flex;
     flex-direction: column;
     gap: 32px;
     align-items: center;
     align-content: center;
     flex-wrap: wrap;
     
`;

export const ActivitesBlock = styled.div`
     background: ${({ theme }) => theme.palette.background['ui-background']};
     display: flex;
     padding: 24px 0px;
     flex-direction: column;
     align-items: flex-start;
     gap: 8px;
     flex: 1 0 0;
     border-radius: 6px;
     @media ${breakpoints.tablet} {
          padding: 24px 15px;
     }
`;

export const ReferralBlock = styled.div`
     background: ${({ theme }) => theme.palette.background['ui-background']};
     border-radius: 6px;
     .stamped-rewards-widget-heading-main{
          line-height: 120%;
     }
`;

export const ActivitiesAndReferralWidgets = styled.div`
     display: flex;
     flex-direction: column;
     gap: 32px;
     width: 100%;
     @media ${breakpoints.tablet} {
          flex-direction: row;
          gap: 32px;
          width: 100%;
     }
`;

export const SummaryWidget = styled.div`
     background: ${({ theme }) => theme.palette.background['ui-background']};
     align-self: stretch;
     border-radius: 6px;
`;
