import { ReactNode } from 'react';
import { useUserDetails } from 'common/hooks';
import { AuthHeader } from '../authheader';
import Footer from '../footer';
import ContentLayout from '../contentLayout';
export const AuthLayout = (props: { children?: ReactNode}) => {
  const { children } = props;
  const { user } = useUserDetails();
  return (
    <>
      <AuthHeader
        firstName={user?.firstName || 'User'}
        lastName={user?.lastName || ''}
      />
      <ContentLayout>
        <div style={{ marginTop: '54px' }}>
          {children}
        </div>
      </ContentLayout>
      <Footer />
    </>
  );
};

export default AuthLayout;
