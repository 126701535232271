import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';

export const PlansContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: ${({ theme }) => `1px solid ${theme.palette['ui-base'].grey40}`};
    align-items: flex-start;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    @media ${breakpoints.desktopSmall} {
        width: auto;
    }
`;

export const Summary = styled.div`
    padding: 24px;
    width: calc(100% - 48px);
`;

export const ShippingContainer = styled.div`
    padding: 24px;
    width: calc(100% - 48px);
    text-align: left;
    margin-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
`;
export const UpcomingDeliveriesContainer = styled.div`
    padding: 24px 24px 0 24px ;
    width: calc(100% - 48px);
    text-align: left;
    margin-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
    .margin-bottom-16 {
        margin-bottom: 16px;
    }
    .margin-top-16 {
        margin-top: 16px;
    }
`;
export const DatesContainer = styled.div`
    display: flex;
    border-top: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    padding: 24px 24px 0px 24px;
    width: calc(100% - 48px);
`;

export const DateComponent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 180px;
`;
export const IntervalContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    padding-left: 24px;
    svg {
        min-height: 18px;
        min-width: 18px;
        max-width: 18px;
        max-height: 18px;
    }
`;

export const ManagePlansContainer = styled.div`
    padding: 16px 24px 0;
    width: calc(100% - 48px);
`;

export const ShippingHeaderAndEditor = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .edit-button {
        display: none;
        cursor: pointer;
        color: ${({ theme }) => theme.palette.link['link-01']};
        &:hover{
            color: ${({ theme }) => theme.palette.link['link-01-hover']};
        }
    }
`;

export const ActionAndAlertContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: calc(100% - 48px);
    padding: 16px 24px 24px 24px;
`;

export const DatesNumber = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.palette.support['support-background-03']};
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text['text-01']};
    border-radius: 50%;
`;

export const UpcomingDeliveriesItem = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
`;

export const DatesContainerListItem = styled.div`
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.palette['ui-base'].grey40};
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    width: 100%;
    gap: 8px;
`;
