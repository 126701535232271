import lang from 'common/lang';
import theme from 'common/theme/theme';
import brandedTreats from 'assets/images/brandedTreats.png';
import classicTreats from 'assets/images/classicTreats.png';
import letterboxTreats from 'assets/images/letterboxTreats.png';
import seasonalTreats from 'assets/images/seasonalTreats.png';
import { externalLinks } from 'common/constants';
import { openExternalLink } from 'common/utils/helpers';
import Typography from '../typography';
import {
  MegaMenuContainer, MegaMenuSubSContainer, PopularCollectionContainer, PopularCollectionProduct, ViewCollectionContainer, ViewMoreCollection,
} from './styles';
import { CollectionItem, MegaMenuProps } from './types';

export const MegaMenu = (props: MegaMenuProps) => {
  const {
    megaMenuOpen,
  } = props;
  const { megaMenu: megaMenuCopy } = lang;

  const popularCollection : CollectionItem[] = [
    { name: megaMenuCopy.brandedTreats, image: brandedTreats },
    { name: megaMenuCopy.letterboxTreats, image: letterboxTreats },
    { name: megaMenuCopy.classicTreats, image: classicTreats },
    { name: megaMenuCopy.seasonalTreats, image: seasonalTreats },
  ];

  const handlePopularCollectionClick = (collection : string) => {
    let collectionUrl;
    switch (collection) {
      case `${megaMenuCopy.brandedTreats}`:
        collectionUrl = externalLinks.brandedTreatsLink;
        break;
      case `${megaMenuCopy.letterboxTreats}`:
        collectionUrl = externalLinks.letterBoxTreatsLink;
        break;
      case `${megaMenuCopy.classicTreats}`:
        collectionUrl = externalLinks.classicTreatsLink;
        break;
      case `${megaMenuCopy.seasonalTreats}`:
        collectionUrl = externalLinks.corporateEventsLink;
        break;
      default:
        break;
    }
    if (collectionUrl) {
      openExternalLink(collectionUrl);
    }
  };

  return (
    <MegaMenuContainer $isOpen={megaMenuOpen}>
      <MegaMenuSubSContainer>
        <Typography className="heading" variant="heading-compact-02" color={theme.palette.text['text-04']}>{megaMenuCopy.cakedropPopularCollections}</Typography>
        <PopularCollectionContainer>
          {popularCollection.map((collection : CollectionItem) => {
            return (
              <PopularCollectionProduct key={collection.name} onClick={() => handlePopularCollectionClick(collection.name)} data-cy={`collection-${collection.name.replaceAll(' ', '').toLowerCase()}`}>
                <div className="collection-block">
                  <img src={collection.image} alt={collection.name} />
                  <Typography variant="body-compact-01">{collection.name}</Typography>
                </div>
              </PopularCollectionProduct>
            );
          })}
        </PopularCollectionContainer>
        <ViewCollectionContainer onClick={() => openExternalLink(externalLinks.shop)} data-cy="view-more-collection-link">
          <Typography
            variant="heading-compact-01"
            data-cy="view-more-collection-link"
          >
            {megaMenuCopy.viewMoreCollection}
          </Typography>
        </ViewCollectionContainer>
      </MegaMenuSubSContainer>
    </MegaMenuContainer>
  );
};

export default MegaMenu;
