import { useNavigate } from 'react-router-dom';
import lang from 'common/lang';
import { PlanStatus, PlanType, SubscriptionLineItems } from 'features/plans/types';
import { formatPlanDates, getSampleLineItem } from 'common/utils/helpers';
import Button from '../button/button';
import { IntervalIcon } from '../iconComponents';
import {
  PlansContainer, Summary, InvoiceContainer, DatesContainer, DateComponent, IntervalContainer,
} from './styles';
import { SubscriptionHeader } from './subscriptionHeader';
import { ProductDetails } from './productDetails';
import Typography from '../typography';
export const Subscription = (props: PlanType) => {
  const {
    createdAt, subscriptionLineItems, id, subscriptionNumber, status,
    deliveryInterval, nextDeliveryDate, nextPaymentDate, cancelledAt, pausedAt,
    orders,
  } = props;
  const orderLineItem = getSampleLineItem(orders[0], subscriptionLineItems[0].title);
  const { plans: plansCopy } = lang;
  const navigate = useNavigate();
  const { deliveryDate } = subscriptionLineItems[0];
  return (
    <PlansContainer>
      <SubscriptionHeader
        name={subscriptionNumber.toString()}
        createdAt={formatPlanDates(createdAt)}
      />
      <Summary>
        {orderLineItem && subscriptionLineItems.map(
          (lineItem: SubscriptionLineItems) => (
            <ProductDetails
              key={lineItem.id}
              {...orderLineItem}
              status={status}
            />
          ),
        )}
      </Summary>
      <IntervalContainer>
        <IntervalIcon />
        <Typography variant="heading-compact-01">
          {`${plansCopy.intervalText} ${deliveryInterval}${deliveryInterval.includes('1') ? '' : 's'}`}
        </Typography>
      </IntervalContainer>
      <DatesContainer>
        {status === PlanStatus.active && (
          <>
            <DateComponent>
              <Typography variant="label-01">{plansCopy.deliveryDate}</Typography>
              <Typography variant="body-compact-02">{formatPlanDates(deliveryDate)}</Typography>
            </DateComponent>
            { nextPaymentDate && (
              <DateComponent>
                <Typography variant="label-01">{plansCopy.nextPaymentDate}</Typography>
                <Typography variant="body-compact-02">{formatPlanDates(nextPaymentDate)}</Typography>
              </DateComponent>
            )}
            {nextDeliveryDate && (
              <DateComponent>
                <Typography variant="label-01">{plansCopy.nextDeliveryDate}</Typography>
                <Typography variant="body-compact-02">{formatPlanDates(nextDeliveryDate)}</Typography>
              </DateComponent>
            )}
            {!nextPaymentDate && !nextDeliveryDate && (
              <DateComponent>
                <Typography variant="label-01">{plansCopy.upcomingDeliveries}</Typography>
                <Typography variant="body-compact-02">{plansCopy.processingDates}</Typography>
              </DateComponent>
            )}
          </>
        )}
        {status === PlanStatus.cancelled && (
          <DateComponent>
            <Typography variant="label-01">{plansCopy.cancelledOn}</Typography>
            <Typography variant="body-compact-02">{formatPlanDates(cancelledAt)}</Typography>
          </DateComponent>
        )}
        {status === PlanStatus.paused && (
          <DateComponent>
            <Typography variant="label-01">{plansCopy.pausedOn}</Typography>
            <Typography variant="body-compact-02">{formatPlanDates(pausedAt)}</Typography>
          </DateComponent>
        )}
      </DatesContainer>
      <InvoiceContainer>
        <Button
          label={plansCopy.viewPlanDetails}
          variant="primary"
          size="base"
          onClick={() => navigate(`/plans/${subscriptionNumber}`)}
        />
      </InvoiceContainer>
    </PlansContainer>
  );
};

export default Subscription;
