import React, { useState, useEffect, ImgHTMLAttributes } from 'react';
import './styles.scss';
type ImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'type'> & {
  borderRadius?: string;
}

export const ImageWithSkeleton = (props: ImageProps) => {
  const {
    src = '', alt = '', width, height, borderRadius = 'unset', ...rest
  } = props;
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      setImageLoaded(true);
    };
  }, [src]);

  return (
    <div className="image-container">
      <img
        className="image"
        src={src}
        alt={alt}
        style={{ borderRadius, width, height }}
        {...rest}
      />
      <div
        className={`skeleton ${imageLoaded ? 'loaded' : 'loading'}`}
        style={{ borderRadius, ...rest.style }}
      >
      </div>
    </div>
  );
};

export default ImageWithSkeleton;
